<template>
	<div>
		<!-- 分类 -->
		<div class="bg-fff radius-8 padding-x-24">
			<div class="flex align-center relative">
				<p class="flex align-center fs-24 flex1" style="
            border-bottom: 1px solid #efefef;
            width: 800px;
            overflow-x: hidden;
          ">
					<span class="padding-24 actBtn1 flex-shrink pointer margin-right-24"
						:class="argument.productTypeId == '' ? 'actBtn' : ''"
						@click="argument.productTypeId='',getList('init')">{{$t('message.name2')}}</span>
					<span class="padding-24 actBtn1 flex-shrink pointer margin-right-24"
						@click="argument.productTypeId=item.id,getList('init')"
						:class="item.id == argument.productTypeId ? 'actBtn' : ''" v-for="(item, index) in treeList"
						:key="index">{{item.name}}</span>
				</p>
				<div @click="showMenu=!showMenu" class="pointer">
					<img src="@img/auth/auth6.png" width="20px" height="20px" class="margin-left-10" alt="" />
				</div>

				<div class="hoverMenu bg-fff" v-show="showMenu">
					<Menu :showTop="false" :shopId="argument.shopId" @getTree="getTree" @getid="getId" :showMenu="showMenu" @changeShow="changeShow"></Menu>
				</div>
			</div>
			<div class="padding-y-10 flex align-center">
				<p class="flex align-center justify-center padding-x-24 sbtn pointer"
					:class="argument.orderByType == item.value ? 'ssbtn' : ''" @click="changeTab(item.value)"
					v-for="(item, index) in $t('message.tabList3')" :key="index">
					<span class="margin-right-10">{{ item.name }}</span>
					<img src="@img/auth/sort.png" width="18px" height="18px" alt="" />
				</p>
			</div>
		</div>
		<!-- 商品 -->
		<div class="flex align-center flex-wrap">
			<div class="bg-fff padding-y-24 padding-x-16 border-box margin-top-24 pointer" style="width: 20%"
				@click="$router.push({ path: '/selfRun/goodsDetail?id='+item.id })" v-for="(item, index) in list" :key="index">
				<div class="flex align-center justify-between" style="background: #fafafa">
					<img :src="item.coverImg" width="100%" height="240px" style="object-fit: cover" alt="" />
				</div>
				<div class="padding-10">
					<p class="fs-14 u-line-2" style="height:28px">
						{{item.productName}}
					</p>
					<p class="fs-24 fw-bold margin-top-10">{{item.symbol}}{{item.productPrice}}</p>
					<div class="flex align-center margin-top-10">
						<div style="width: 24px;height: 24px;" class="radius-4 margin-right-4 flex-shrink overflow-hidden">
							<img :src="item.shopIcon" width="100%" height="100%">
						</div>
						<span class="fs-14 line-16 color-999 u-line-1">{{lan=='English'?item.shopNameEnglish:item.shopName}}</span>
					</div>
					<div class="flex align-center justify-between margin-top-10">
						<p class="flex align-center">
							<img src="@img/auth/show1.png" alt="" width="24px" height="24px" />
							<span class="fs-14 color-999">{{item.collectNum || 0}}</span>
						</p>
						<p class="flex align-center">
							<img src="@img/auth/show.png" alt="" width="24px" height="24px" />
							<span class="fs-14 color-999">{{item.viewNum || 0}}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<Empty :length="list.length"></Empty>
		<div class="margin-top-24">
			<el-pagination background @current-change="handleCurrentChange" :current-page.sync="argument.pageNo"
				:page-size="argument.pageSize" layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			shopId: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				showMenu: false,
				lan:localStorage.getItem('language'),
				list: [],
				argument: {
					pageNo: 1,
					shopId: "",
					pageSize: 10,
					productTypeId: "",
					orderByType: null, //最新1 浏览量2 收藏量3
					orderBy: null, //倒序1 升序2 无3
				},
				total: 0,
				treeList: [],
			};
		},
		created() {
			this.argument.shopId = this.shopId;
			this.getList('init');
		},
		methods: {
			changeShow(val){
				this.showMenu = val
			},
			getTree(tree) {
				console.log(1111,tree)
				this.treeList = tree;
			},
			getId(id) {
				this.argument.productTypeId = id;
				this.getList('init');
			},
			// 
			changeTab(value) {
				if(this.argument.orderByType==value){
					this.argument.orderByType = null
					this.argument.orderBy = null
					this.getList('init');
					return
				}
				this.argument.orderByType = value;
				if (this.argument.orderBy == 1) {
					this.argument.orderBy = 2;
				} else {
					this.argument.orderBy = 1;
				}

				this.getList('init');
			},
			// 获取列表
			getList(type) {
				if (type == 'init') {
					this.list = [];
					this.argument.pageNo = 1;
				}
				this.$http({
					url: '/app-api/biz/open/product-page-order',
					method: "get",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
					params: this.argument,
				}).then((res) => {
					if (res.data.code == 0) {
						this.list = res.data.data.list;
						this.total = res.data.data.total;
					}
				});
			},
			// 分页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.argument.pageNo = val;
				this.getList();
			},
		},
	};
</script>

<style scoped>
	.actBtn1 {
		border-bottom: 4px solid transparent;
	}

	.actBtn {
		font-weight: bold;
		border-bottom: 4px solid #222222;
	}

	.sbtn {
		height: 48px;
		border: 1px solid #222222;
		border-radius: 38px;
		margin-right: 28px;
	}

	.ssbtn {
		font-weight: bold;
		border: 2px solid #222222;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #e32519 !important;
	}

	.hoverMenu {
		position: absolute;
		top: 82px;
		left: 0;
		width: 100%;
		/* height: 100vh; */
		color: #fff;
		z-index: 100;
		box-sizing: border-box;
		color: #333333 !important;
	}

	img {
		pointer-events: auto !important;
	}
</style>