<template>
  <div
    v-if="isCallMessage && conversationType === TYPES.CONV_GROUP"
    :class="{ blinkText: isBlink }"
  >
    {{ custom }}
  </div>
</template>

<script lang="ts">
import { computed } from "../../../adapter-vue";
import TUIChatEngine, { IMessageModel } from "@tencentcloud/chat-uikit-engine";
interface IProps {
  message: IMessageModel;
  signalingInfo: any;
  customContent: any;
  blinkMessageIDList?: string[];
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({})
  },
  signalingInfo: {
    key: "signalingInfo",
    required: false,
    type: null,
    default: () => ({})
  },
  customContent: {
    key: "customContent",
    required: false,
    type: null,
    default: () => ({})
  },
  blinkMessageIDList: {
    key: "blinkMessageIDList",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const TYPES = TUIChatEngine.TYPES;
  const isCallMessage = computed(() => !!props.signalingInfo);
  const conversationType = computed(() => props.message?.conversationType);
  const custom = computed(() => props.customContent?.custom);
  const isBlink = computed(() => {
    if (props.message?.ID) {
      return props.blinkMessageIDList?.includes(props.message.ID);
    }
    return false;
  });
  return {
    TYPES,
    isCallMessage,
    conversationType,
    custom,
    isBlink
  };
};
export default __sfc_main;
</script>

<style scoped lang="scss">
@keyframes blinkText {
  50% {
    color: #ff9c19;
  }
}
.blinkText {
  animation: blinkText 1s linear 3;
}
</style>
