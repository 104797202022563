<template>
	<!-- 过度动画 -->
	<transition name="fade">
		<div class="toast-container" v-if="visible">
			<!-- Toast 内容 -->
			<div class="toast">
				<div class="content">
					<p v-show="type" class="flex align-center justify-center margin-bottom-16"><img :src="type" alt="" width="32px" height="32px"/></p>
					
					<!-- <i class="iconfont" :class="'icon-' + type"></i> -->
					<p class="text-center">{{ content }}</p>
				</div>
				<!-- 关闭按钮 -->
				<!-- <i v-if="hasClose" class="iconfont icon-close close" @click="visible = false"></i> -->
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'toast-tip',
		data() {
			return {
				content: '',
				time: 3000,
				visible: false,
				//对应的img
				type: '',
				hasClose: true,
			}
		},
		mounted() {
			this.close();
		},
		methods: {
			close() {
				setTimeout(() => {
					this.visible = false;
				}, this.time);
			}
		}
	}
</script>

<style scoped>
	/* 动画效果 淡入淡出 */
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s ease;
	}

	.fade-enter,
	.fade-leave-active {
		opacity: 0;
	}

	.toast-container {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		/* // 横居中 */
		justify-content: center;
		/* // 竖居中 */
		align-items: center;
		z-index: 99999;
	}

	.toast {
		background: rgba(0,0,0,0.6);
		border-radius: 16px 16px 16px 16px;
		max-width: 450px;
		padding: 24px 32px;
		border-radius: 6px;
		font-size: 16px;
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
	}

	.content span {
		padding-left: 10px;
	}
	.content img {
		padding-left: 10px;
	}
</style>