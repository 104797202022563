import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "quill/dist/quill.core.css";
import App from "./App.vue";
import router from "./router";

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper)

// 全局引入toast
import Toast from "./components/toast/index.js"
Vue.prototype.$Toast = Toast;

import Config from "./utils/config.js"
Vue.prototype.$Config = Config;

import Foot from "@/components/foot.vue";
import Head from "@/components/head.vue";
import Menu from "@/components/menu.vue";
import goodsList from "@/components/goodsList.vue";
import shopList from "@/components/shopList.vue";
import Empty from "@/components/empty.vue";
Vue.component('Foot', Foot);
Vue.component('Head', Head);
Vue.component('Menu', Menu);
Vue.component('shopList', shopList);
Vue.component('goodsList', goodsList);
Vue.component('Empty', Empty);

Vue.use(ElementUI);

// 身份认证

// 接口请求
import {http} from './utils/request'
Vue.prototype.$http = http;
import {https} from './utils/request'
Vue.prototype.$https = https;

Vue.config.productionTip = false;

// 字符串拆分数组
Vue.prototype.$getArr = function (string) {
	let array = [];
	if (string) {
		array = string.split(",")
	}
	return array;
};

// 时间戳转化
Vue.prototype.$timestampToMins = function (timestamp) {
	const dateObj = new Date(timestamp);
	return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
};
Vue.prototype.$timestampToDate = function (timestamp) {
	const dateObj = new Date(timestamp);
	return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
};

// 中英文转换
import VueI18n from 'vue-i18n' //引入vue-i18n
import i18n from './assets/language/lang.js'  //引入lang.js

Vue.use(VueI18n)//使用


// 腾讯配置
import { TUIComponents, TUIChatKit, genTestUserSig } from "./TUIKit";
import { TUILogin } from "@tencentcloud/tui-core";

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

const SDKAppID = 1600031095; // Your SDKAppID
const secretKey = "0724dd13806f2a588fee063ba5614bee8da46727396cf48b21e26b9ed78dc1bb"; //Your secretKey
// const userID = '186381599'; // User ID
const userID = localStorage.getItem('userIdPhone'); // User ID

// TUIChatKit add TUIComponents
TUIChatKit.components(TUIComponents, Vue);
// TUIChatKit init
TUIChatKit.init();
// TUICore login
TUILogin.login({
	SDKAppID,
	userID,
	// UserSig 是用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。  
	// 该方法仅适合本地跑通 Demo 和功能调试，详情请参见 https://cloud.tencent.com/document/product/269/32688 
	userSig: genTestUserSig({
		SDKAppID,
		secretKey,
		userID,
	}).userSig,
	// 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true  
	useUploadPlugin: true,
	// 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航  
	// 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139  
	// 如果您已购买内容审核服务，开启此功能请设置为 true  
	useProfanityFilterPlugin: false,
	framework: "vue2",
});
new Vue({
	router,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
export { SDKAppID, secretKey };