<template>
  <div :class="[(isShowCreateGroup || isShowManageGroup || isShowSelectMember) && 'TUI-group']">
    <CreateGroup v-if="isShowCreateGroup"/>
    <ManageGroup v-if="isShowManageGroup"/>
    <SelectMember v-if="isShowSelectMember"/>
  </div>
</template>
<script lang="ts">
import { TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import { ref } from "../../adapter-vue";
import CreateGroup from "./create-group/index.vue";
import ManageGroup from "./manage-group/index.vue";
import SelectMember from "./select-member/index.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const isShowCreateGroup = ref(false);
  const isShowManageGroup = ref(false);
  const isShowSelectMember = ref(false);
  TUIStore.watch(StoreName.GRP, {
    isShowCreateComponent: (data: any) => {
      if (data) {
        isShowCreateGroup.value = true;
      } else {
        isShowCreateGroup.value = false;
      }
    },
    isShowManageComponent: (data: any) => {
      if (data) {
        isShowManageGroup.value = true;
      } else {
        isShowManageGroup.value = false;
      }
    },
    isShowSelectComponent: (data: any) => {
      if (data) {
        isShowSelectMember.value = true;
      } else {
        isShowSelectMember.value = false;
      }
    }
  });
  return {
    isShowCreateGroup,
    isShowManageGroup,
    isShowSelectMember
  };
};
__sfc_main.components = Object.assign({
  CreateGroup,
  ManageGroup,
  SelectMember
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss" scoped>
.TUI-group {
  width: 100%;
  height: 100%;
}
</style>