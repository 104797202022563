import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import VueI18n from 'vue-i18n' //引入vue-i18n
Vue.use(VueRouter);
Vue.use(VueI18n);
//合并对象的方法
function concatkeyvalue(map) {//map = [{..},{..}]
	let newmap = {}
	map.forEach(items => {
		for (var key in items) {
			newmap[key] = items[key]
		}
	})
	return newmap
}
//将各个文件的中英文翻译全部合并
let zh = concatkeyvalue([require('../assets/language/lang_zh.js')])
let en = concatkeyvalue([require('../assets/language/lang_en.js')])
let locale = '';
if (localStorage.getItem("language") == '简体中文') {
	locale = 'zh'
} else {
	locale = 'en'
}
const i18n = new VueI18n({
	locale: locale,  // 语言标识
	messages: {
		'zh': zh,
		'en': en
	}
})
const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: {
			title: i18n.t('message.首页'),
			showFooter: true, //使用这个，显示隐藏
		},
		children: [
			// 聊天
			{
				path: "chat",
				name: "chat",
				meta: {
					title: "chat",
					showFooter: false, //使用这个，显示隐藏
				},
				component: () => import("@/views/chat.vue"),
			},
			// 认证
			{
				path: "authentication",
				name: "authentication",
				meta: {
					title: i18n.t('message.资质认证'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () =>
					import(
						/* webpackChunkName: "about" */
						"../views/index/authentication.vue"
					),
				children: [
					// 管理员
					{
						path: "Administrator",
						name: "Administrator",
						meta: {
							title: i18n.t('message.管理员认证'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/index/Administrator.vue"
							),
					},
					// 普通员工
					{
						path: "employee",
						name: "employee",
						meta: {
							title: i18n.t('message.员工认证'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/index/employee.vue"
							),
					},
				],
			},
			// 品牌商家
			{
				path: "brandMerchant",
				name: "brandMerchant",
				meta: {
					title: i18n.t('message.品牌商家'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication2: true,
					showHeader: true,
				},
				component: () =>
					import(
						/* webpackChunkName: "about" */
						"../views/index/brandMerchant.vue"
					),
				children:[
					// 商品详情
					{
						path: "goodsDetail",
						name: "goodsDetail",
						meta: {
							title: i18n.t('message.商品详情'),
							showFooter: false, //使用这个，显示隐藏
							showHeader2:true,
						},
						component: () => import("@/views/shop/goodsDetail.vue"),
					},
					// 商家详情
					{
						path: "MerchantDetails",
						name: "MerchantDetails",
						meta: {
							title: i18n.t('message.商家详情'),
							showFooter: false, //使用这个，显示隐藏
							showAuthentication: true,
						},
						component: () => import("@/views/shop/MerchantDetails.vue"),
						children: [

							{
								// 全部商品
								path: "allGoods",
								name: "allGoods",
								meta: {
									title: i18n.t('message.全部商品'),
									showAuthentication: false, //使用这个，显示隐藏
									showFore: true,
								},
								component: () => import("@/views/shop/allGoods.vue"),
							},
						],
					},
				]
			},
			// 搜索结果
			{
				path: "searchResult",
				name: "searchResult",
				meta: {
					title: i18n.t('message.搜索结果'),
					showFooter: false, //使用这个，显示隐藏
				},
				component: () =>
					import(
						/* webpackChunkName: "about" */
						"../views/index/searchResult.vue"
					),
			},
			// 商品分类列表
			{
				path: "goodsType",
				name: "goodsType",
				meta: {
					title: i18n.t('message.商品分类'),
					showFooter: false, //使用这个，显示隐藏
				},
				component: () =>
					import(
						/* webpackChunkName: "about" */
						"../views/index/goodsType.vue"
					),
			},
			// 发布采购
			{
				path: "ReleasePurchase",
				name: "ReleasePurchase",
				meta: {
					title: i18n.t('message.发布采购'),
					showFooter: false, //使用这个，显示隐藏
					showHeader:true
				},
				component: () =>
					import(
						/* webpackChunkName: "about" */
						"../views/index/ReleasePurchase.vue"
					),
			},
			// 商家详情
			{
				path: "MerchantDetails",
				name: "MerchantDetails",
				meta: {
					title: i18n.t('message.商家详情'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () => import("@/views/shop/MerchantDetails.vue"),
				children: [

					{
						// 全部商品
						path: "allGoods",
						name: "allGoods",
						meta: {
							title: i18n.t('message.全部商品'),
							showAuthentication: false, //使用这个，显示隐藏
							showFore: true,
						},
						component: () => import("@/views/shop/allGoods.vue"),
					},
				],
			},
			// 轮播详情
			{
				path: "swiperDetail",
				name: "swiperDetail",
				meta: {
					title: i18n.t('message.轮播详情'),
					showFooter: false, //使用这个，显示隐藏
				},
				component: () => import("@/views/index/swiperDetail.vue"),
			},
			// 商品详情
			{
				path: "goodsDetail",
				name: "goodsDetail",
				meta: {
					title: i18n.t('message.商品详情'),
					showFooter: false, //使用这个，显示隐藏
					showHeader2: true, //使用这个，显示隐藏
				},
				component: () => import("@/views/shop/goodsDetail.vue"),
			},
			// 产品中心
			{
				path: "selfRun",
				name: "selfRun",
				meta: {
					title: i18n.t('message.产品中心'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () => import("@/views/shop/selfRun.vue"),
				children:[
					// 商品详情
					{
						path: "goodsDetail",
						name: "goodsDetail",
						meta: {
							title: i18n.t('message.商品详情'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/shop/goodsDetail.vue"),
					}
				]
			},
			// 询单中心 供应商
			{
				path: "InquiryCenter",
				name: "InquiryCenter",
				meta: {
					title: i18n.t('message.询单中心'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () => import("@/views/oderCenter/InquiryCenter.vue"),
				children:[
					
					// 采购详情
					{
						path: "purchaseDetail",
						name: "purchaseDetail",
						meta: {
							title: i18n.t('message.采购详情'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/oderCenter/purchaseDetail.vue"),
					},
					// 报价
					{
						path: "quotedPrice",
						name: "quotedPrice",
						meta: {
							title: i18n.t('message.报价'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/oderCenter/quotedPrice.vue"),
					},
					// 发布采购
					{
						path: "ReleasePurchase",
						name: "ReleasePurchase",
						meta: {
							title: i18n.t('message.发布采购'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/index/ReleasePurchase.vue"
							),
					}
				]
			},
			// 询单中心 采购商
			{
				path: "InquiryCenterCai",
				name: "InquiryCenterCai",
				meta: {
					title: i18n.t('message.询单中心'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () => import("@/views/oderCenter/InquiryCenterCai.vue"),
				children:[
					// 采购详情
					{
						path: "purchaseDetail",
						name: "purchaseDetail",
						meta: {
							title: i18n.t('message.采购详情'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/oderCenter/purchaseDetail.vue"),
					},
					// 报价
					{
						path: "quotedPrice",
						name: "quotedPrice",
						meta: {
							title: i18n.t('message.报价'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/oderCenter/quotedPrice.vue"),
					},
					// 发布采购
					{
						path: "ReleasePurchaseRoute",
						name: "ReleasePurchaseRoute",
						meta: {
							title: i18n.t('message.发布采购'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/index/ReleasePurchaseRoute.vue"
							),
					}
				]
			},
			// 采购详情
			{
				path: "purchaseDetail",
				name: "purchaseDetail",
				meta: {
					title: i18n.t('message.采购详情'),
					showFooter: false, //使用这个，显示隐藏
					showHeader:true
				},
				component: () => import("@/views/oderCenter/purchaseDetail.vue"),
			},
			// 报价
			{
				path: "quotedPrice",
				name: "quotedPrice",
				meta: {
					title: i18n.t('message.报价'),
					showHeader:true,
					showFooter: false, //使用这个，显示隐藏
				},
				component: () => import("@/views/oderCenter/quotedPrice.vue"),
			},
			// 发布招标
			{
				path: "IssueTender",
				name: "IssueTender",
				meta: {
					title: i18n.t('message.发布招标'),
					showAuthentication: false, //使用这个，显示隐藏
				},
				component: () =>
					import(
						/* webpackChunkName: "about" */
						"../views/oderCenter/IssueTender.vue"
					),
			},
			// 招标大厅
			{
				path: "biddingHall",
				name: "biddingHall",
				meta: {
					title: i18n.t('message.招标大厅'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () => import("@/views/oderCenter/biddingHall.vue"),
				children: [

					// 发布招标
					{
						path: "IssueTender",
						name: "IssueTender",
						meta: {
							title: i18n.t('message.发布招标'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/oderCenter/IssueTender.vue"
							),
					},
					// 招标详情
					{
						path: "ViewDetails",
						name: "ViewDetails",
						meta: {
							title: i18n.t('message.详情'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/oderCenter/ViewDetails.vue"
							),
					},
				],
			}, // 新闻资讯
			{
				path: "news",
				name: "news",
				meta: {
					title: i18n.t('message.新闻资讯'),
					showFooter: false,
					showAuthentication: true,
				},
				component: () => import("@/views/news/news.vue"),
				children: [
					{
						path: "newsDetail",
						name: "newsDetail",
						meta: {
							title: i18n.t('message.新闻详情'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"@/views/news/newsDetail.vue"
							),
					},
				]
			},
			// 海关资讯
			{
				path: "customs",
				name: "customs",
				meta: {
					title: i18n.t('message.海关资讯'),
					showFooter: false,
					showAuthentication: true,
				},
				component: () => import("@/views/news/customs.vue"),
				children: [

					{
						path: "customsDetail",
						name: "customsDetail",
						meta: {
							title: i18n.t('message.海关详情'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"@/views/news/customsDetail.vue"
							),
					},
				]
			},
			// 行业文档
			{
				path: "industry",
				name: "industry",
				meta: {
					title: i18n.t('message.行业文档'),
					showFooter: false,
					showAuthentication: true,
				},
				component: () => import("@/views/news/industry.vue"),
				children: [

					{
						path: "industryDetail",
						name: "industryDetail",
						meta: {
							title: i18n.t('message.行业详情'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"@/views/news/industryDetail.vue"
							),
					},
				]
			},
			// 行业会展
			{
				path: "exhibitions",
				name: "exhibitions",
				meta: {
					title: i18n.t('message.行业会展'),
					showFooter: false,
					showAuthentication: true,
				},
				component: () => import("@/views/news/exhibitions.vue"),
				children: [

					{
						path: "exhibitionsDetail",
						name: "exhibitionsDetail",
						meta: {
							title: i18n.t('message.会展详情'),
							showAuthentication: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"@/views/news/exhibitionsDetail.vue"
							),
					},
				]
			},

			// 个人中心
			{
				path: "personal_center",
				name: "personal_center",
				meta: {
					title: i18n.t('message.个人中心'),
					showFooter: false, //使用这个，显示隐藏
					showAuthentication: true,
				},
				component: () => import("@/views/center/centerHome.vue"),
				children: [
					
					// 供应商-报价管理
					{
						path: "quotation_mana",
						name: "quotation_mana",
						meta: {
							title: i18n.t('message.报价管理'),
							showFooter: false, //使用这个，显示隐藏
							showAuthentication2: true,
						},
						component: () => import("@/views/center/individual/quotationMana.vue"),
						children:[
							
							// 供应商-报价详情
							{
								path: "quotationDetail",
								name: "quotationDetail",
								meta: {
									title: i18n.t('message.报价详情'),
									showFooter: false, //使用这个，显示隐藏
								},
								component: () => import("@/views/center/individual/quotationDetail.vue"),
							},
						]
					},
					// 发布采购
					{
						path: "ReleasePurchase",
						name: "ReleasePurchase",
						meta: {
							title: i18n.t('message.发布采购'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () =>
							import(
								/* webpackChunkName: "about" */
								"../views/index/ReleasePurchase.vue"
							),
					},
					// 采购管理-全部采购
					{
						path: "purchasing",
						name: "purchasing",
						meta: {
							title: i18n.t('message.采购管理'),
							showFooter: false, //使用这个，显示隐藏
							showAuthentication2: true,
						},
						component: () => import("@/views/center/individual/purchasing.vue"),
						children:[
							
							// 报价详情
							{
								path: "quotation",
								name: "quotation",
								meta: {
									title: i18n.t('message.报价详情'),
									showFooter: false, //使用这个，显示隐藏
								},
								component: () => import("@/views/center/individual/quotation.vue"),
							},
							// 供应商-报价管理
							{
								path: "quotation_mana",
								name: "quotation_mana",
								meta: {
									title: i18n.t('message.报价管理'),
									showFooter: false, //使用这个，显示隐藏
								},
								component: () => import("@/views/center/individual/quotationMana.vue")
							},
						]
					},
					// 招标管理
					{
						path: "bidding",
						name: "bidding",
						meta: {
							title: i18n.t('message.招标管理'),
							showFooter: false, //使用这个，显示隐藏
							showAuthentication2: true,
						},
						component: () => import("@/views/center/individual/bidding.vue"),
						children:[
							
							// 招标详情 
							{
								path: "tender",
								name: "tender",
								meta: {
									title: i18n.t('message.招标详情'),
									showFooter: false, //使用这个，显示隐藏
								},
								component: () => import("@/views/center/individual/tender.vue"),
							}
						]
					},
					// 投标管理
					{
						path: "bid",
						name: "bid",
						meta: {
							title: i18n.t('message.投标管理'),
							showFooter: false, //使用这个，显示隐藏
							showAuthentication2: true,
						},
						component: () => import("@/views/center/individual/bidding.vue"),
						children:[
							
							// 投标详情 
							{
								path: "tender",
								name: "tender",
								meta: {
									title: i18n.t('message.投标详情'),
									showFooter: false, //使用这个，显示隐藏
								},
								component: () => import("@/views/center/individual/tender.vue"),
							}
						]
					},
					// 订购登记
					{
						path: "order",
						name: "order",
						meta: {
							title: i18n.t('message.订购登记'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/order.vue"),
					},
					// 样本申请
					{
						path: "sample",
						name: "sample",
						meta: {
							title: i18n.t('message.样本申请'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/sample.vue"),
					},
					// 个人信息
					{
						path: "personal",
						name: "personal",
						meta: {
							title: i18n.t('message.个人信息'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/personal.vue"),
					},
					// 账号信息
					{
						path: "account",
						name: "account",
						meta: {
							title: i18n.t('message.账号信息'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/account.vue"),
					},
					// 我的收藏
					{
						path: "collection",
						name: "collection",
						meta: {
							title: i18n.t('message.我的收藏'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/collection.vue"),
					},
					// 公告通知
					{
						path: "notification",
						name: "notification",
						meta: {
							title: i18n.t('message.消息通知'),
							showFooter: false, //使用这个，显示隐藏
							showAuthentication2: true,
						},
						component: () => import("@/views/center/individual/notification.vue"),
						children:[
							// 消息通知详情
							{
								path: "noticeDetail",
								name: "noticeDetail",
								meta: {
									title: i18n.t('message.消息通知详情'),
									showFooter: false, //使用这个，显示隐藏
								},
								component: () => import("@/views/center/individual/noticeDetail.vue"),
							},
						]
					},
					// 员工列表
					{
						path: "employee",
						name: "employee",
						meta: {
							title: i18n.t('message.员工列表'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/employee.vue"),
					},
					// 联系客服
					{
						path: "contact",
						name: "contact",
						meta: {
							title: i18n.t('message.联系客服'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/customer/contact.vue"),
					},
					// 意见反馈
					{
						path: "feedback",
						name: "feedback",
						meta: {
							title: i18n.t('message.意见反馈'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/customer/feedback.vue"),
					},
					// 我的投诉
					{
						path: "complaint",
						name: "complaint",
						meta: {
							title: "My complaint",
							title: i18n.t('message.我的投诉'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/customer/complaint.vue"),
					},
					// 帮助中心
					{
						path: "help",
						name: "help",
						meta: {
							title: i18n.t('message.帮助中心'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/help.vue"),
					},
					// 供应商-处理订购登记
					{
						path: "handle",
						name: "handle",
						meta: {
							title: i18n.t('message.处理订购登记'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/handle.vue")
					},
					// 供应商-处理样本申请
					{
						path: "sampleList",
						name: "sampleList",
						meta: {
							title: i18n.t('message.处理样本申请'),
							showFooter: false, //使用这个，显示隐藏
						},
						component: () => import("@/views/center/individual/sampleList.vue")
					},
				]
			},
			// 供应商-报价管理
			{
				path: "quotation_mana",
				name: "quotation_mana",
				meta: {
					title: i18n.t('message.报价管理'),
					showFooter: false, //使用这个，显示隐藏
					showHeader:true,
					showAuthentication2: true,
				},
				component: () => import("@/views/center/individual/quotationMana.vue")
			},
			// 报价详情
			{
				path: "quotation",
				name: "quotation",
				meta: {
					title: i18n.t('message.报价详情'),
					showFooter: false, //使用这个，显示隐藏
					showHeader:true
				},
				component: () => import("@/views/center/individual/quotation.vue"),
			},
			// 供应商-报价详情
			{
				path: "quotationDetail",
				name: "quotationDetail",
				meta: {
					title: i18n.t('message.报价管理'),
					showFooter: false, //使用这个，显示隐藏
					showHeader:true,
				},
				component: () => import("@/views/center/individual/quotationDetail.vue"),
			},
			// 供应商-报价详情-更多
			{
				path: "quotationDetailMore",
				name: "quotationDetailMore",
				meta: {
					title: i18n.t('message.报价管理'),
					showFooter: false, //使用这个，显示隐藏
				},
				component: () => import("@/views/center/individual/quotationDetailMore.vue"),
			},
			// 我的投诉详情
			{
				path: "complaintDetail",
				name: "complaintDetail",
				meta: {
					title: i18n.t('message.详情'),
					showFooter: false, //使用这个，显示隐藏
				},
				component: () => import("@/views/center/customer/complaintDetail.vue"),
			},

			// 关于我们
			{
				path: "about",
				name: "about",
				meta: {
					title: i18n.t('message.关于我们'),
					showFooter: false, //使用这个，显示隐藏
				},
				component: () => import("@/views/center/about.vue"),
			}
		],
	},
	{
		path: "/login",
		name: "login",
		meta: {
			title: "Welcome to login",
		},
		component: () => import("@/views/login/loginHome.vue"),
	},
	{
		path: "/googleLogin",
		name: "googleLogin",
		meta: {
			title: "Welcome to login",
		},
		component: () => import("@/views/login/googleLogin.vue"),
	},
	{
		path: "/register",
		name: "register",
		meta: {
			title: "Register your user",
		},
		component: () => import("@/views/login/register.vue"),
	},
	{
		path: "/agreement",
		name: "agreement",
		meta: {
			title: "View agreement",
		},
		component: () => import("@/views/login/userAgreement.vue"),
	},
	{
		path: "/privacypolicy",
		name: "privacypolicy",
		meta: {
			title: "View privacypolicy",
		},
		component: () => import("@/views/login/privacypolicy.vue"),
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});
router.afterEach(() => {
	window.scrollTo(0, 0)    //滚动条至于浏览器顶部
})
export default router;