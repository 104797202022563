<template>
	<div>
		<div class="margin-top-20 padding-24 bg-fff radius-8" v-for="(item,index) in showList" :key="index">
			<div class="flex align-center justify-between margin-bottom-30">
				<div class="flex align-center" @click="$router.push({path:'/brandMerchant/MerchantDetails',query:{id:item.id}})">
					<img :src="item.shopIcon" width="64px" height="64px" />
					<div class="margin-left-10">
						<p class="more" style="color: #333;display: inline-block;">{{item.shopName}}</p>
						<p class="margin-top-10 flex align-center color-222">{{item.yearNum?item.yearNum:''}}{{item.yearNum?$t('message.shop4'):''}} <span
								class="dots" v-if="item.yearNum"></span> {{item.country?item.country:$t('message.暂无国家信息')}}｜{{comAddress(item)}}
						</p>
					</div>
				</div>
				<div class="flex align-center fs-16">
					<img :src="item.isCollect==1?'img/auth/Heart1.png':'img/auth/Heart.png'" @click="toCollect(item)"
						class="pointer" width="24px" height="24px" />
					<p class="chatBtn" @click="toChat(item)">{{$t('message.shop1')}}</p>
					<p class="bgBtn color-fff chatBtn"
						@click="$router.push({path:'/brandMerchant/MerchantDetails',query:{id:item.id}})">
						{{$t('message.shop2')}}
					</p>
				</div>
			</div>
			<div class="flex align-center justify-between">
				<div class="fs-16 flex-shrink" style="width: 180px;">
					<p>{{$t('message.shop3')}}</p>
					<p class="margin-top-16 " v-for="(ite,ind) in item.productNameList" :key="ind"
						v-show="ind<3 || item.showMore">·{{ite.name}}</p>
					<div v-if="item.productNameList">
						<p class="more margin-top-10" v-if="item.productNameList.length>3" style="width: fit-content;" @click="$router.push({path:'/brandMerchant/MerchantDetails',query:{id:item.id}})">{{$t('message.name3')}}</p>
					</div>
					<p class="margin-top-48">{{$t('message.bidding22')}}</p>
					<p class="fw-bold" style="margin-top: 4px;">{{item.contactTel}}</p>
					<p class="margin-top-10">{{$t('message.bidding23')}}</p>
					<p class="fw-bold" style="margin-top: 4px;">{{item.contactEmail}}</p>
				</div>
				<div class="top9swiper" style="width: 1000px;">
					<swiper :options="swiperOption">
						<swiper-slide v-for="(ite, ind) in item.productList" :key="ind">
							<div class="bg-fff margin-right-40 border-box pointer overflow-hidden radius-8" style="min-height: 340px"
								@click="!shop?$router.push({ path: '/goodsDetail?id='+ite.id }):$router.push({ path: '/brandMerchant/goodsDetail?id='+ite.id })">
								<div class="flex align-center justify-center">
									<img :src="ite.coverImg" width="272px" height="272px" style="object-fit: cover"
										alt="" />
								</div>
								<div class="padding-8">
									<p class="fs-14 u-line-2 color-333 line-16" style="height: 38px;width: 200px;">{{ite.productName}}</p>
									<p class="fs-24 line-28 color-333 fw-600 margin-top-10">{{ite.symbol?ite.symbol:'?'}}{{ite.productPrice}}</p>
								</div>
							</div>
						</swiper-slide>
						<img src="@img/index/swiperL2.png" class="swiper-button-prev" alt=""
							style="width: 32px;height: 32px;" slot="button-prev" />
						<img src="@img/index/swiperR2.png" class="swiper-button-next" alt=""
							style="width: 32px;height: 32px;" slot="button-next" />
					</swiper>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Emoji from '@/components/emoji.vue'
	export default {
		props: {
			fieldArray: {
				type: Array,
				default: () => [{
					productNameList: []
				}],
			},
			shop:{
				type:Boolean,
				default:false
			}
		},
		components:{
			Emoji
		},
		data() {
			return {
				showitem: false,
				swiperOption: {
					slidesPerView: 3.5,
					// 设置分页器
					pagination: {
						el: ".swiper-pagination",
						// 设置点击可切换
						clickable: true,
					},
					// 设置前进后退按钮
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					// 设置自动轮播
					autoplay: false,
					// 设置轮播可循环
					loop: false,
				},
				showChat:false,
				shopId:null,
				chatId:null,
				chatInput:'',
				chatTime: new Date().getTime(),
				timer:null,
				emojiVisible:false,
        // 在线咨询 ↑
				chatList:[],
				showList:[],
      	userId:localStorage.getItem('userId')
			}
		},
		watch:{
			fieldArray:{
				handler (newVal,oldVal){
					this.showList = []
					console.log(newVal,'new')
					newVal.forEach(item=>{
						this.showList.push({...item,showMore:false})
					})
					this.comList()
				},
				deep:true
			}
		},
		methods: {
			chooseEmojiDefault(e){
				this.chatInput = this.chatInput + e
			},
			comAddress(item){
				let enAddress = ''
				if(!item){
					return
				}
				if(item.provinceName){
					enAddress = enAddress + item.provinceName
				}
				if(item.cityName){
					enAddress = enAddress + item.cityName
				}
				if(item.areaName){
					enAddress = enAddress + item.areaName
				}
				if(item.address){
					enAddress = enAddress + item.address
				}
				if(enAddress==''){
					enAddress = this.$t('message.暂无地址')
				}
				return enAddress
			},
			comList(){
				console.log(this.showList)
			},
			// 聊天
			toChat(item) {
				console.log(item)
				if (!localStorage.getItem("userId")) {
					this.$router.push({
						path: '/login'
					});
					return false;
				}
				this.$router.push({
					path:'/chat',
					query:{
						userId:item.contactTel
					}
				})
			},
			// 收藏
			toCollect(item) {
				if (!localStorage.getItem("userId")) {
					this.$router.push({
						path: "/login"
					});
					return false;
				}
				this.$http({
					url: '/admin-api/biz/user-collect/create',
					method: "post",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
					data: {
						contactId: item.id,
						type: 6,
						userId: localStorage.getItem("userId"),
					},
				}).then((res) => {
					if (res.data.code == 0) {
						if (item.isCollect == 1) {
							item.isCollect = 0;
						} else {
							item.isCollect = 1;
						}
					}
				});
			},
		},
	}
</script>

<style scoped>
	.changBtn {
		border: 1px solid;
		height: 48px;
		border-radius: 38px;
	}

	.actchangBtn {
		border: 2px solid;
		font-weight: bold;
	}

	.dots {
		width: 3px;
		height: 3px;
		background: #333;
		border-radius: 50%;
		margin: 0 10px;
	}

	.chatBtn:nth-of-type(1) {
		border: 2px solid #E82511;
		color: #E82511;
	}

	.chatBtn {
		font-weight: bold;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 35px;
		padding: 0 16px;
		margin-left: 24px;
		cursor: pointer;
	}

	img {
		pointer-events: auto !important;
	}
	.send_input{
		display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #F9F9F9;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}
</style>