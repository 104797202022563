<template>
  <div class="bg-fff">
    <div class="inercont">
      <div class="flex align-center justify-between fs-14 padding-y-10">
        <img
          :src="bLogo"
          @click="$router.push({ path: '/' })"
          class="pointer"
          alt="logo"
          width="170px"
          height="42px"
        />
        <div
          class="searchBox flex1 margin-left-30 margin-right-24 fs-16 flex align-center"
          v-show="showSearch"
        >
          <div class="flex align-center flex1">
            <div
              class="flex align-center padding-right-20 margin-right-20 pointer"
              style="border-right: 1px solid #d9d9d9"
            >
              <el-select
                v-model="searchValue.selType"
                @change="toSearch"
                :placeholder="$t('message.请选择')"
                style="border: none"
              >
                <el-option
                  :label="item.label"
                  :key="index"
                  v-for="(item, index) in $t('message.searchList')"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <input
              type="text"
              v-model="searchValue.keywords"
              class="flex1"
              :placeholder="$t('message.请输入')"
            />
          </div>
          <div
            class="flex align-center justify-center pointer searchBtn"
            @click="toSearch"
          >
            <i class="el-icon-search"></i>
            <span class="margin-left-10">{{ $t("message.name1") }}</span>
          </div>
        </div>
        <div class="flex align-center">
          <i
            class="el-icon-search margin-right-40 pointer"
            @click="$router.push({ path: '/searchResult', query: searchValue })"
            v-show="!showSearch"
          ></i>
          <el-dropdown class="margin-right-40 pointer" @command="toPath">
            <span class="el-dropdown-link fw-bold color-222">
              {{ $t("message.footName15") }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="/personal_center/help?index=0">{{
                $t("message.footName16")
              }}</el-dropdown-item>
              <el-dropdown-item command="/personal_center/help?index=1">{{
                $t("message.footName17")
              }}</el-dropdown-item>
              <el-dropdown-item command="/personal_center/feedback">{{
                $t("message.footName18")
              }}</el-dropdown-item>
              <el-dropdown-item command="/about">{{
                $t("message.footName15")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown class="margin-right-40 pointer" @command="toPath">
            <span class="el-dropdown-link fw-bold color-222">
              {{ $t("message.footName1") }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>{{
                $t("message.footName6")
              }}</el-dropdown-item>
              <el-dropdown-item
                :command="{ path: '/ReleasePurchase', type: 3 }"
                >{{ $t("message.footName7") }}</el-dropdown-item
              >
              <el-dropdown-item
                :command="{ path: '/personal_center/purchasing', type: 3 }"
                >{{ $t("message.footName8") }}</el-dropdown-item
              >
              <el-dropdown-item :command="{ path: '/biddingHall', type: 3 }">{{
                $t("message.footName9")
              }}</el-dropdown-item>
              <el-dropdown-item
                :command="{ path: '/personal_center/bidding?type=1', type: 3 }"
                >{{ $t("message.footName10") }}</el-dropdown-item
              >
              <el-dropdown-item disabled>{{
                $t("message.footName11")
              }}</el-dropdown-item>
              <el-dropdown-item
                :command="{ path: '/quotation_mana', type: 4 }"
                >{{ $t("message.footName12") }}</el-dropdown-item
              >
              <el-dropdown-item
                :command="{ path: '/personal_center/handle', type: 4 }"
                >{{ $t("message.footName13") }}</el-dropdown-item
              >
              <el-dropdown-item
                :command="{ path: '/personal_center/sample', type: 4 }"
                >{{ $t("message.footName14") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <span class="margin-right-40 pointer">My management</span> -->
          <div
            class="margin-right-40 pointer relative"
            @click="toPath('/personal_center/notification?selectList=2')"
          >
            <img
              src="@img/auth/auth2.png"
              alt="消息"
              width="16px"
              height="18px"
            />
            <span
              class="absolute bgBtn"
              v-show="userInfo.unReadNum"
              style="
                width: 8px;
                height: 8px;
                border-radius: 50%;
                top: 0;
                right: 0;
                z-index: 10;
              "
            ></span>
          </div>
          <el-dropdown
            class="margin-right-40 pointer"
            trigger="click"
            :hide-on-click="false"
          >
            <span class="el-dropdown-link flex align-center">
              <img
                src="@img/auth/auth3.png"
                alt="语言"
                width="16px"
                height="16px"
              />
              <span class="margin-left-6 fw-bold color-222">{{ language }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>
                <p class="fs-16 color-333">{{ $t("message.name34") }}</p>
                <p class="fs-12" style="line-height: 14px">
                  {{ $t("message.name46") }}
                </p>
              </el-dropdown-item>
              <el-dropdown-item disabled>
                <span class="fs-16 color-333">{{ $t("message.name47") }}</span>
              </el-dropdown-item>
              <el-dropdown-item style="background: #fff">
                <el-select
                  v-model="language"
                  @change="changeLanguage"
                  :placeholder="$t('message.请选择')"
                >
                  <el-option
                    v-for="(item, index) in countryList"
                    :key="index"
                    :label="item.language"
                    :value="item.language"
                  >
                  </el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item disabled>
                <span class="fs-16 color-222">{{ $t("message.name48") }}</span>
              </el-dropdown-item>
              <el-dropdown-item style="background: #fff">
                <el-select
                  v-model="symbol"
                  disabled
                  :placeholder="$t('message.请选择')"
                >
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div
            class="margin-right-40 flex align-center pointer"
            v-if="userInfo.id"
            @click="$router.push({ path: '/personal_center' })"
          >
            <img
              :src="userInfo.avatar ? userInfo.avatar : require('@img/index/index16.png')"
              width="36px"
              height="36px"
              style="border-radius:50%"
            />
            <span class="margin-left-10 fw-bold color-222">{{ userInfo.dassler }}{{ userInfo.nickname }}</span>
          </div>
          <div
            v-else
            @click="$router.push({ path: '/login' })"
            class="loginbtn bgBtn flex align-center justify-center color-fff fw-bold"
          >
            {{ $t("message.name33") }}
          </div>
          <div
            class="flex align-center pointer"
            v-if="userInfo.id"
            @click="outlogin"
          >
            <img
              src="@img/auth/outlogin.png"
              alt=""
              width="18px"
              height="18px"
            />
            <span class="margin-left-10 fw-bold">{{ $t("message.退出") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-y-16 bg-eee" v-show="showBreadcrumb">
      <div class="inercont">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            :to="{ path: breadcrumbItem.path, query: breadcrumbItem.query }"
            :key="index"
            v-for="(breadcrumbItem, index) in breadcrumb"
          >
            {{ breadcrumbItem.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>
import TencentCloudChat from '@tencentcloud/chat';
export default {
  props: {
    showBreadcrumb: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    searchValue: {
      type: Object,
      default: () => ({
        selType: "1",
        keywords: "",
      }),
    },
  },
  mounted() {
    this.getBreadcrumb();
  },
  created() {
    this.init();
  },
  data() {
    return {
      breadcrumb: [],
      title: "",
      language: "English",
      symbol: "$",
      countryList: [],
      userInfo: {
        id: "",
        unReadNum: 0,
      },
      searchList: [],
      bLogo: require("@img/auth/auth1.png"),
      userId:localStorage.getItem('userId'),
      timer:null
    };
  },
  beforeDestroy() {
    console.log(this.timer)
    clearInterval(this.timer)
  },
  destroyed(){
    console.log(this.timer)
    clearInterval(this.timer)
  },
  watch: {
    $route: "getBreadcrumb",
  },
  methods: {
    init() {
      // 配置文件
      this.$http({
        url: "/admin-api/biz/logo/get-type",
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          let datas = res.data.data;
          datas.forEach((el) => {
            if (el.type == 2) {
              this.bLogo = el.logoUrl;
            }
          });
        }
      });
      // 语言初始化
      if (localStorage.getItem("language")) {
        this.language = localStorage.getItem("language");
        this.symbol = localStorage.getItem("symbol");
      } else {
        localStorage.setItem("language", this.language);
        localStorage.setItem("symbol", this.symbol);
      }
      // 搜索历史
      if (localStorage.getItem("search_list")) {
        this.searchList = JSON.parse(localStorage.getItem("search_list"));
      } else {
        this.searchList = [];
      }
      this.$emit("getsearchList", this.searchList);
      // 获得国家信息列表
      this.$http({
        url: "/admin-api/biz/country/list",
        // url: "admin-api/biz/phone-prefix/country-list",
        method: "",
        data: {},
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.countryList = res.data.data;
        }
      });
      // 用户详情
      if (localStorage.getItem("userId")) {
        let options = {SDKAppID:1600031095}
        let chat = TencentCloudChat.create(options)
        this.$http({
          url: "/app-api/biz/open/open-user-info",
          method: "get",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }).then((res) => {
          if (res.data.code == 0 && res.data.data) {
            this.userInfo = res.data.data;
            this.userInfo.unReadNum = this.userInfo.unReadNum + chat.getTotalUnreadMessageCount()
            // showAllType:1,
          } else if (!res.data.data) {
            localStorage.removeItem("userId");
          }
        });
        this.timer = setInterval(() => {
          let options = {SDKAppID:1600031095}
          let chat = TencentCloudChat.create(options)
          this.$http({
            url: "/app-api/biz/open/open-user-info",
            method: "get",
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }).then((res) => {
            if (res.data.code == 0 && res.data.data) {
              this.userInfo = res.data.data;
              this.userInfo.unReadNum = this.userInfo.unReadNum + chat.getTotalUnreadMessageCount()
              // showAllType:1,
            } else if (!res.data.data) {
              localStorage.removeItem("userId");
            }
          });
        },10000);
      }
    },
    // 退出登录
    outlogin() {
      this.$alert(this.$t("message.确定退出登录?"), "", {
        confirmButtonText: this.$t("message.确定"),
        showClose: false,
        showCancelButton: true,
        cancelButtonText: this.$t("message.取消"),
        confirmButtonClass: this.$t("message.确认"),
        cancelButtonClass: this.$t("message.取消"),
        callback: (action) => {
          if (action == "confirm") {
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            this.$router.replace("/login");
          }
        },
      });
    },
    // 修改语言
    changeLanguage(value) {
      this.countryList.forEach((el) => {
        if (el.language == value) {
          this.symbol = el.symbol;
          localStorage.setItem("language", this.language);
          localStorage.setItem("symbol", this.symbol);
          this.$router.go(0);
        }
      });
    },
    // 搜索
    toSearch() {
      if (
        this.searchList.indexOf(this.searchValue.keywords) == -1 &&
        this.searchValue.keywords != ""
      ) {
        this.searchList.unshift(this.searchValue.keywords);
        localStorage.setItem("search_list", JSON.stringify(this.searchList));
      }
      if (this.$route.path == "/searchResult") {
        this.$emit("toSearch", this.searchList);
      } else {
        this.$router.push({
          path: "/searchResult",
          query: this.searchValue,
        });
      }

      // console.log(111,this.searchValue);
    },
    // 面包屑
    getBreadcrumb() {
      this.breadcrumb = this.$route.matched;
      this.$route.matched.forEach((item, index) => {
        console.log(item);
        item.meta.title === "Home page" || item.meta.title === "首页"
          ? (item.path = "/")
          : this.$route.path === item.path
          ? (this.title = item.meta.title)
          : "";
      });
      if (this.$route.query) {
        this.breadcrumb[this.breadcrumb.length - 1].query = this.$route.query;
      }
    },
    // 跳转页面
    toPath(path) {
      console.log(path.type);
      if (path.type) {
        this.$http({
          url: "/app-api/biz/open/open-user-info",
          method: "get",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }).then((res) => {
          if (res.data.code == 0 && res.data.data) {
            if (res.data.data.enterpriseType == 5) {
              this.$router.push({
                path: path.path,
              });
            } else if (res.data.data.enterpriseType == path.type) {
              this.$router.push({
                path: path.path,
              });
            } else {
              this.$message(this.$t("message.暂无权限"));
              // 未登录
              if (!this.userInfo.id) {
                this.$router.push({
                  path: "/login",
                });
              }
            }
          } else {
            this.$message(this.$t("message.暂无权限"));
            // 未登录
            if (!this.userInfo.id) {
              this.$router.push({
                path: "/login",
              });
            }
          }
        });
        return;
      }
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
img {
  pointer-events: auto !important;
}

.loginbtn {
  width: 110px;
  height: 36px;
  margin-left: 18px;
  border-radius: 57px 57px 57px 57px;
  cursor: pointer;
}

.searchBox {
  /* width: 680px; */
  /* height: 56px; */
  background: #ffffff;
  border-radius: 35px;
  box-sizing: border-box;
  border: 1px solid #e82511;
}

/deep/.searchBox .el-input__inner {
  border: none !important;
  background: transparent !important;
  width: 100px !important;
}

.el-breadcrumb ::v-deep .el-breadcrumb__inner:hover{
  color: #e82511 !important;
}

.searchBtn {
  width: 125px;
  height: 48px;
  background: #e82511;
  border-radius: 0 38px 38px 0px;
  color: #fff;
}
.el-select-dropdown__item.selected{
  color: #e82511 !important;
}
</style>