<template>
  <div>
    <div v-show="$route.meta.showFooter">
      <div class="bg-fff flex justify-center" style="position: sticky;top: 0;z-index: 9999;" >
        <div class="flex align-center justify-between fs-14" style="width: 1360px;" @click.stop="">
          <img src="@img/auth/auth1.png" alt="logo" width="170px" height="auto" />
          <div class="flex align-center">
            <el-dropdown
              class="margin-right-40 pointer"
              @command="toPath"
            >
              <span class="el-dropdown-link fw-bold">
                {{ $t("message.footName15") }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/personal_center/help?index=0">{{
                  $t("message.footName16")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/help?index=1">{{
                  $t("message.footName17")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/feedback">{{
                  $t("message.footName18")
                }}</el-dropdown-item>
                <el-dropdown-item command="/about">{{
                  $t("message.footName15")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              class="margin-right-40 pointer"
              @command="toPath"
            >
              <span class="el-dropdown-link fw-bold">
                {{ $t("message.footName1") }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>{{
                  $t("message.footName6")
                }}</el-dropdown-item>
                <el-dropdown-item :command="{path:'/ReleasePurchase',type:3}">{{
                  $t("message.footName7")
                }}</el-dropdown-item>
                <el-dropdown-item
                  :command="{path:'/personal_center/purchasing',type:3}"
                  >{{ $t("message.footName8") }}</el-dropdown-item
                >
                <el-dropdown-item :command="{path:'/personal_center/bidding?type=0',type:3}">{{
                  $t("message.footName9")
                }}</el-dropdown-item>
                <el-dropdown-item
                  :command="{path:'/personal_center/bid?type=1',type:4}"
                  >{{ $t("message.footName10") }}</el-dropdown-item
                >
                <el-dropdown-item disabled>{{
                  $t("message.footName11")
                }}</el-dropdown-item>
                <el-dropdown-item :command="{path:'/quotation_mana',type:4}">{{
                  $t("message.footName12")
                }}</el-dropdown-item>
                <el-dropdown-item
                  :command="{path:'/personal_center/handle',type:4}"
                  >{{ $t("message.footName13") }}</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{path:'/personal_center/sample',type:4}"
                  >{{ $t("message.footName14") }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <span class="margin-right-40 pointer">My management</span> -->
            <div
              class="margin-right-40 pointer relative"
              @click="toPath('/personal_center/notification?selectList=2')"
            >
              <img
                src="@img/auth/auth2.png"
                alt="消息"
                width="16px"
                height="18px"
              />
              <span
                class="absolute bgBtn"
                v-show="userInfo.unReadNum"
                style="
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  top: 0;
                  right: 0;
                  z-index: 10;
                "
              ></span>
            </div>
            <el-dropdown
              class="margin-right-40 pointer"
              trigger="click"
              :hide-on-click="false"
            >
              <span class="el-dropdown-link flex align-center">
                <img
                  src="@img/auth/auth3.png"
                  alt="语言"
                  width="16px"
                  height="16px"
                />
                <span class="margin-left-6 margin-right-6">{{
                  language
                }}</span>
                <!-- <img
                  src="/img/icon/arrowDown.png"
                  alt="下拉图标"
                  width="16px"
                  height="16px"
                /> -->
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>
                  <p class="fs-16 color-333">{{ $t("message.name34") }}</p>
                  <p class="fs-12" style="line-height: 14px">
                    {{ $t("message.name46") }}
                  </p>
                </el-dropdown-item>
                <el-dropdown-item disabled>
                  <span class="fs-16 color-333">{{
                    $t("message.name47")
                  }}</span>
                </el-dropdown-item>
                <el-dropdown-item style="background: #fff">
                  <el-select
                    v-model="language"
                    @change="changeLanguage"
                    :placeholder="$t('message.请选择')"
                  >
                    <el-option
                      v-for="(item, index) in countryList"
                      :key="index"
                      :label="item.language"
                      :value="item.language"
                    >
                    </el-option>
                  </el-select>
                </el-dropdown-item>
                <el-dropdown-item disabled>
                  <span class="fs-16 color-333">{{
                    $t("message.name48")
                  }}</span>
                </el-dropdown-item>
                <el-dropdown-item style="background: #fff">
                  <el-select
                    v-model="symbol"
                    disabled
                    :placeholder="$t('message.请选择')"
                  >
                  </el-select>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            
            <div
              class="flex align-center pointer margin-right-40"
              v-if="userInfo.id"
              @click="$router.push({ path: '/personal_center' })"
            >
              <div class="overflow-hidden radius" style="width: 36px;height: 36px;">
                <img
                  :src="
                    userInfo.avatar
                      ? userInfo.avatar
                      : 'img/index/index16.png'
                  "
                  alt="头像"
                  width="36px"
                  height="36px"
                  style="border-radius:50%"
                />
              </div>
              <span class="margin-left-10">{{ userInfo.dassler }}{{ userInfo.nickname }}</span>
            </div>
            <div
              class="loginbtn bgBtn flex align-center justify-center color-fff"
              v-else
              @click="$router.push({ path: '/login' })"
            >
              {{ $t("message.name33") }}
            </div>
            <div
              class="flex align-center pointer"
              v-if="userInfo.id"
              @click="outlogin"
            >
              <img
                src="@img/auth/outlogin.png"
                alt=""
                width="18px"
                height="18px"
              />
              <span class="margin-left-10 fw-bold">{{
                $t("message.退出")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 第一块 -->
      <div class="relative" style="background: #000">
        <el-carousel
          height="660px"
          :initial-index="initialIndex"
          arrow="never"
          @change="
            (index) => {
              initialIndex = index;
            }
          "
        >
          <el-carousel-item
            v-for="(item, index) in alldata.homeBannerList"
            :key="index"
            :style="
              'background:url(' +
              item.bannerImg +
              ') no-repeat center;background-size: cover;'
            "
          >
            <!-- <img :src="item.bannerImg" alt="" class="swperImg" /> -->
            <div class="topin">
              <div class="margin-top-120 inercont" style="height: 260px">
                <div class="flex align-center margin-bottom-16 pointer" v-show="item.jumpType == 2">
                  <span class="margin-right-20 fs-20">{{$t("message.name3")}}</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div style="font-size: 58px; width: 907px">
                  {{ item.describ }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="topin" @click="tojupm">
          
          <div class="fw-bold inercont">
            <div
              class="flex align-center justify-between margin-top-16"
              @click.stop=""
            >
              <div class="flex align-center pointer outMenu" slot="reference">
                <img
                  src="@img/index/index1.png"
                  alt="菜单"
                  width="24px"
                  height="24px"
                />
                <span class="margin-left-20">{{ $t("message.name2") }}</span>
                <!-- 主菜单hover后展示 -->
                <div class="hoverMenu bg-fff">
                  <Menu
                    :userInfo="userInfo"
                    @changeLanguage="changeLanguage"
                    :countryList="countryList"
                    :language="language"
                    :symbol="symbol"
                  />
                </div>
              </div>

              <div class="flex align-center margin-left-20">
                <!-- {{$t('message.hello')}} -->
                <span
                  v-for="(item, index) in $t('message.tabList')"
                  :key="index"
                  @click="toPath(item.path)"
                  class="pointer margin-left-16"
                  >{{ item.name }}</span
                >
              </div>
            </div>
            <div class="margin-top-80" style="height: 260px">
            </div>
            <!-- 搜索模块 -->
            <div
              class="searchBox flex align-center justify-between fs-16 relative"
              @click.stop=""
            >
              <div class="flex align-center flex1">
                <div
                  class="flex align-center padding-right-20 margin-right-20 pointer"
                  style="border-right: 1px solid #d9d9d9"
                >
                  <el-select
                    v-model="searchValue.selType"
                    placeholder="请选择"
                    style="border: none"
                  >
                    <el-option
                      :label="item.label"
                      :key="index"
                      v-for="(item, index) in $t('message.searchList')"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <input
                  type="text"
                  v-model="searchValue.keywords"
                  style="width:360px"
                  :placeholder="$t('message.请输入')"
                  slot="reference"
                  @focus="showSearchWindow=true"
                  @blur="closeSearchWindow"
                />
              </div>
              <div
                class="flex align-center justify-center pointer searchBtn"
                @click="(visible = false), toSearch()"
              >
                <i class="el-icon-search"></i>
                <span class="margin-left-10">{{ $t("message.name1") }}</span>
              </div>
              <div class="absolute search_window" v-show="showSearchWindow">
                <div class="flex flex-direction margin-bottom-25" v-if="unsearchList.length!==0">
                  <div class="flex align-center justify-between padding-x-24 border-box">
                    <span class="color-000 fs-20 line-23 fw-600">{{ $t('message.name51') }}</span>
                    <div style="width: 18px;height: 18px;" class="pointer" @click="delSearch">
                      <img src="@img/index/delete.png" width="100%" height="100%" />
                    </div>
                  </div>
                  <div v-for="(item,index) in unsearchList" :key="index"
                    @click="
                      searchValue.keywords = item;
                      visible = false;
                      $router.push({
                        path: '/searchResult',
                        query: searchValue,
                      })
                    "
                    class="flex align-center padding-x-24 border-box padding-y-13 search_hover pointer"
                  >
                    <div class="margin-right-12 flex-shrink" style="width: 30px;height: 30px;">
                      <img src="@img/index/searches.png" width="100%" height="100%" />
                    </div>
                    <span class="color-000 fs-16 line-18">{{ item }}</span>
                  </div>
                  <div class="padding-x-24 border-box flex align-center margin-top-5 pointer" @click="getMoreSearch()" @mousedown="preventBlur($event)" v-if="!moreSearch">
                    <span class="underline fs-16 color-666 line-18 margin-right-8">{{$t('message.name53')}}</span>
                    <div style="width:18px;height:18px;">
                      <img src="@img/icon/arrowDown2.png" width="100%" height="100%" />
                    </div>
                  </div>
                  <div class="padding-x-24 border-box flex align-center margin-top-5 pointer" @click="getMoreSearch()" @mousedown="preventBlur($event)" v-if="moreSearch">
                    <div style="width:18px;height:18px;">
                      <img src="@img/icon/arrowUp2.png" width="100%" height="100%" />
                    </div>
                  </div>
                </div>
                <div class="flex flex-direction">
                  <div class="flex align-center justify-between padding-x-24 border-box">
                    <span class="color-000 fs-20 line-23 fw-600">{{ $t('message.name50') }}</span>
                    <div class="pointer flex align-center" @click="getRecommSearch('rec')" @mousedown="preventBlur($event)">
                      <span class="margin-right-8 underline fs-16 line-000 color-000">{{ $t('message.name52') }}</span>
                      <div style="width: 18px;height: 18px;">
                        <img src="@img/index/refresh.png" width="100%" height="100%" />
                      </div>
                    </div>
                  </div>
                  <div v-for="(item,index) in recommSearchList" :key="index"
                    @click="
                      searchValue.keywords = item.productName;
                      visible = false; 
                      $router.push({
                        path: '/searchResult',
                        query: searchValue,
                      });
                    "
                    class="flex align-center padding-x-24 border-box padding-y-13 search_hover pointer"
                  >
                    <div class="margin-right-12 flex-shrink" style="width: 30px;height: 30px;">
                      <img src="@img/index/recommend.png" width="100%" height="100%" />
                    </div>
                    <span class="color-000 fs-16 line-18">{{ item.productName }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fs-16 flex align-center margin-top-30"
              v-if="searchList.length"
            >
              <span class="margin-right-10">{{ $t("message.name39") }}:</span>
              <span
                v-for="(item, index) in searchList.slice(0, 5)"
                @click="
                  (searchValue.keywords = item),
                    $router.push({ path: '/searchResult', query: searchValue })
                "
                class="margin-left-20 flex align-center padding-x-16 pointer"
                style="
                  height: 36px;
                  border: 1px solid #ffffff;
                  border-radius: 36px;
                "
                :key="index"
                >{{ item }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 第二块 -->
      <div class="top2 fw-bold">
        <div class="flex align-center justify-between">
          <!-- 采购方 -->
          <!-- @click="$router.push({path:'/ReleasePurchase'})" -->
          <div
            class="flex1 margin-right-48 pointer"
            @click="quaCertification(1)"
            v-if="showAllType != 4"
          >
            <div class="flex align-center justify-between">
              <div class="flex align-center fs-24 line-28 margin-bottom-4">
                <span class="margin-right-10 color-red">{{
                  showAllType == 1 ? $t("message.name4") : $t("message.name40")
                }}</span>
                <img
                  src="@img/index/index5.png"
                  alt="more"
                  width="24px"
                  height="24px"
                />
              </div>
              <div
                class="more"
                @click.stop="$router.push({ path: '/InquiryCenterCai' })"
              >
                {{ $t("message.name3") }}
              </div>
            </div>
            <div class="fs-16 color-999 margin-top-5 line-18">
              {{ $t("message.name6") }}
            </div>
          </div>
          <!-- 供应方 -->
          <!-- @click="$router.push({path:'/personal_center'})" -->
          <div
            class="flex1 pointer"
            @click="quaCertification(2)"
            v-if="showAllType != 3"
          >
            <div class="flex align-center justify-between">
              <div class="flex align-center fs-24 line-28 margin-bottom-4">
                <span class="margin-right-10 color-red">{{
                  showAllType == 1 ? $t("message.name5") : $t("message.name41")
                }}</span>
                <img
                  src="@img/index/index5.png"
                  alt="more"
                  width="24px"
                  height="24px"
                />
              </div>
              <div
                class="more"
                @click.stop="$router.push({ path: '/InquiryCenter' })"
              >
                {{ $t("message.name3") }}
              </div>
            </div>
            <div class="fs-16 color-999 margin-top-5 line-18">
              {{ $t("message.name7") }}
            </div>
          </div>
        </div>
        <div class="margin-top-24 flex align-start justify-between">
          <!-- 报价动态 -->
          <div class="bg-zhu radius-8 padding-x-24 margin-right-48 flex1">
            <div class="padding-y-30 text-center fs-24">
              {{ $t("message.name8") }}
            </div>
            <div
              class="bg-fff radius-4 padding-y-10 padding-x-20 flex align-center justify-between color-666 fs-16"
            >
              <span class="flex1">{{ $t("message.name10") }}</span>
              <span class="flex1 text-center">{{ $t("message.name11") }}</span>
              <span class="flex1 text-center">{{ $t("message.name12") }}</span>
            </div>
            <div class="padding-x-20 padding-y-24">
              <div
                v-for="(item, index) in alldata.offerList.slice(0, 5)"
                :key="index"
                style="line-height: 48px"
                class="fs-16 flex align-center justify-between"
              >
                <span class="flex1 u-line-1">{{ item.goodsName }}</span>
                <span class="flex1 text-center color-red"
                  >{{ item.offerPrice }} {{ alldata.symbol }}/{{
                    item.goodsUnit
                  }}</span
                >
                <span class="color-999 flex1 text-center"
                  >{{ item.time }}{{ item.timeUnit
                  }}-{{ $t("message.name44") }}</span
                >
              </div>
            </div>
          </div>
          <!-- 今日求购 -->
          <div class="bg-zhu radius-8 padding-x-24 flex1">
            <div class="padding-y-30 text-center fs-24">
              {{ $t("message.name9") }}
            </div>
            <div
              class="bg-fff radius-4 padding-y-10 padding-x-20 flex align-center justify-between color-666 fs-16"
            >
              <span class="flex1">{{ $t("message.name10") }}</span>
              <span class="flex1 text-center">{{ $t("message.name13") }}</span>
              <span class="flex1 text-center">{{ $t("message.name12") }}</span>
              <span class="flex1 text-center">{{ $t("message.name42") }}</span>
            </div>
            <div class="padding-x-20 padding-y-24">
              <div
                v-for="(item, index) in alldata.purchaseList.slice(0, 5)"
                :key="index"
                style="line-height: 48px"
                class="fs-16 flex align-center justify-between"
              >
                <span class="flex1 u-line-1">{{ item.goodsName }}</span>
                <span class="flex1 text-center color-red"
                  >{{ item.num }} {{ item.unit }}</span
                >
                <span class="color-999 flex1 text-center"
                  >{{ item.time }}{{ item.timeUnit }}-{{ $t("message.name44") }}</span
                >
                <div
                  class="flex1 fs-14 flex justify-center"
                  @click="ruleQuote(item)"
                >
                  <span class="color-333 text-right pointer quotationBtn">{{
                    $t("message.name43")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第三块发布采购信息 -->
      <div
        class="top3"
        v-if="showAllType != 4"
        :style="
          'background: url(' +
          backgroundImg +
          ') no-repeat center center;background-size: cover;'
        "
      >
        <div class="inercont flex align-center justify-between">
          <div class="color-fff fw-bold">
            <div style="font-size: 58px">{{ $t("message.name14") }}</div>
            <div class="fs-24 margin-right-120 margin-top-10 margin-bottom-100">
              {{ $t("message.name15") }}
            </div>
            <div
              class="fs-16 color-333 bg-fff InquiryBtn pointer"
              @click.stop="toPath('/InquiryCenter')"
            >
              {{ $t("message.name16") }}
            </div>
          </div>
          <div
            class="bg-fff radius-8 flex-shrink border-box padding-x-30 padding-top-36"
            style="width: 465px; height: 566px"
          >
            <div class="fs-24 fw-bold text-center" style="color: #60120a">
              {{ $t("message.name17") }}
            </div>
            <div
              class="fs-24 fw-bold text-center margin-bottom-10 margin-top-10"
            >
              {{ $t("message.name18") }}
            </div>
            <div class="margin-top-20 margin-bottom-6 fs-16">
              {{ $t("message.purchase12") }}{{ $t("message.purchase3") }}
            </div>
            <div class="flex">
              <el-cascader
                class="flex1"
                v-model="purchaseData.type"
                ref="deptIdCascader"
                :placeholder="$t('message.请选择采购类型')"
                :options="treeList"
                :props="{ checkStrictly: true, value: 'id', label: 'name' }"
                @change="selectCas"
                clearable
              ></el-cascader>
            </div>
            <div class="margin-top-20 margin-bottom-6 fs-16">
              {{ $t("message.purchase12") }}{{ $t("message.purchase4") }}
            </div>
            <div class="flex">
              <el-input
                v-model="purchaseData.goodsName"
                :placeholder="$t('message.请输入商品名称')"
              ></el-input>
            </div>
            <div class="margin-top-20 margin-bottom-6 fs-16">
              {{ $t("message.purchase12") }}{{ $t("message.purchase10") }}
            </div>
            <div class="purchase description">
              <el-input
                v-model="purchaseData.msg"
                type="textarea"
                :rows="5"
                resize="none"
                :placeholder="
                  $t(
                    'message.请填写采购商品的品种、规格、型号、包装、物流以及采购商所在地区、资质等要求、确保您收到准确的报价'
                  )
                "
              ></el-input>
            </div>
            <div
              class="bgBtn color-fff fs-16 fw-bold quoteBtn flex align-center justify-center pointer"
              @click="toQuote(2)"
            >
              {{ $t("message.name19") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 第四块新闻资讯动态 -->
      <div>
        <div class="inercont padding-top-100 padding-bottom-60">
          <div
            class="fs-58 text-center fw-bold margin-bottom-60"
            style="width: 971px; margin: auto"
          >
            {{ $t("message.name20") }}
          </div>
          <div class="flex align-start justify-between margin-top-60">
            <div class="flex1 margin-right-48">
              <div class="flex align-center justify-between margin-bottom-36">
                <span class="fs-24 fw-bold">{{ $t("message.name21") }}</span>
                <span class="more" @click="toPath('/news')">{{
                  $t("message.name3")
                }}</span>
              </div>
              <div class="" v-if="alldata.offiziellList.length">
                <div
                  class="relative radius-8 top4rel margin-bottom-16"
                  @click="
                    $router.push({
                      path:
                        '/news/newsDetail?id=' + alldata.offiziellList[0].id,
                    })
                  "
                >
                  <img
                    :src="alldata.offiziellList[0].sequence==1?alldata.offiziellList[0].flatPatternmaking:alldata.offiziellList[0].coverImg"
                    alt="官方动态"
                    width="100%"
                    style="object-fit: cover"
                    height="303px"
                  />
                  <div
                    class="absolute top4abs flex align-center justify-between color-fff"
                  >
                    <span class="fs-24 u-line-2 line-30" style="width: 70%;white-space: pre-wrap;word-break: break-word;">
                      {{ alldata.offiziellList[0].title }}
                    </span>
                    <div
                      class="pointer fs-16 flex align-center justify-center"
                      style="
                        height: 36px;
                        border-radius: 34px;
                        border: 1px solid #ffffff;
                        width: 92px;
                      "
                    >
                      <span>{{ $t("message.name45") }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="flex align-center justify-between"
                  v-if="alldata.offiziellList.length > 1"
                >
                  <div
                    v-for="(item, index) in alldata.offiziellList.length - 1"
                    :key="index"
                    @click="
                      $router.push({
                        path:
                          '/news/newsDetail?id=' +
                          alldata.offiziellList[index + 1].id,
                      })
                    "
                    style="width: 30%"
                    class="margin-right-16 pointer"
                    :style="index == 2 ? 'margin-right:0' : ''"
                  >
                    <div class="flex align-center justify-center bg-fa">
                      <img
                        :src="alldata.offiziellList[index + 1].coverImg"
                        height="150px"
                        class="radius-4"
                        width="100%"
                        alt="官方动态"
                      />
                    </div>
                    <div
                      class="padding-x-10 padding-top-16 padding-bottom-24 fs-14"
                    >
                      <div class="u-line-2" style="white-space: pre-wrap;word-break: break-word;">
                        {{ alldata.offiziellList[index + 1].title }}
                      </div>
                      <div class="color-999 margin-top-16">
                        {{
                          $timestampToDate(
                            alldata.offiziellList[index + 1].createTime
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex1">
              <div class="flex align-center justify-between margin-bottom-36">
                <span class="fs-24 fw-bold">{{ $t("message.name36") }}</span>
                <span class="more" @click="toPath('/news')">{{
                  $t("message.name3")
                }}</span>
              </div>
              <div class="" v-if="alldata.inoffiziellList.length">
                <div
                  class="relative radius-8 top4rel margin-bottom-16"
                  @click="
                    $router.push({
                      path:
                        '/news/newsDetail?id=' + alldata.inoffiziellList[0].id,
                    })
                  "
                >
                  <img
                    :src="alldata.inoffiziellList[0].sequence==1?alldata.inoffiziellList[0].flatPatternmaking:alldata.inoffiziellList[0].coverImg"
                    alt="行业动态"
                    width="100%"
                    style="object-fit: cover"
                    height="303px"
                  />
                  <div
                    class="absolute top4abs flex align-center justify-between color-fff"
                  >
                    <span class="fs-24 u-line-2 line-30" style="width: 70%;white-space: pre-wrap;word-break: break-word;">
                      {{ alldata.inoffiziellList[0].title }}
                    </span>
                    <div
                      class="pointer fs-16 flex align-center justify-center"
                      style="
                        height: 36px;
                        border-radius: 34px;
                        border: 1px solid #ffffff;
                        width: 92px;
                      "
                    >
                      <span>{{ $t("message.name45") }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="flex align-center justify-between"
                  v-if="alldata.inoffiziellList.length > 1"
                >
                  <div
                    v-for="(item, index) in alldata.inoffiziellList.length - 1"
                    :key="index"
                    @click="
                      $router.push({
                        path:
                          '/news/newsDetail?id=' +
                          alldata.inoffiziellList[index + 1].id,
                      })
                    "
                    style="width: 30%"
                    class="margin-right-16 pointer"
                    :style="index == 2 ? 'margin-right:0' : ''"
                  >
                    <div class="flex align-center justify-center bg-fa">
                      <img
                        :src="alldata.inoffiziellList[index + 1].coverImg"
                        height="150px"
                        width="100%"
                        class="radius-4"
                        alt="行业动态"
                      />
                    </div>
                    <div
                      class="padding-x-10 padding-top-16 padding-bottom-24 fs-14"
                    >
                      <div class="u-line-2" style="white-space: pre-wrap;word-break: break-word;">
                        {{ alldata.inoffiziellList[index + 1].title }}
                      </div>
                      <div class="color-999 margin-top-16">
                        {{
                          $timestampToDate(
                            alldata.inoffiziellList[index + 1].createTime
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第五块行业会展 -->
      <div class="bg-zhu">
        <div class="inercont padding-top-100 padding-bottom-80">
          <div class="fs-58 margin-bottom-60 text-center fw-bold">
            {{ $t("message.name22") }}
          </div>
          <div class="flex align-start">
            <div class="flex1 margin-right-48">
              <div class="flex align-center justify-between margin-bottom-30">
                <span class="fw-bold fs-24">{{ $t("message.name23") }}</span>
                <span
                  class="more fw-bold fs-16"
                  @click="toPath('/exhibitions')"
                  >{{ $t("message.name3") }}</span
                >
              </div>
              <div
                class="radius-8 bg-fff padding-y-30 padding-left-20 padding-right-30"
              >
                <div
                  v-for="(item, index) in alldata.domesticList.slice(0, 3)"
                  :key="index"
                  @click="
                    $router.push({
                      path: '/exhibitions/exhibitionsDetail?id=' + item.id,
                    })
                  "
                  class="flex align-center margin-bottom-48 pointer"
                  :style="
                    index == alldata.domesticList.length - 1
                      ? 'margin-bottom:0'
                      : ''
                  "
                >
                  <img
                    :src="item.coverImg"
                    alt="国内展会"
                    width="180"
                    height="122"
                    class="margin-right-20"
                    style="object-fit: cover; border-radius: 8px"
                  />
                  <div class="flex1 flex flex-direction justify-between" style="height: 122px;">
                    <div class="u-line-2 fs-24 margin-bottom-24">
                      {{ item.title }}
                    </div>
                    <div class="flex align-center justify-between fs-14">
                      <span class="color-red">
                        {{ comAddress(item) }}
                      </span>
                      <span class="color-999">{{
                        $timestampToDate(item.beginTime)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex1">
              <div class="flex align-center justify-between margin-bottom-30">
                <span class="fw-bold fs-24">{{ $t("message.name37") }}</span>
                <span
                  class="more fw-bold fs-16"
                  @click="toPath('/exhibitions')"
                  >{{ $t("message.name3") }}</span
                >
              </div>
              <div
                class="radius-8 bg-fff padding-y-30 padding-left-20 padding-right-30"
              >
                <div
                  v-for="(item, index) in alldata.abroadList.slice(0, 3)"
                  :key="index"
                  @click="
                    $router.push({
                      path: '/exhibitions/exhibitionsDetail?id=' + item.id,
                    })
                  "
                  class="flex align-center margin-bottom-48 pointer"
                  :style="
                    index == alldata.abroadList.length - 1
                      ? 'margin-bottom:0'
                      : ''
                  "
                >
                  <img
                    :src="item.coverImg"
                    alt="国际展会"
                    width="180"
                    height="122"
                    class="margin-right-20"
                    style="object-fit: cover; border-radius: 8px"
                  />
                  <div class="flex1 flex flex-direction justify-between" style="height: 122px;">
                    <div class="u-line-2 fs-24 margin-bottom-24">
                      {{ item.title }}
                    </div>
                    <div class="flex align-center justify-between fs-14">
                      <span class="color-red">{{ comAddress(item) }}</span>
                      <span class="color-999">{{
                        $timestampToDate(item.beginTime)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第六块行业文档 -->
      <div>
        <div class="inercont padding-y-100">
          <div class="fs-58 text-center fw-bold margin-bottom-60">
            {{ $t("message.name24") }}
          </div>
          <div class="flex align-start justify-between">
            <!-- 法律法规 -->
            <div
              class="flex1 margin-right-48 radius-8 padding-left-20 padding-right-10 padding-top-30"
              style="border: 1px solid #e7e7e7;width: 424px;height: 380px;box-sizing: border-box;"
            >
              <div class="flex align-center justify-between margin-bottom-20">
                <span class="fs-24 fw-bold">{{ $t("message.name25") }}</span>
                <span class="more" @click="toPath('/industry')">{{
                  $t("message.name3")
                }}</span>
              </div>
              <div
                class="fs-20 color-red u-line-2 margin-bottom-16"
                style="line-height: 24px; height: 48px"
              >
                {{ $t("message.name26") }}
              </div>
              <div>
                <div
                  v-for="(item, ind) in alldata.lawList"
                  :key="ind"
                  @click="
                    $router.push({
                      path: '/industry/industryDetail?id=' + item.id,
                    })
                  "
                  class="fs-16 margin-bottom-24 pointer u-line-1"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
            <!-- 行业标准 -->
            <div
              class="flex1 margin-right-48 radius-8 padding-left-20 padding-right-10 padding-top-30"
              style="border: 1px solid #e7e7e7;width: 424px;height: 380px;box-sizing: border-box;"
            >
              <div class="flex align-center justify-between margin-bottom-20">
                <span class="fs-24 fw-bold">{{ $t("message.name27") }}</span>
                <span class="more" @click="toPath('/industry')">{{
                  $t("message.name3")
                }}</span>
              </div>
              <div
                class="fs-20 color-red u-line-2 margin-bottom-16"
                style="line-height: 24px; height: 48px"
              >
                {{ $t("message.name28") }}
              </div>
              <div>
                <div
                  v-for="(item, ind) in alldata.standardList"
                  :key="ind"
                  @click="
                    $router.push({
                      path: '/industry/industryDetail?id=' + item.id,
                    })
                  "
                  class="fs-16 margin-bottom-24 pointer u-line-1"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
            <!-- 行业技术 -->
            <div
              class="flex1 radius-8 padding-left-20 padding-right-10 padding-top-30"
              style="border: 1px solid #e7e7e7;width: 424px;height: 380px;box-sizing: border-box;"
            >
              <div class="flex align-center justify-between margin-bottom-20">
                <span class="fs-24 fw-bold">{{ $t("message.name29") }}</span>
                <span class="more" @click="toPath('/industry')">{{
                  $t("message.name3")
                }}</span>
              </div>
              <div
                class="fs-20 color-red u-line-2 margin-bottom-16"
                style="line-height: 24px; height: 48px"
              >
                {{ $t("message.name30") }}
              </div>
              <div>
                <div
                  v-for="(item, ind) in alldata.technologyList"
                  :key="ind"
                  @click="
                    $router.push({
                      path: '/industry/industryDetail?id=' + item.id,
                    })
                  "
                  class="fs-16 margin-bottom-24 pointer u-line-1"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第七块海关资讯 -->
      <div class="top7">
        <div class="inercont">
          <div class="fs-58 text-center fw-bold margin-bottom-48">
            {{ $t("message.name31") }}
          </div>
          <div class="flex align-center justify-between flex-wrap">
            <div
              v-for="(item, index) in alldata.cerealList"
              @click="
                $router.push({ path: '/customs/customsDetail?id=' + item.id })
              "
              :key="index"
              style="width: 48%"
              class="border-box margin-bottom-30 bg-fff padding-top-30 padding-bottom-20 padding-left-48 padding-right-30 flex align-center radius-8"
            >
              <div class="margin-right-48 flex-shrink">
                <div
                  class="fs-36 fw-bold padding-bottom-10 text-center"
                  style="border-bottom: 1px solid"
                >
                  {{ setTime(2, item.createTime) }}
                </div>
                <div class="fs-16 color-666 margin-top-16">
                  {{ setTime(1, item.createTime) }}
                </div>
              </div>
              <div class="flex1">
                <div
                  class="u-line-1 fs-24 fw-bold margin-bottom-24"
                  style="width: 420px"
                >
                  {{ item.title }}
                </div>
                <div class="fs-14 line-22 color-666 u-line-2" style="width: 400px;height: 44px;">
                  {{ item.brief?item.brief:$t('message.暂无') }}
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in alldata.nutritionList"
              @click="
                $router.push({ path: '/customs/customsDetail?id=' + item.id })
              "
              :key="index"
              style="width: 48%"
              class="border-box pointer margin-bottom-30 bg-fff padding-top-30 padding-bottom-20 padding-left-48 padding-right-30 flex align-center"
            >
              <div class="margin-right-48 flex-shrink">
                <div
                  class="fs-36 fw-bold padding-bottom-10 text-center"
                  style="border-bottom: 1px solid"
                >
                  {{ setTime(2, item.createTime) }}
                </div>
                <div class="fs-16 color-666 margin-top-16">
                  {{ setTime(1, item.createTime) }}
                </div>
              </div>
              <div class="flex1">
                <div
                  class="u-line-1 fs-24 fw-bold margin-bottom-24"
                  style="width: 420px"
                >
                  {{ item.title }}
                </div>
                <div class="fs-14 color-666 u-line-2" style="width: 400px">
                  {{ item.brief }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="fs-16 pointer flex align-center justify-center"
            @click="toPath('/customs')"
            style="
              width: 150px;
              height: 36px;
              border-radius: 50px;
              border: 1px solid;
              margin: 0 auto;
            "
          >
            {{ $t("message.name3") }}
          </div>
        </div>
      </div>
      <!-- 第八块 -->
      <div class="" style="min-height: 1075px;">
        <div class="inercont padding-top-100 padding-bottom-60 flex flex-direction align-center" style="min-height: 1075px;">
          <div class="fs-58 fw-bold text-center margin-bottom-48">
            {{ $t("message.name38") }}
          </div>
          <div
            class="flex align-center justify-between padding-x-100 margin-bottom-60"
            style="width: 80%;"
          >
            <div
              v-for="(item, index) in $t('message.tabList2')"
              :key="index"
              :style="tab2act == index ? 'background:#f8f3f2' : ''"
              class="flex align-center justify-center pointer border-box padding-y-10"
              style="width: 30%; border: 1px solid #f8f3f2;"
              @click="tab2act = index"
            >
              <img
                :src="tab2act == index ? item.sleimg : item.unimg"
                width="48px"
                height="48px"
                alt=""
              />
              <span
                :class="
                  tab2act == index ? 'fs-22 color-601 fw-600' : 'fs-20 color-666'
                "
                class="fw-bold margin-left-14"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <!-- 商家 -->
          <div v-if="tab2act == 2" class="flex align-center flex-grow" style="height:100%">
            <div class="flex flex-direction align-center">
              <div class="flex align-center justify-between margin-bottom-56 relative">
                <div class="pointer absolute" style="width: 58px;height: 58px;left: -90px;" @click="changeShowShop(-1)" v-if="showShop!=0">
                  <img src="@img/index/shopLeft.png" width="100%" height="100%">
                </div>
                <div class="flex overflow-hidden justify-end" style="width: 100%;">
                  <div style="width: 100%;" class="padding-24 bg-fff radius-8 border-box border-f4-1">
                    <div class="flex align-center justify-between margin-bottom-30">
                      <div class="flex align-center" @click="$router.push({path:'/MerchantDetails',query:{id:alldata.shopList[showShop].id}})">
                        <img :src="alldata.shopList[showShop].shopIcon" width="64px" height="64px" />
                        <div class="margin-left-10">
                          <p class="more" style="color: #333;display: inline-block;">{{alldata.shopList[showShop].shopName}}</p>
                          <p class="margin-top-10 flex align-center color-222">{{alldata.shopList[showShop].yearNum}}{{$t('message.shop4')}} <span class="dots"></span> {{alldata.shopList[showShop].country}}｜{{alldata.shopList[showShop].cityName}}
                          </p>
                        </div>
                      </div>
                      <div class="flex align-center fs-16">
                        <img :src="alldata.shopList[showShop].isCollect==1?'img/auth/Heart1.png':'img/auth/Heart.png'" @click="toCollect(alldata.shopList[showShop])"
                          class="pointer" width="24px" height="24px" />
                        <p class="chatBtn" @click="toChat(alldata.shopList[showShop])">{{$t('message.shop1')}}</p>
                        <p class="bgBtn color-fff chatBtn"
                          @click="$router.push({path:'/MerchantDetails',query:{id:alldata.shopList[showShop].id}})">
                          {{$t('message.shop2')}}
                        </p>
                      </div>
                    </div>
                    <div class="flex align-center justify-between">
                      <div class="fs-16 flex-shrink margin-right-164" style="width: 180px;">
                        <p>{{$t('message.shop3')}}</p>
                        <p class="margin-top-16 " v-for="(ite,ind) in alldata.shopList[showShop].productNameList" :key="ind"
                          v-show="ind<3">·{{ite.name}}</p>
                        <div v-if="alldata.shopList[showShop].productNameList">
                          <p class="more margin-top-10" v-show="alldata.shopList[showShop].productNameList.length>3" style="width: fit-content;" @click="$router.push({path:'/MerchantDetails',query:{id:alldata.shopList[showShop].id}})">{{ $t('message.name3') }}</p>
                        </div>
                        <p class="margin-top-48">{{$t('message.bidding22')}}</p>
                        <p class="fw-bold" style="margin-top: 4px;">{{alldata.shopList[showShop].contactTel}}</p>
                        <p class="margin-top-10">{{$t('message.bidding23')}}</p>
                        <p class="fw-bold" style="margin-top: 4px;">{{alldata.shopList[showShop].contactEmail}}</p>
                      </div>
                      <div class="top9swiper flex overflow-hidden relative" style="width: 1000px;min-height:340px;">
                        <div class="absolute shop_left_btn flex align-center padding-left-12 border-box" v-if="alldata.shopList[showShop].showNum!=0" @click="changeShopShowPro(-1)">
                          <div class="pointer" style="width: 32px;height: 32px;">
                            <img src="@img/icon/shopLeft.png" width="100%" height="100%">
                          </div>
                        </div>
                        <div class="absolute shop_right_btn flex align-center padding-right-12 border-box justify-end" v-if="alldata.shopList[showShop].productList.length>3 && this.alldata.shopList[showShop].showNum + 4 <= this.alldata.shopList[showShop].productList.length" @click="changeShopShowPro(1)">
                          <div class="pointer" style="width: 32px;height: 32px;">
                            <img src="@img/icon/shopRight.png" width="100%" height="100%">
                          </div>
                        </div>
                        <div class="bg-fff margin-right-40 border-box pointer overflow-hidden radius-8 flex-shrink" style="height: 340px" @click="$router.push({ path: '/goodsDetail?id='+ite.id })" v-for="(ite, ind) in alldata.shopList[showShop].productList" :key="ind" v-if="ind>=alldata.shopList[showShop].showNum && ind<=alldata.shopList[showShop].showNum+3">
                          <div class="flex align-center justify-center flex-shrink" style="width: 240px;height: 240px;">
                            <img :src="ite.coverImg" width="100%" height="100%" style="object-fit: cover"
                              alt="" />
                          </div>
                          <div class="padding-10">
                            <p class="fs-14 u-line-2">{{lan=='English'?ite.productNameEnglish:ite.productName}}</p>
                            <p class="fs-24 fw-bold margin-top-10">{{ite.symbol}}{{ite.productPrice}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink bg-f5 margin-left-20 radius-8 flex-direction align-center justify-center pointer" style="width: 172px;height: 484px;" v-if="showShop==alldata.shopList.length-1" @click="$router.push({path:'/brandMerchant'})">
                    <div style="width: 58px;height: 58px;" class="margin-bottom-10">
                      <img src="@img/index/viewMore2.png" width="100%" height="100%" alt="">
                    </div>
                    <span class="color-666 fs-16 line-18">{{ $t('message.查看更多') }}</span>
                  </div>
                </div>
                <div class="pointer absolute" style="width: 58px;height: 58px;right: -90px;" @click="changeShowShop(1)" v-if="showShop!=alldata.shopList.length-1">
                  <img src="@img/index/shopRight.png" width="100%" height="100%">
                </div>
              </div>
            </div>
          </div>
          <!-- 源头厂家 -->
          <div class="flex flex-direction align-center" v-if="tab2act == 0" style="width: 100%;">
            <div class="flex align-center justify-center margin-bottom-56" style="width: 106%;">
              <div class="flex flex-wrap margin-right--20 margin-bottom--20" style="width: 77%;">
                <div class="flex flex-direction margin-right-20 margin-bottom-20 radius-8 product_hover overflow-hidden" style="width: 200px;" v-for="(item,index) in alldata.productList" v-if="index>=productMin && index<productMax" :key="index" @click="$router.push({ path: '/goodsDetail?id=' + item.id })">
                  <div style="width: 200px;height: 200px;">
                    <img :src="item.coverImg" width="100%" height="100%" alt="">
                  </div>
                  <div class="padding-8 border-box flex flex-direction" style="width: 100%;height: 100px;">
                    <div style="width: 140px;" class="u-line-1 color-333 fs-14 line-16">{{ item.productName }}</div>
                    <div class="margin-y-10 color-333 fs-24 line-28 fw-600">{{ item.symbol }}{{ item.productPrice }}</div>
                    <div class="flex align-center">
                      <div style="width: 20px;height: 20px;" class="margin-right-4 flex-shrink">
                        <img :src="item.shopIcon" width="100%" height="100%">
                      </div>
                      <span class="fs-14 line-16 color-999 u-line-1">{{item.shopName?item.shopName:$t('message.暂无名称')}}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-direction align-center justify-center bg-f5 radius-8 pointer" style="width: 200px;height: 300px;" @click="$router.push({ path: '/MerchantDetails/allGoods?id=2' })">
                  <div style="width: 58px;height: 58px;" class="margin-bottom-10">
                    <img src="@img/index/viewMore2.png" width="100%" height="100%" alt="">
                  </div>
                  <span class="color-666 fs-16 line-18">{{ $t('message.查看更多') }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 优质推荐 -->
          <div class="flex flex-direction align-center" v-if="tab2act == 1" style="width: 100%;">
            <div class="flex align-center justify-center margin-bottom-56" style="width: 106%;">
              <div class="flex flex-wrap margin-right--20 margin-bottom--20" style="width: 77%;">
                <div class="flex flex-direction margin-right-20 margin-bottom-20 radius-8 product_hover overflow-hidden" style="width: 200px;" v-for="(item,index) in alldata.productShopList" v-if="index>=TJMin && index<TJMax" :key="index" @click="$router.push({ path: '/goodsDetail?id=' + item.id })">
                  <div style="width: 200px;height: 200px;">
                    <img :src="item.coverImg" width="100%" height="100%" alt="">
                  </div>
                  <div class="padding-8 border-box flex flex-direction" style="width: 100%;height: 100px;">
                    <div style="width: 140px;" class="u-line-1 color-333 fs-14 line-16">{{ item.productName }}</div>
                    <div class="margin-y-10 color-333 fs-24 line-28 fw-600">{{ item.symbol }}{{ item.productPrice }}</div>
                    <div class="flex align-center">
                      <div style="width: 20px;height: 20px;" class="margin-right-4 flex-shrink">
                        <img :src="item.shopIcon" width="100%" height="100%">
                      </div>
                      <span class="fs-14 line-16 color-999 u-line-1">{{item.shopName?item.shopName:$t('message.暂无名称')}}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-direction align-center justify-center bg-f5 radius-8 pointer" style="width: 200px;height: 300px;" @click="$router.push({ path: '/selfRun' })">
                  <div style="width: 58px;height: 58px;" class="margin-bottom-10">
                    <img src="@img/index/viewMore2.png" width="100%" height="100%" alt="">
                  </div>
                  <span class="color-666 fs-16 line-18">{{ $t('message.查看更多') }}</span>
                </div>
              </div>
            </div>
          </div>

          
          <div class="relative bg-601-1" style="width: 760px;height: 3px;">
            <div class="absolute bg-601" :style="{'left': comLeft(tab2act),'top': '0','bottom': '0','transition': 'all ease-in-out .3s','width': '33%',}"></div>
          </div>
          <!--  -->
        </div>
      </div>
      <!-- 第九块招标 -->
      <div class="bg-zhu padding-y-100">
        <div class="inercont">
          <div class="fs-58 fw-bold margin-bottom-48 text-center">
            {{ $t("message.name35") }}
          </div>
          <div class="top9swiper relative padding-left-20">
            <swiper :options="swiperOption" style="width: 1226px;">
              <swiper-slide
                v-for="(item, index) in alldata.tenderList"
                :key="index"
                v-if="index<6"
              >
                <div
                  class="bg-fff margin-right-40 padding-y-30 padding-x-20 border-box pointer radius-10"
                  @click="rulePath(item)"
                  style="height: 440px"
                >
                  <div class="u-line-2 fs-24 fw-bold" style="height:48px;">
                    {{ item.name }}
                  </div>
                  <div class="margin-y-20 flex align-center">
                    <img
                      src="@img/index/index11.png"
                      width="20px"
                      height="20px"
                      alt=""
                    />
                    <span class="margin-left-10 fs-14 color-red"
                      >{{ $timestampToDate(item.startTime) }}——{{
                        $timestampToDate(item.endTime)
                      }}</span
                    >
                  </div>
                  <div
                    class="flex align-center justify-center margin-top-20 margin-bottom-30 overflow-hidden radius-8"
                  >
                    <img
                      :src="item.coverPlan"
                      width="100%"
                      height="200px"
                      style="object-fit: cover"
                      alt=""
                    />
                  </div>
                  <div
                    class="bgBtn flex align-center justify-center color-fff fs-16"
                    style="height: 40px; border-radius: 27px"
                  >
                    <i class="el-icon-circle-plus-outline"></i>
                    <span class="margin-left-10">{{
                      $t("message.name32")
                    }}</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div style="width: 275px;height: 440px;" class="pointer flex align-center justify-center flex-direction bg-f0e radius-8" @click="$router.push({ path: '/biddingHall' })">
                  <div style="width: 58px;height: 58px;" class="margin-bottom-10">
                    <img src="@img/index/viewMore.png" width="100%" height="100%" alt="">
                  </div>
                  <span class="color-601 fs-16 line-18">{{ $t('message.查看更多') }}</span>
                </div>
              </swiper-slide>
            </swiper>
            <img
              src="@img/index/swiperL1.png"
              class="swiper-button-prev"
              alt=""
              style="width: 50px; height: 50px; left: -50px"
              slot="button-prev"
            />
            <img
              src="@img/index/swiperR1.png"
              class="swiper-button-next"
              alt=""
              style="width: 50px; height: 50px; right: -50px"
              slot="button-next"
            />
          </div>
        </div>
      </div>
      <Foot />
    </div>
    <router-view
      :treeList="treeList"
      :countryList="countryList"
      :phoneList="phoneList"
      :showAllType="showAllType"
    />

  </div>
  
</template>

<script>
import TencentCloudChat from '@tencentcloud/chat';
import Emoji from '@/components/emoji.vue'
import { TUILogin } from "@tencentcloud/tui-core";
import { genTestUserSig } from ".././TUIKit";

export default {
  name: "HomeView",
  components: {
    Emoji,
  },
  data() {
    return {
      showSearchWindow:false,
      showChat:false,
      shopId:null,
      chatId:null,
      chatInput:'',
      chatTime: new Date().getTime(),
      timer:null,
      emojiVisible:false,
      chatList:[],
      // 在线咨询 ↑
      lan:localStorage.getItem('language'),
      userId:localStorage.getItem('userId'),
      initialIndex: 0,
      searchValue: {
        selType: "1",
        keywords: "",
      },
      swiperOption: {
        slidesPerView: 4,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: false,
        // 设置轮播可循环
        loop: false,
      },
      value: "",
      searchList: [],
      unsearchList: [],
      moreSearch: false,
      tab2act: 0,
      countryList: [],
      phoneList:[],
      language: "English",
      symbol: "$",
      alldata: {
        offiziellList: [],
        inoffiziellList: [],
        offerList: [],
        purchaseList: [],
        domesticList:[],
        abroadList:[],
      },
      showShop:0,
      showitem:false,
      showProduct:0,
      productMin:0,
      productMax:9,
      showTJ:0,
      TJMin:0,
      TJMax:9,
      treeList: [],
      recommSearchList: [],
      userInfo: {
        id: "",
        unReadNum: 0,
      },
      showAllType: 1, //1未登录、未认证 3采购方 4供应商 5采购供应

      purchaseData: {
        type: [],
        msg: "",
        goodsName: "",
      },
      system: {
        phone: "",
        email: "",
        twoCode: "",
        wLogo: "/img/index/logo.png", //白logo
        bLogo: "", //
        powerAttorney: "", //委托书
      },
      backgroundImg: "",
      visible: false,
    };
  },
  created() {
    this.init();
  },
  mounted(){
    this.init()
  },
  methods: {
    chooseEmojiDefault(e){
      this.chatInput = this.chatInput + e
    },
    closeSearchWindow(){
      setTimeout(() => {
        this.showSearchWindow=false
      }, 1000);
    },
    comAddress(item){
      let enAddress = ''
      if(!item){
        return
      }
      if(item.countryName){
        enAddress = enAddress + item.countryName
      }
      if(item.provinceName){
        enAddress = enAddress + item.provinceName
      }
      if(item.cityName){
        enAddress = enAddress + item.cityName
      }
      if(item.areaName){
        enAddress = enAddress + item.areaName
      }
      if(item.address){
        enAddress = enAddress + item.address
      }
      if(enAddress==''){
        enAddress = this.$t('message.暂无地址')
      }
      return enAddress
    },
    // 聊天
    toChat(item) {
      if (!localStorage.getItem("userId")) {
        this.$router.push({
          path: '/login'
        });
        return false;
      }
      this.$router.push({
        path:'/chat',
        query:{
          userId:item.contactTel
        }
      })
    },
    changeShowShop(val){
      if(val==1){
        if(this.showShop<this.alldata.shopList.length-1){
          this.showShop += 1
        }else{
          this.showShop = 0
        }
      }else if(val==-1){
        if(this.showShop==0){
          this.showShop = this.alldata.shopList.length-1
        }else{
          this.showShop -= 1
        }
      }
    },
    comLeft(val){
      let left = val*33
      return `${left}%`
    },
    // 收藏
    toCollect(item) {
      if (!localStorage.getItem("userId")) {
        this.$router.push({
          path: "/login"
        });
        return false;
      }
      this.$http({
        url: '/admin-api/biz/user-collect/create',
        method: "post",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        data: {
          contactId: item.id,
          type: 6,
          userId: localStorage.getItem("userId"),
        },
      }).then((res) => {
        if (res.data.code == 0) {
          if (item.isCollect == 1) {
            item.isCollect = 0;
          } else {
            item.isCollect = 1;
          }
        }
      });
    },
    selectCas(e){
      if(e.length==3){
        if(this.$refs.deptIdCascader){
          this.$refs.deptIdCascader.dropDownVisible = false
        }
      }
    },
    // 搜索
    getRecommSearch(type) {
      this.$http({
        url: "/app-api/biz/open/open-product-name",
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          if (type == "rec") {
            this.$Toast(this.$t("message.已刷新"));
          }
          this.recommSearchList = res.data.data;
        }
      });
    },
    preventBlur(e){
      e.preventDefault()
    },
    getMoreSearch() {
      this.moreSearch = !this.moreSearch;
      if (this.moreSearch) {
        this.unsearchList = this.searchList;
      } else {
        this.unsearchList = this.searchList.slice(0, 3);
      }
    },
    toSearch() {
      if (
        this.searchList.indexOf(this.searchValue.keywords) == -1 &&
        this.searchValue.keywords != ""
      ) {
        this.searchList.unshift(this.searchValue.keywords);
        this.unsearchList = this.searchList.slice(0, 3);
        localStorage.setItem("search_list", JSON.stringify(this.searchList));
      }
      this.$router.push({
        path: "/searchResult",
        query: this.searchValue,
      });
    },
    delSearch() {
      this.$alert(this.$t("message.确定清空历史记录?"), "", {
        confirmButtonText: this.$t("message.确定"),
        showClose: false,
        showCancelButton: true,
        cancelButtonText: this.$t("message.取消"),
        confirmButtonClass: this.$t("message.确认"),
        cancelButtonClass: this.$t("message.取消"),
        callback: (action) => {
          if (action == "confirm") {
            localStorage.removeItem("search_list");
            this.searchList = [];
            this.unsearchList = [];
          }
        },
      });
    },
    // 修改语言
    changeLanguage(value) {
      this.language = value;
      this.countryList.forEach((el) => {
        if (el.language == value) {
          this.symbol = el.symbol;
          localStorage.setItem("language", this.language);
          localStorage.setItem("symbol", this.symbol);
          if (this.language == "简体中文") {
            this.$i18n.locale = "zh";
          } else {
            this.$i18n.locale = "en";
          }
          this.$router.go(0);
        }
      });
    },
    // 切换商家展示商品
    changeShopShowPro(val){
      if(val==1){
        this.alldata.shopList[this.showShop].showNum += 1
      } else if(val==-1){
        this.alldata.shopList[this.showShop].showNum -= 1
      }
    },
    // 获取首页数据
    openGet() {
      // 首页数据
      this.$http({
        url: "/app-api/biz/open/get",
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.alldata = res.data.data;
          let shopList = []
          this.alldata.shopList.forEach(item=>{
            item = {...item,showNum:0}
            shopList.push(item)
          })
          this.alldata.shopList = shopList
        }
      });
      // 背景图
      this.$http({
        url: "/admin-api/biz/background/page",
        method: "get",
        params:{
          portType:1
        },
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.backgroundImg = res.data.data.list[0].backgroundImg;
          // this.alldata = res.data.data;
        }
      });

      // 配置文件
      this.$http({
        url: "/admin-api/biz/logo/get-type",
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          let datas = res.data.data;
          datas.forEach((el) => {
            if (el.type == 1) {
              this.system.wLogo = el.logoUrl;
            } else if (el.type == 2) {
              this.system.bLogo = el.logoUrl;
            } else if (el.type == 3) {
              this.system.twoCode = el.logoUrl;
            } else if (el.type == 4) {
              this.system.powerAttorney = el.logoUrl;
            } else if (el.type == 5) {
              this.system.email = el.logoUrl;
            } else if (el.type == 6) {
              this.system.phone = el.logoUrl;
            }
          });
          // this.alldata = res.data.data;
        }
      });
    },
    // 数据初始化
    init() {
      // 语言初始化
      if (localStorage.getItem("language")) {
        this.language = localStorage.getItem("language");
        this.symbol = localStorage.getItem("symbol");
        if (this.language == "简体中文") {
          this.$i18n.locale = "zh";
        } else {
          this.$i18n.locale = "en";
        }
      } else {
        localStorage.setItem("language", this.language);
        localStorage.setItem("symbol", this.symbol);
      }
      // 搜索历史初始化
      if (localStorage.getItem("search_list")) {
        this.searchList = JSON.parse(localStorage.getItem("search_list"));
        this.unsearchList = this.searchList.slice(0, 3);
      } else {
        this.searchList = [];
        this.unsearchList = [];
      }
      // 获得国家信息列表
      this.$http({
        url: "/admin-api/biz/country/list",
        // url: "/admin-api/biz/phone-prefix/country-list",
        method: "",
        data: {},
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.countryList = res.data.data;
        }
      });
      // 获得电话区号列表
      this.$http({
        url: "/admin-api/biz/phone-prefix/list",
        method: "",
        data: {},
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.phoneList = res.data.data;
        }
      });
      // 所有产品类型树形结构
      this.$http({
        // 1产品类型 2企业类型 3行业资讯类型 4行业标准类型 5行业技术类型6文章类型7投诉类型8海关类型9行业会展
        url: "/app-api/biz/open/type-tree?type=1",
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.treeList = res.data.data;
        }
      });
      this.openGet();
      this.getRecommSearch();
      // 用户详情
      if (this.userId) {
        this.$http({
          // url: "/admin-api/system/user/admin-get-user-enterprise-info",
          url: "/app-api/biz/open/open-user-info",
          method: "get",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          params: {
            // userId: localStorage.getItem("userId"),
          },
        }).then((res) => {
          let {code,data} = res.data
          if (code == 0 && data) {
            this.userInfo = data;
            localStorage.setItem("userIdPhone", data.mobile);
            //1未登录、未认证 2待审核  3采购方 4供应商 5采购供应
            if (data.authStatus == 1) {
              this.showAllType = data.enterpriseType;
            } else {
              if (data.examineStatus == 1) {
                this.showAllType = 2;
              }
            }
            let options = {SDKAppID:1600031095}
            let chat = TencentCloudChat.create(options)
            // 获取用户信息更新im信息
            chat.logout().then(()=>{
              TUILogin.login({
                SDKAppID:1600031095,
                userID:data.mobile,
                userSig: genTestUserSig({
                  SDKAppID:1600031095,
                  secretKey:"0724dd13806f2a588fee063ba5614bee8da46727396cf48b21e26b9ed78dc1bb",
                  userID:data.mobile,
                }).userSig,
                // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true  
                useUploadPlugin: true,
                framework: "vue2",
              }).then(()=>{
                chat.updateMyProfile({
                  nick:data.dassler+data.nickname,
                  avatar:data.avatar
                })
                this.userInfo.unReadNum = Number(this.userInfo.unReadNum) + Number(chat.getTotalUnreadMessageCount())
              });
            })
            // showAllType:1,
          } else if (!data) {
            localStorage.removeItem("userId");
          }
        });
      }
    },

    // 轮播图跳转页面
    tojupm() {
      if (this.alldata.homeBannerList[this.initialIndex].jumpType == 2) {
        this.$router.push({
          path:
            "/swiperDetail?id=" +
            this.alldata.homeBannerList[this.initialIndex].id,
        });
      }
      // 商品
      else if(this.alldata.homeBannerList[this.initialIndex].jumpType == 3){
        this.$router.push({
          path:
            "/goodsDetail?id=" +
            this.alldata.homeBannerList[this.initialIndex].infoId,
        });
      }
      // 商家
      else if(this.alldata.homeBannerList[this.initialIndex].jumpType == 4){
        this.$router.push({
          path:
            "/MerchantDetails?id=" +
            this.alldata.homeBannerList[this.initialIndex].infoId,
        });
      }
      // 新闻资讯
      else if(this.alldata.homeBannerList[this.initialIndex].jumpType == 5){
        this.$router.push({
          path:
            "/news/newsDetail?id=" +
            this.alldata.homeBannerList[this.initialIndex].infoId,
        });
      }
      // 海关资讯
      else if(this.alldata.homeBannerList[this.initialIndex].jumpType == 6){
        this.$router.push({
          path:
            "/customs/customsDetail?id=" +
            this.alldata.homeBannerList[this.initialIndex].infoId,
        });
      }
      // 行业文档
      else if(this.alldata.homeBannerList[this.initialIndex].jumpType == 7){
        this.$router.push({
          path:
            "/industry/industryDetail?id=" +
            this.alldata.homeBannerList[this.initialIndex].infoId,
        });
      }
      // 行业会展
      else if(this.alldata.homeBannerList[this.initialIndex].jumpType == 8){
        this.$router.push({
          path:
            "/exhibitions/exhibitionsDetail?id=" +
            this.alldata.homeBannerList[this.initialIndex].infoId,
        });
      }
    },
    // 跳转前判断
    rulePath(item){
      if(this.showAllType!=4 && this.showAllType!=5){
        this.$Toast(this.$t("message.您没有投标的权限"))
        return
      }
      this.toPath({path: '/biddingHall/ViewDetails?id=' + item.id,type:4})
    },
    // 跳转页面
    toPath(path) {
      if (path.type) {
        if (this.showAllType == 5) {
          this.$router.push({
            path: path.path,
          });
        } else if (this.showAllType == path.type) {
          this.$router.push({
            path: path.path,
          });
        } else {
          this.$message(this.$t("message.暂无权限"));
					// 未登录
					if (!this.userInfo.id) {
						this.$router.push({
							path: "/login",
						});
					}
        }
        return;
      }
      // 采购商
      if (path == "/InquiryCenter" && this.showAllType == 3) {
        this.$router.push({
          path: "/InquiryCenterCai",
        });
        return false;
      }
      this.$router.push({
        path: path,
      });
    },
    // 供应商、采购商认证
    quaCertification(type) {
      // 未登录
      if (!this.userInfo.id) {
        this.$router.push({
          path: "/login",
        });
        return false;
      }
      if (this.showAllType == 2) {
        this.$Toast(this.$t("message.您的认证申请正在审核中，请耐心等待"));
        return false;
      }

      let mess = "",
        confirmButtonText = "";
      // 发起报价
      // 未完成企业认证
      if (this.userInfo.authStatus == 0) {
        // 供应
        if (type == 1) {
          mess = this.$t("message.企业认证完成后可发起报价");
          confirmButtonText = this.$t("message.立即认证");
        }
        // 	发布采购信息
        else if (type == 2) {
          mess = this.$t("message.企业认证完成后可发布采购信息");
          confirmButtonText = this.$t("message.立即认证");
        }
        this.$alert(mess, "", {
          confirmButtonText: confirmButtonText,
          showClose: false,
          showCancelButton: true,
          cancelButtonText: this.$t("message.取消"),
          confirmButtonClass: this.$t("message.确认"),
          cancelButtonClass: this.$t("message.取消"),
          callback: (action) => {
            if (action == "confirm") {
              this.$router.push({
                path: "/authentication",
              });
            }
          },
        });
      }
      // 完成企业认证
      else if (this.userInfo.authStatus == 1) {
        // this.$router.push({
        // 	path: "/ReleasePurchase"
        // })
        if (type == 1) {
          this.$router.push({
            path: "/ReleasePurchase",
          });
        }
        // 供应商
        else if (type == 2) {
          this.$router.push({
            path: "/InquiryCenter",
          });
        }
      }
    },
    // 退出登录
    outlogin() {
      this.$alert(this.$t("message.确定退出登录?"), "", {
        confirmButtonText: this.$t("message.确定"),
        showClose: false,
        showCancelButton: true,
        cancelButtonText: this.$t("message.取消"),
        confirmButtonClass: this.$t("message.确认"),
        cancelButtonClass: this.$t("message.取消"),
        callback: (action) => {
          if (action == "confirm") {
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            this.$router.replace("/login");
          }
        },
      });
    },
    // 报价前验证
    ruleQuote(item){
      if(this.userInfo.shopId==item.shopId){
        this.$Toast(this.$t('message.无法对自己的商铺进行该操作'));
      } else{
        this.toQuote(1,item.id)
      }
    },
    // 去报价
    toQuote(type, id) {
      // 未登录
      if (!this.userInfo.id) {
        this.$router.push({
          path: "/login",
        });
        return;
      }
      let mess = "",
        confirmButtonText = "";
      // 发起报价
      // 未完成企业认证
      if (this.showAllType==1) {
        // 供应
        mess = this.$t("message.企业认证完成后可发起报价");
        confirmButtonText = this.$t("message.立即认证");
        this.$alert(mess, "", {
          confirmButtonText: confirmButtonText,
          showClose: false,
          showCancelButton: true,
          cancelButtonText: this.$t("message.取消"),
          confirmButtonClass: this.$t("message.确认"),
          cancelButtonClass: this.$t("message.取消"),
          callback: (action) => {
            if (action == "confirm") {
              this.$router.push({
                path: "/authentication",
              });
            }
          },
        });
      }else if(this.showAllType==2){
		  this.$Toast(this.$t("message.您的认证申请正在审核中，请耐心等待"));
	  }
      // 完成企业认证
      else if (this.userInfo.authStatus == 1) {
        if (type == 1) {
          if (this.showAllType == 3) {
            this.$Toast(this.$t("message.买方没有报价权限"));
            return false;
          }
          // 报价
          this.$router.push({
            path: "/quotedPrice?id=" + id,
          });
        }
        // 发布求购
        else if (type == 2) {
          this.purchaseData.type = JSON.stringify(this.purchaseData.type);
          this.$router.push({
            path: "/ReleasePurchase",
            query: this.purchaseData,
          });
        }
      }
    },
    // 设置时间
    setTime(type, time) {
      const dateObj = new Date(time);
      // 年月
      if (type == 1) {
        return `${dateObj.getFullYear()}-${String(
          dateObj.getMonth() + 1
        ).padStart(2, "0")}`;
      }
      // 日
      else {
        return `${String(dateObj.getDate()).padStart(2, "0")}`;
      }
    },
  },
};
</script>
<style scoped>
.swperImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topin {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  color: #fff;
  z-index: 100;
  padding: 10px 0;
  box-sizing: border-box;
}

/* @media screen and (max-width: 1440px) {
		.topin {
			width: 1200px;
		}
	} */
.outMenu:hover .hoverMenu {
  display: block;
}

.hoverMenu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  color: #fff;
  z-index: 100;
  box-sizing: border-box;
  color: #333333 !important;
}

.top2 {
  max-width: 1440px;
  min-width: 1200px;
  padding: 70px 40px 60px;
  box-sizing: border-box;
  margin: 0 auto;
}

.loginbtn {
  width: 110px;
  height: 36px;
  margin-left: 18px;
  border-radius: 57px 57px 57px 57px;
  cursor: pointer;
}

.searchBox {
  width: 680px;
  height: 56px;
  background: #ffffff;
  border-radius: 27px 27px 27px 27px;
  box-sizing: border-box;
  padding: 4px;
}

/deep/.searchBox .el-input__inner {
  border: none !important;
  background: transparent !important;
  width: 120px !important;
}

.searchBtn {
  width: 125px;
  height: 48px;
  background: #e82511;
  border-radius: 27px 27px 27px 27px;
}

.quotationBtn {
  width: 82px;
  height: 30px;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  border: 1px solid #333333;
  text-align: center;
  line-height: 30px;
  transition: all ease-in-out .3s;
}
.quotationBtn:hover{
  background-color: #E82511;
  border-color: #E82511;
  color: #fff;
}

/*  */
.top3 {
  height: 650px;
  background: url("/public/img/index/bg2.png") no-repeat center center;
  background-size: cover;
  padding: 40px 0 45px 0;
  box-sizing: border-box;
}

.InquiryBtn {
  width: 187px;
  height: 48px;
  border-radius: 60px 60px 60px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quoteBtn {
  width: 271px;
  height: 42px;
  margin: 30px auto 0;
  border-radius: 43px 43px 43px 43px;
}

.top4rel {
  height: 303px;
  overflow: hidden;
}

.top4abs {
  width: 100%;
  height: 79px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  left: 0;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
}

.top7 {
  height: 916px;
  background: url("/public/img/index/bg3.png") no-repeat center center;
  background-size: cover;
  padding: 80px 0 0 0;
  box-sizing: border-box;
}

.messageCard::-webkit-scrollbar {
  width: 760px;
}

.messageCard::-webkit-scrollbar-thumb {
  width: 200px;
  border-radius: 10px;
  background: rgba(96, 18, 10, 1);
}

.messageCard::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(96, 18, 10, 0.1);
}

/deep/.swiper-button-prev {
  /* left: -10px !important; */
}

/deep/.swiper-button-next {
  /* right: -10px !important; */
}

img {
  pointer-events: auto !important;
}
.changBtn {
  border: 1px solid;
  height: 48px;
  border-radius: 38px;
}

.actchangBtn {
  border: 2px solid;
  font-weight: bold;
}

.dots {
  width: 3px;
  height: 3px;
  background: #333;
  border-radius: 50%;
  margin: 0 10px;
}

.chatBtn:nth-of-type(1) {
  border: 2px solid #E82511;
  color: #E82511;
}

.chatBtn {
  font-weight: bold;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  padding: 0 16px;
  margin-left: 24px;
  cursor: pointer;
}

.product_hover{
  transition: all ease-in-out .3s;
}
.product_hover:hover{
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
}
.shop_left_btn{
  left: 0;
  top: 0;
  bottom: 0;
  width: 175px;
  background: linear-gradient( 90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
}
.shop_right_btn{
  right: 0;
  top: 0;
  bottom: 0;
  width: 175px;
  background: linear-gradient( 270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
}
.send_input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F9F9F9;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.search_window{
  left: 0;
  right: 0;
  top: 100%;
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  background-color: #fff;
  border-radius: 24px;
  box-sizing: border-box;
}
.search_hover{
  transition: all ease-in-out .2s;
  background-color: #fff;
}
.search_hover:hover{
  background-color: #F5F5F5;
}
.el-select-dropdown__item.selected{
  color: #e82511 !important;
}
</style>