//lang_zh.js

module.exports = {
	message: {
		"已刷新": "已刷新",
		tabList: [
			// 自营专区
			{
				name: "自营专区",
				path: "/MerchantDetails",
			},
			{
				// 产品中心
				name: "产品中心",
				path: "/selfRun",
			},
			{
				// 询单中心
				name: "询单中心",
				path: "/InquiryCenter",
			},
			{
				// 招标大厅
				name: "招标大厅",
				path: "/biddingHall",
			},
			{
				// 品牌商家
				name: "品牌商家",
				path: "/brandMerchant",
			},
			{
				// 新闻资讯
				name: "新闻资讯",
				path: "/news",
			},
			{
				// 海关资讯
				name: "海关资讯",
				path: "/customs",
			},
			{
				// 行业文档
				name: "行业文档",
				path: "/industry",
			},
			{
				// 行业会展
				name: "行业会展",
				path: "/exhibitions",
			},
		],
		searchList: [{
			label: "产品",
			value: "1"
		}, {
			label: "商家",
			value: "2"
		}, {
			label: "行业信息",
			value: "3"
		}],
		tabList2: [{
				name: "源头厂家",
				sleimg: require("@img/index/tabsel1.png"),
				unimg: require("@img/index/tab1.png"),
			},
			{
				name: "优质推荐",
				sleimg: require("@img/index/tabsel2.png"),
				unimg: require("@img/index/tab2.png"),
			},
			{
				name: "品牌商家",
				sleimg: require("@img/index/tabsel3.png"),
				unimg: require("@img/index/tab3.png"),
			},
		],
		tabList3: [{
				name: "最新",
				value: 1,
			},
			{
				name: "浏览量",
				value: 2,
			},
			{
				name: "收藏量",
				value: 3,
			},
		],
		tabList4: [{
			name: "今日求购",
		}, {
			name: "最新报价",
		}],
		tabList5: [{
			name: "全部",
			value: "",
		}, {
			name: "待开标",
			value: 1,
		}, {
			name: "已开标",
			value: 2,
		}, {
			name: "已结束",
			value: 3,
		}],
		nav_list: [{
				name: '个人中心',
				icon: '/img/center/individual.png',
				selectIcon: '/img/center/individualSelect.png',
				path: "/personal_center",
				expand: true,
				children: [{
						name: "个人信息",
						path: "/personal_center/personal"
					},
					{
						name: "账号信息",
						path: "/personal_center/account"
					},
					{
						name: "我的收藏",
						path: "/personal_center/collection"
					}
				]
			},
			{
				name: '客服中心',
				icon: '/img/center/customer.png',
				selectIcon: '/img/center/customerSelect.png',
				path: "/personal_center/contact",
				expand: true,
				children: [{
						name: "联系客服",
						path: "/personal_center/contact"
					},
					{
						name: "意见反馈",
						path: "/personal_center/feedback"
					},
					{
						name: "我的投诉",
						path: "/personal_center/complaint"
					},
					{
						name: "帮助中心",
						path: "/personal_center/help"
					}
				]
			},
			{
				name: '消息中心',
				icon: '/img/center/message.png',
				selectIcon: '/img/center/messageSelect.png',
				path: "/personal_center/notification",
				expand: true,
				children: [{
					name: "消息通知",
					path: "/personal_center/notification"
				}]
			},
			{
				name: '员工中心',
				icon: '/img/center/employee.png',
				selectIcon: '/img/center/employeeSelect.png',
				path: "/personal_center/employee",
				expand: true,
				children: [{
					name: "员工列表",
					path: "/personal_center/employee"
				}]
			},
		],
		type_list: ["采购商", "供应商"],
		type_list1: ["商品", "商家", "新闻资讯", "海关资讯", "相关文档", "展会信息", ],
		type_list2: ["买家指引", "卖家指引"],
		type_list3: ["在线咨询", "公告通知", "系统消息"],
		type_list4: ["全部招标", "待审核", "已通过", "已驳回"],
		type_list44: ["全部投标", "待审核", "已通过", "已驳回"],
		type_list5: ["我的全部采购", "我的采购订单"],
		type_list6: ["我的全部报价", "已成交报价"],
		type_list7: ['全部', '未开始', '进行中', '已结束'],

		infoList: [
			[{
					name: "发布采购需求",
					desc: "发布采购需求，供应商主动报价",
					btn: "发布采购",
					msg: 0,
					path: "/personal_center/ReleasePurchase"
				},
				{
					name: "采购管理",
					desc: "进行采购管理，快速找到合适报价",
					btn: "采购管理",
					msg: 0,
					path: "/personal_center/purchasing"
				},
				{
					name: "招标管理",
					desc: "进行招标管理，快速找到合作方",
					btn: "招标管理",
					msg: 0,
					path: "/personal_center/bidding?type=0" 
				},
				{
					name: "我的投标",
					desc: "投标管理，查看合作意向",
					btn: "投标管理",
					msg: 0,
					path: "/personal_center/bid?type=1" 
				},
				{
					name: "订购登记",
					desc: "查看商品订购记录",
					btn: "我的订购登记",
					msg: 0,
					path: "/personal_center/order"
				},
				{
					name: "样本申请",
					desc: "查看商品样本申请",
					btn: "我的样本申请",
					msg: 0,
					path: "/personal_center/sample"
				},
			],
			[{
					name: "报价管理",
					desc: "进行报价管理，查看最新报价动态",
					btn: "报价管理",
					msg: 0,
					path: "/personal_center/quotation_mana"
				},
				{
					name: "处理订购登记",
					desc: "查看本商铺的高意向采购商",
					btn: "处理订购登记",
					msg: 0,
					path: "/personal_center/handle"
				},
				{
					name: "处理样本申请",
					desc: "查看本商铺的高意向采购商",
					btn: "处理样本申请",
					msg: 0,
					path: "/personal_center/sampleList"
				},
				{
					name: "我的投标",
					desc: "投标管理，查看合作意向",
					btn: "投标管理",
					msg: 0,
					path: "/personal_center/bid?type=1" 
				},
				{
					name: "订购登记",
					desc: "查看商品订购记录",
					btn: "我的订购登记",
					msg: 0,
					path: "/personal_center/order"
				},
				{
					name: "样本申请",
					desc: "查看商品样本申请",
					btn: "我的样本申请",
					msg: 0,
					path: "/personal_center/sample"
				},
			],
			[{
				name: "发布采购需求",
				desc: "发布采购需求，供应商主动报价",
				btn: "发布采购",
				msg: 0,
				path: "/personal_center/ReleasePurchase"
			},
			{
				name: "采购管理",
				desc: "进行采购管理，快速找到合适报价",
				btn: "采购管理",
				msg: 0,
				path: "/personal_center/purchasing"
			},
			{
				name: "招标管理",
				desc: "进行招标管理，快速找到合作方",
				btn: "招标管理",
				msg: 0,
				path: "/personal_center/bidding?type=0" 
			},
			{
				name: "订购登记",
				desc: "查看商品订购记录",
				btn: "我的订购登记",
				msg: 0,
				path: "/personal_center/order"
			},
			{
				name: "样本申请",
				desc: "查看商品样本申请",
				btn: "我的样本申请",
				msg: 0,
				path: "/personal_center/sample"
			},
			],
			[{
				name: "报价管理",
				desc: "进行报价管理，查看最新报价动态",
				btn: "报价管理",
				msg: 0,
				path: "/personal_center/quotation_mana"
			},
			{
				name: "处理订购登记",
				desc: "查看本商铺的高意向采购商",
				btn: "处理订购登记",
				msg: 0,
				path: "/personal_center/handle"
			},
			{
				name: "处理样本申请",
				desc: "查看本商铺的高意向采购商",
				btn: "处理样本申请",
				msg: 0,
				path: "/personal_center/sampleList"
			},
			{
				name: "我的投标",
				desc: "投标管理，查看合作意向",
				btn: "投标管理",
				msg: 0,
				path: "/personal_center/bid?type=1" 
			},
			]
		],


		name1: "搜索",
		name2: "全部分类",
		name3: "更多",
		name4: "采购商企业认证",
		name5: "供应商企业认证",
		name6: "发布最新食品产业采购需求，获取行业一手价格信息",
		name7: "为各类食品产业采购需求报价，快速找到合作客户",
		name8: "报价动态",
		name9: "今日求购",
		name10: "产品名称",
		name11: "最新报价",
		name12: "时间",
		name13: "数量",
		name14: "轻松采购",
		name15: "发布采购需求，获取最新报价，综合对比供应商，达成最优合作",
		name16: "询单中心",
		name17: "想要获取最新报价？",
		name18: "立即发布采购需求！",
		name19: "立即获取报价",
		name20: "营养健康食品产业前沿动态",
		name21: "官方动态",
		name22: "全球食品产业行业会展",
		name23: "中国会展",
		name24: "国际专业权威行业文档",
		name25: "法律法规",
		name26: "食品行业法律规范",
		name27: "行业标准",
		name28: "提供国际食品安全标准",
		name29: "行业技术",
		name30: "专业实验技术操作文献",
		name31: "最新国际海关资讯",
		name32: "投标",
		name33: "登录",
		name34: "设置语言和货币",
		name35: "项目招标",
		name36: "行业动态",
		name37: "国际会展",
		name38: "个性化购物体验",
		name39: "常搜内容",
		name40: "发布采购信息",
		name41: "立即发起报价",
		name42: "操作",
		name43: "报价",
		name44: "前",
		name45: "查看",
		name46: "请选择您的首选语言和货币且随时可以更新设置",
		name47: "语言",
		name48: "货币",
		name49: "暂无数据",
		name50: "为你推荐",
		name51: "最近搜索",
		name52: "刷新",
		name53: "展示更多",


		footName1: "我的管理",
		footName2: "新闻资讯",
		footName3: "行业会展",
		footName4: "行业文档",
		footName5: "海关资讯",
		footName6: "采购",
		footName7: "发布采购",
		footName8: "采购管理",
		footName9: "招标管理",
		footName10: "我的投标",
		footName11: "供应",
		footName12: "报价管理",
		footName13: "处理订购",
		footName14: "处理样本申请",
		footName15: "关于我们",
		footName16: "买家指引",
		footName17: "卖家指引",
		footName18: "意见反馈",
		footName19: "营养素制造网 服务电话： +86-0371-63316437",
		footName20: "Copyright© nutrient-top.com Corporation All Rights Reserved",
		footName21: "邮箱",
		footName22: "电话",
		footName23: "移动端",
		footName24: "豫ICP备05001944号-4增值电信业务经营许可证:豫B2-20230509",
		indexName1: "请选择您的身份",
		indexName2: "企业管理员",
		indexName3: "企业员工",
		indexName4: "去验证",
		indexName44: "审核中",
		indexName5: "企业信息",
		indexName6: "企业名称",
		indexName7: "企业类型",
		indexName8: "所在国家",
		indexName9: "所在城市",
		indexName10: "详细地址",
		indexName11: "主营商品",
		"可多选": "可多选",
		indexName12: "公司注册证书",
		indexName13: "也可上传营业执照",
		indexName14: "企业委托书",
		indexName15: "请填写'企业委托书'并加盖公章后拍照或扫描上传",
		indexName16: " 下载委托书模板",
		indexName17: "存续证明",
		indexName18: "用于证明您企业的真实状态",
		indexName19: "联系信息",
		indexName20: "名字",
		indexName21: "姓氏",
		indexName22: "电话",
		indexName23: "邮箱",
		indexName24: "立即认证",
		indexName25: "企业编码",

		purchase1: "发布采购信息",
		purchase2: "标题",
		purchase3: "类型",
		purchase4: "商品",
		purchase5: "数量",
		purchase6: "单位",
		purchase7: "预算",
		purchase8: "期望收货日期",
		purchase9: "收货地址",
		purchase10: "说明",
		purchase11: "商品图片",
		purchase12: "采购",
		purchase13: "只有提供报价的供应商可以查看，请您放心填写",
		purchase14: "一键发布",
		purchase15: "发起报价",
		purchase16: "重置",
		purchase17: "查找采购需求",
		purchase18: "取消",
		purchase19: "确定报价",
		purchase20: "具体价格",
		purchase21: "报价说明",
		purchase22: "查找最新报价",

		bidding1: "发布招标项目",
		bidding2: "去招标",
		bidding3: "查询招标信息",
		bidding4: "开始时间",
		bidding5: "项目信息",
		bidding6: "项目名称",
		bidding7: "投标时间",
		bidding77: "招标时间",
		bidding8: "需求描述",
		bidding9: "招标文件",
		bidding10: "上传封面图",
		bidding11: "上传详情图",
		bidding12: "提交",
		bidding13: "招标详情",
		bidding14: "公司详情",
		bidding15: "负责人",
		bidding16: "企业地址",
		bidding17: "主营产品",
		bidding18: "投标",
		bidding19: "招标结束",
		bidding20: "招标未开始",
		bidding21: "详情图片",
		bidding22: "联系电话",
		bidding23: "联系邮箱",
		bidding24: "投标文件",
		bidding25: "投标",
		bidding26: "审核状态",
		bidding27: "目前未审核",
		bidding28: "审核时间",
		"恭喜中标": "恭喜中标",
		"暂未中标": "暂未中标",
		"投标结果": "投标结果",
		"咨询消息不能为空":"咨询消息不能为空",
		"查看更多":"查看更多",
		"暂无名称":"暂无名称",
		

		shop1: "立即联系",
		shop2: "进入店铺",
		shop3: "业务",
		shop4: "年",
		shop5: "收藏",
		shop6: "主营产品",
		shop7: "企业介绍",
		shop8: "合作对象",
		shop9: "营业执照",
		shop10: "店铺资质",
		shop11: "合作时间",
		shop12: "采购总量",
		shop13: "成交金额",
		shop14: "成交商品",
		shop15: "合作详情",
		shop16: "件",
		shop17: "联系供应商",
		shop18: "投诉商家",
		shop19: "商家信息",
		shop20: "商家名称",
		shop21: "申请信息",
		shop22: "投诉类型",
		shop23: "投诉原因",
		shop24: "照片凭证",
		shop25: "请您如实描述投诉信息，便于工作人员了解实际情况并及时处理",
		shop26: "库存",
		shop27: "发货地",
		shop28: "规格",
		shop29: "样本申请",
		shop30: "订购登记",
		shop31: "商品属性",
		shop32: "商品详情",
		shop33: "推荐商品",
		shop34: "电话咨询",
		shop35: "联系人",
		shop36: "我知道了",
		shop37: "商品名称",
		shop38: "商品价格",
		shop39: "联系地址",
		shop40: "订购详情",
		shop41: "备注",
		shop42: "请您填写此表，我们会安排专业的服务人员与您联系",
		shop43: "申请描述",
		shop44: "表",

		news1: "收藏",
		news2: "下载",
		news3: "相关推荐",
		news4: "咨询活动",
		news44: "活动结束",
		news5: "请拨打咨询电话，了解活动详情，参与报名活动",

		login1: "验证",
		login2: "手机",
		login3: "号验证",
		login4: "设置",
		login5: "密码",
		login6: "名字",
		login7: "姓氏",
		login8: "验证码",
		login9: "获取验证码",
		login10: "验证码已发送到您的邮箱，10分钟内输入有效，请勿泄漏",
		login11: "手机号验证",
		"设置密码": "设置密码",
		"验证码已发送到您的手机，10分钟内输入有效，请勿泄漏": "验证码已发送到您的手机，10分钟内输入有效，请勿泄漏",
		login13: "上一步",
		login14: "新密码",
		login15: "确认密码",
		login16: "登录即表示您已同意",
		login17: "用户协议和隐私政策",
		login18: "已阅读并同意",
		login19: "用户协议",
		login20: "隐私政策",
		login21: "营养素制造网将严格保护您的个人信息安全",
		login22: "不同意，退出",
		login23: "同意",
		login24: "和",
		login25: "返回",
		login26: "邮箱",
		login27: "密码",
		login28: "登录 nutrient-top.com",
		login29: "忘记密码",
		login30: "还没有账户",
		login31: "立即注册",
		login32: "手机号验证码登录",
		login33: "登录",
		login34: "身份认证",
		login35: "重置密码",

		mine1: "资格认证",
		mine2: "微信账号",
		mine3: "当前身份",
		mine4: "头像",
		mine5: "保存",
		mine6: "修改手机号",
		mine7: "修改密码",
		mine8: "点击修改",
		mine9: "账号信息",
		mine10: "在线客服",
		mine11: "立即咨询",
		mine12: "联系地址",
		mine13: "详情图片",
		mine14: "投标信息",
		mine144: "员工列表",
		mine15: "删除",
		mine16: "报价详情",
		mine17: "订购登记信息",
		mine177: "样本申请信息",
		mine18: "已处理",
		mine19: "查看详情",
		mine199: "已结束",
		mine20: "接受报价",
		mine200: "已接受此报价",
		mine21: "采购信息",
		mine22: "登记时间",
		mine23: "审核状态",
		mine24: "下载所有文件",
		mine25: "审核详情",
		mine26: "审核时间",
		mine27: "删除招标",
		mine277: "删除投标",
		mine28: "重新发布",
		mine288: "重新投标",
		mine29: "拒绝原因",

		"了解更多信息":"了解更多信息",
		"请上传投标文件":"请上传投标文件",
		"备注详细信息":"备注详细信息",
		"请选择": "请选择",
		"请输入": "请输入关键字",
		"商品类型": "商品类型",
		"请选择采购类型": "请选择采购类型",
		"请输入商品名称": "请输入商品名称",
		"请填写采购商品的品种、规格、型号、包装、物流以及采购商所在地区、资质等要求、确保您收到准确的报价": "请填写采购商品的品种、规格、型号、包装、物流以及采购商所在地区、资质等要求、确保您收到准确的报价",
		"请输入采购标题": "请输入采购标题",
		"请输入采购数量": "请输入采购数量",
		"请输入采购单位": "请输入采购单位",
		"请输入采购预算": "请输入采购预算",
		"请选择日期": "请选择日期",
		"请选择地址": "请选择地址",
		"请选择国家": "请选择国家",
		"请输入详细地址": "请输入详细地址",
		"请输入您的名字": "请输入您的名字",
		"请输入姓氏": "请输入姓氏",
		"请输入联系方式": "请输入联系方式",
		"请输入邮箱": "请输入邮箱",
		"只有提供报价的供应商可以查看，请您放心填写": "只有提供报价的供应商可以查看，请您放心填写",
		"首页": "首页",
		"发布成功": "发布成功",
		"发布成功，请及时关注消息获取最新报价！": "发布成功，请及时关注消息获取最新报价！",
		"继续采购": "继续采购",
		"确定放弃最新一条投标？":"确定放弃最新一条投标？",
		"确定重新发起投标吗？":"确定重新发起投标吗？",
		"操作成功":"操作成功",
		"您确定要删除此样本申请吗？":"您确定要删除此样本申请吗？",


		// root
		"资质认证": "资质认证",
		"管理员认证": "管理员认证",
		"员工认证": "员工认证",
		"品牌商家": "品牌商家",
		"搜索结果": "搜索结果",
		"商品分类": "商品分类",
		"发布采购": "发布采购",
		"我的投诉": "我的投诉",
		"商家详情": "商家详情",
		"全部商品": "全部商品",
		"商品详情": "商品详情",
		"轮播详情": "轮播详情",
		"产品中心": "产品中心",
		"询单中心": "询单中心",
		"采购详情": "采购详情",
		"报价": "报价",
		"招标大厅": "招标大厅",
		"发布招标": "发布招标",
		"详情": "详情",
		"新闻资讯": "新闻资讯",
		"新闻详情": "新闻详情",
		"海关资讯": "海关资讯",
		"海关详情": "海关详情",
		"行业文档": "行业文档",
		"行业详情": "行业详情",
		"行业会展": "行业会展",
		"会展详情": "会展详情",
		"个人中心": "个人中心",
		"采购管理": "采购管理",
		"招标管理": "招标管理",
		"投标管理": "投标管理",
		"订购登记": "订购登记",
		"样本申请": "样本申请",
		"个人信息": "个人信息",
		"账号信息": "账号信息",
		"我的收藏": "我的收藏",
		"消息通知": "消息通知",
		"消息通知详情": "消息通知详情",
		"员工列表": "员工列表",
		"联系客服": "联系客服",
		"意见反馈": "意见反馈",
		"我的投诉": "我的投诉",
		"帮助中心": "帮助中心",
		"处理订购登记": "处理订购登记",
		"处理样本申请": "处理样本申请",
		"报价管理": "报价管理",
		"报价详情": "报价详情",
		"招标详情": "招标详情",
		"投标详情": "投标详情",
		"关于我们": "关于我们",
		"不能对自己公司的招标进行投标":"不能对自己公司的招标进行投标",

		"请输入项目名称": "请输入项目名称",
		"开始时间": "开始时间",
		"结束时间": "结束时间",
		"暂无时间": "暂无时间",
		"暂无省份": "暂无省份",
		"未命名": "未命名",
		"请详细描述您的招标需求": "请详细描述您的招标需求",
		"提交成功": "提交成功",
		"知道了": "知道了",
		"提交成功！工作人员审核通过您的招标项目后，发布招标项目了": "提交成功！工作人员审核通过您的招标项目后，发布招标项目了",
		"提交失败": "提交失败",
		"请重新提交您的投标文件":"请重新提交您的投标文件",
		"重新提交": "重新提交",
		"发布失败": "发布失败",
		"企业认证完成后即可发布采购信息": "企业认证完成后即可发布采购信息",
		"回购": "回购",
		"确认": "确认",
		"取消": "取消",
		"请输入企业名称": "请输入企业名称",
		"请选择类型": "请选择类型",
		"请等待处理结果":"请等待处理结果",

		"投诉商家": "投诉商家",
		"投诉时间": "投诉时间",
		"投诉类型": "投诉类型",
		"待处理": "待处理",
		"已处理": "已处理",
		"已拒绝": "已拒绝",
		"投诉详情": "投诉详情",
		"处理结果": "处理结果",
		"处理意见": "处理意见",
		"处理时间": "处理时间",
		"重新提交": "重新提交",
		"暂无权限": "暂无权限",
		"修改成功": "修改成功",
		"申请时间": "申请时间",
		"待审核": "待审核",
		"已审核": "已审核",
		"投标列表": "投标列表",
		"操作": "操作",
		"收起": "收起",
		"审核通过":"审核通过",
		"审核驳回":"审核驳回",
		"暂无地址":"暂无地址",
		"暂无":"暂无",
		"暂无国家信息":"暂无国家信息",
		"请详细描述您投诉的原因，工作人员会立即处理":"请详细描述您投诉的原因，工作人员会立即处理",
		"请选择":"请选择",
		"翻译":"翻译",
		"发送":"发送",


		"企业名称":"企业名称",
		"该文件已丢失":"该文件已丢失",
		"下载文件":"下载文件",
		"合作":"合作",
		"已合作":"已合作",
		"处理成功":"处理成功",
		"转到":"转到",
		"页":"页",
		"最小页码为1":"最小页码为1",
		"页数超过最大页数":"页数超过最大页数",
		"请选择您想要提供反馈的图像":"请选择您想要提供反馈的图像",
		"上传成功":"上传成功",
		"条数":"条",
		"收藏管理":"收藏管理",
		"取消收藏":"取消收藏",
		"全选":"全选",
		"确定取消收藏吗？":"确定取消收藏吗？",
		"查看详情":"查看详情",
		"您确定要删除该订购记录吗？":"您确定要删除该订购记录吗？",
		"当前限制选择 3 个文件，本次选择了":"当前限制选择 3 个文件，本次选择了",
		"个文件，共选择了":"个文件，共选择了",
		"个文件":"个文件",
		"请选择联系人":"请选择联系人",
		"请选择投诉类型":"请选择投诉类型",
		"请输入投诉原因":"请输入投诉原因",
		"请上传源文件":"请上传源文件",
		"请上传复印件":"请上传复印件",
		"请输入要查询的产品名称":"请输入要查询的产品名称",
		"请输入具体价格":"请输入具体价格",
		"请输入单位":"请输入单位",
		"成功与该企业合作！":"成功与该企业合作！",
		"请输入正确的手机号":"请输入正确的手机号",

		"确定": "确定",
		"取消": "取消",
		"确定清空历史记录?": "确定清空历史记录?",
		"您的认证申请正在审核中，请耐心等待": "您的认证申请正在审核中，请耐心等待",
		"企业认证完成后可发起报价": "企业认证完成后可发起报价",
		"立即认证": "立即认证",
		"企业认证完成后可发布采购信息": "企业认证完成后可发布采购信息",
		"买方没有报价权限": "买方没有报价权限",
		"您还没有完成企业认证": "您还没有完成企业认证",
		"感谢您的反馈！":"感谢您的反馈！",
		"请提出你的意见":"请提出你的意见",

		"账号": "账号",
		"密码": "密码",
		"请输入您的邮箱账号": "请输入您的邮箱账号",
		"请输入您的登录密码": "请输入您的登录密码",
		"忘记密码?": "忘记密码?",
		"两次密码输入不一致":"两次密码输入不一致",
		"密码不能为空":"密码不能为空",
		"还没有账号?": "还没有账号?",
		"立即注册": "立即注册",
		"登录代表您已同意": "登录代表您已同意",
		"用户协议": "用户协议",
		"隐私政策": "隐私政策",
		'登录': '登录',
		"验证码登录": "验证码登录",
		"其它方式登录": "其它方式登录",
		"验证码": "验证码",
		"邮箱登录": "邮箱登录",
		"请输入您的手机号": "请输入您的手机号",
		"请输入您的验证码": "请输入您的验证码",
		"获取验证码": "获取验证码",
		"重新获取": "重新获取",
		"X秒重新获取": "X秒重新获取",
		"验证码已发送": "验证码已发送",
		"倒计时结束后再发送": "倒计时结束后再发送",
		"请阅读并勾选用户协议": "请阅读并勾选用户协议",
		"身份验证": "身份验证",
		"设置密码": "设置密码",
		"手机号验证": "手机号验证",
		"邮箱验证": "邮箱验证",
		"验证码已发送到您的邮箱，10分钟内输入有效，请勿泄漏": "验证码已发送到您的邮箱，10分钟内输入有效，请勿泄漏",
		"验证码已发送到您的手机，10分钟内输入有效，请勿泄漏": "验证码已发送到您的手机，10分钟内输入有效，请勿泄漏",
		"重置密码": "重置密码",
		"新密码": "新密码",
		"确认密码": "确认密码",
		"请设置您的新密码": "请设置您的新密码",
		"请设置您的密码": "请设置您的密码",
		"请确认您的密码": "请确认您的密码",
		"完成": "完成",
		"密码设置成功!请重新登陆": "密码设置成功!请重新登陆",
		"两次密码不一致": "两次密码不一致",
		"验证码不能为空":"验证码不能为空",
		"邮箱": "邮箱",
		"注册成功": "注册成功",
		"登陆成功跳转首页": "登陆成功跳转首页",
		"三方账号成功绑定该邮箱":"三方账号成功绑定该邮箱",
		"确定保存信息?": "确定保存信息?",
		"保存成功": "保存成功",
		"确定删除?": "确定删除?",
		"删除招标成功":"删除招标成功",
		"未查找到对应企业":"未查找到对应企业",
		"请选择采购单位":"请选择采购单位",

		"员工信息": "员工信息",
		"暂无报价": "暂无报价",
		"提交成功，工作人员将立即处理": "提交成功，工作人员将立即处理",
		"继续报价":"继续报价",
		"取消报价":"取消报价",
		"全部":"全部",
		"确定接受这个报价吗?":"确定接受这个报价吗?",
		"您可以在我的购买订单中查看":"您可以在我的购买订单中查看",
		"新投标":"新投标",
		"有":"有",
		"个新投标":"个新投标",
		
		"咨询产品":"咨询产品",
		"订购登记表":"订购登记表",
		"请输入备注详情":"请输入备注详情",
		"退出":"退出",
		"确定退出登录?":"确定退出登录?",
		
		"请输入联系人姓名":"请输入联系人姓名",
		"请输入联系邮箱":"请输入联系邮箱",
		"请输入联系地址":"请输入联系地址",
		"请输入申请描述":"请输入申请描述",
		"请输入备注信息":"请输入备注信息",
		"请输入订购详情":"请输入订购详情",
		"采购信息":"采购信息",
		"报价成功":"报价成功",
		"会展已结束":"会展已结束",
		"密码设置成功!":"密码设置成功",
		"请重新登陆":"请重新登陆",
		"确定删除报价信息?":"确定删除报价信息?",
		"有关标书将于招标开始后收到":"有关标书将于招标开始后收到",
		"确定删除该招标?":"确定删除该招标?",
		"确定与该企业合作?":"确定与该企业合作?",
		"请输入关键词":"请输入关键词",
		"请输入具体价格":"请输入具体价格",
		"请输入商品单位":"请输入商品单位",
		"请输入报价说明":"请输入报价说明",
		"请输入您的新密码":"请输入您的新密码",
		"请再次输入新密码":"请再次输入新密码",
		"请输入您的新手机号":"请输入您的新手机号",
		"确定删除该采购信息?":"确定删除该采购信息?",
		"删除成功":"删除成功",
		"请输入企业代码":"请输入企业代码",
		"请输入业务代码":"请输入业务代码",
		"请输入正确手机号":"请输入正确手机号",
		"请选择手机号前缀":"请选择手机号前缀",
		"请输入名称":"请输入名称",
		"请输入姓氏":"请输入姓氏",
		"请输入正确的邮箱":"请输入正确的邮箱",
		"请输入地址":"请输入地址",
		"请选择主营产品":"请选择主营产品",
		"新报价":" 新报价",
		"请输入正确邮箱":"请输入正确邮箱",
		"编号":"编号",
		"员工名称":"员工名称",
		"接受该报价成功":"接受该报价成功",

		"后重新发送":"后重新发送",
		"网站许可证":"网站注册/许可:悦ICP备16022018-1版权所有@ 2020-2022君和数字创意",
		"转载版权联系":"关于转载或引用本网站文章的版权问题，请与我们联系。电话:020-34224268传真:020-34113782",

		"您没有发布采购的权限":"您没有发布采购的权限",
		"您没有投标的权限":"您没有投标的权限",
		"您没有发布招标的权限":"您没有发布招标的权限",
		"已经到底啦":"已经到底啦",
		"结束会话":"结束会话",
		"您的账号暂未绑定邮箱，请完成一次登录以绑定邮箱":"您的账号暂未绑定邮箱，请完成一次登录以绑定邮箱",
		"三方登录提示":"三方登录提示",
		"您的三方账号暂未绑定对应用户，请选择登录/注册对应用户进行绑定":"您的三方账号暂未绑定对应用户，请选择登录/注册对应用户进行绑定",
		"注册":"注册",
		"请输入您的意见和建议":"请输入您的意见和建议",
		"无法对自己的商铺进行该操作":"无法对自己的商铺进行该操作",
		"输入密码不能为空":"输入密码不能为空",
		"输入的密码长度应在4-16位":"输入的密码长度应在4-16位",
		"两次输入密码不一致":"两次输入密码不一致",
		"该账号已经绑定了此平台的三方账号，确定继续登录以替换绑定吗？":"该账号已经绑定了此平台的三方账号，确定继续登录以替换绑定吗？",
		"请选择城市":"请选择城市",
		"不是jpeg,png,jpg格式,请上传正确的图片类型":"不是jpeg,png,jpg格式,请上传正确的图片类型"
	}
}