import Vue from 'vue';
import router from "./../router";
import Toast from "./../components/toast/index.js"
import {
	Alert,
	MessageBox
} from 'element-ui';
import VueI18n from 'vue-i18n' //引入vue-i18n
Vue.use(VueI18n);
//合并对象的方法
function concatkeyvalue(map) {//map = [{..},{..}]
	let newmap = {}
	map.forEach(items => {
		for (var key in items) {
			newmap[key] = items[key]
		}
	})
	return newmap
}
//将各个文件的中英文翻译全部合并
let zh = concatkeyvalue([require('../assets/language/lang_zh.js')])
let en = concatkeyvalue([require('../assets/language/lang_en.js')])
let locale = '';
if (localStorage.getItem("language") == '简体中文') {
	locale = 'zh'
} else {
	locale = 'en'
}
const i18n = new VueI18n({
	locale: locale,  // 语言标识
	messages: {
		'zh': zh,
		'en': en
	}
})
export default {
	// baseURL: 'http://127.0.0.1:48081',//本地
	// baseURL: 'http://192.168.0.111:48081',//测试
	baseURL: 'https://admin.nutrient-top.com/nutrient',//线上
	auth: (showAllType, path) => {
		if (!localStorage.getItem("userId")) {
			router.push({
				path: "/login"
			});
		} else if (showAllType == 1) {
			MessageBox.alert(i18n.t('message.您还没有完成企业认证'), "", {
				confirmButtonText: i18n.t('message.立即认证'),
				showClose: false,
				showCancelButton: true,
				cancelButtonText: i18n.t('message.取消'),
				confirmButtonClass: i18n.t("message.确认"),
				cancelButtonClass: i18n.t("message.取消"),
				callback: (action) => {
					if (action == "confirm") {
						router.push({
							path: "/authentication",
						});
					}
				},
			});
		} else if (showAllType == 2) {
			Toast(i18n.t('message.您的认证申请正在审核中，请耐心等待'))
			return false;
		} else {
			router.push({
				path: path,
			});
		}
	},
}
