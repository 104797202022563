<template>
  <img
    class="image-preview"
    :style="{
      transform: `scale(${props.zoom}) rotate(${props.rotate}deg)`,
    }"
    :src="props.src"
  />
</template>
<script lang="ts">
const __sfc_main = {};
__sfc_main.props = {
  zoom: {
    type: Number,
    default: 1
  },
  rotate: {
    type: Number,
    default: 0
  },
  src: {
    type: String,
    default: ""
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    props
  };
};
export default __sfc_main;
</script>
<style lang="scss">
.image-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.1s ease 0s;
  pointer-events: auto;
}
</style>
