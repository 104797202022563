<template>
  <div>
    <transition name="fade">
      <div class="inercont">
        <div
          class="flex align-center justify-between fs-14 padding-top-10"
          v-show="showTop2"
        >
          <img :src="bLogo" alt="logo" width="170px" height="42px" />
          <div class="flex align-center">
            <el-dropdown class="margin-right-40 pointer" @command="toPath">
              <span class="el-dropdown-link fw-bold">
                {{ $t("message.footName15") }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/personal_center/help?index=0">{{
                  $t("message.footName16")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/help?index=1">{{
                  $t("message.footName17")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/feedback">{{
                  $t("message.footName18")
                }}</el-dropdown-item>
                <el-dropdown-item command="/about">{{
                  $t("message.footName15")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown class="margin-right-40 pointer" @command="toPath">
              <span class="el-dropdown-link fw-bold">
                {{ $t("message.footName1") }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>{{
                  $t("message.footName6")
                }}</el-dropdown-item>
                <el-dropdown-item command="/ReleasePurchase">{{
                  $t("message.footName7")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/purchasing">{{
                  $t("message.footName8")
                }}</el-dropdown-item>
                <el-dropdown-item command="/biddingHall">{{
                  $t("message.footName9")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/bidding?type=1">{{
                  $t("message.footName10")
                }}</el-dropdown-item>
                <el-dropdown-item disabled>{{
                  $t("message.footName11")
                }}</el-dropdown-item>
                <el-dropdown-item command="/quotation_mana">{{
                  $t("message.footName12")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/handle">{{
                  $t("message.footName13")
                }}</el-dropdown-item>
                <el-dropdown-item command="/personal_center/sample">{{
                  $t("message.footName14")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <span class="margin-right-40 pointer">My management</span> -->
            <div
              class="margin-right-40 pointer relative"
              @click="toPath('/personal_center/notification?selectList=2')"
            >
              <img
                src="@img/auth/auth2.png"
                alt="消息"
                width="16px"
                height="18px"
              />
              <span
                class="absolute bgBtn"
                v-show="userInfo.unReadNum"
                style="
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  top: 0;
                  right: 0;
                  z-index: 10;
                "
              ></span>
            </div>
            <el-dropdown class="margin-right-40 pointer">
              <span class="el-dropdown-link flex align-center">
                <img
                  src="@img/auth/auth3.png"
                  alt="语言"
                  width="16px"
                  height="16px"
                />
                <span class="margin-left-6 fw-bold">{{
                  language
                }}</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item disabled>
                  <p class="fs-16 color-333">{{ $t("message.name34") }}</p>
                  <p class="fs-12" style="line-height: 14px">
                    {{ $t("message.name46") }}
                  </p>
                </el-dropdown-item>
                <el-dropdown-item disabled>
                  <span class="fs-16 color-333">{{
                    $t("message.name47")
                  }}</span>
                </el-dropdown-item>
                <el-dropdown-item style="background: #fff">
                  <el-select
                    v-model="language"
                    @change="changeLanguage"
                    :placeholder="$t('message.请选择')"
                  >
                    <el-option
                      v-for="(item, index) in countryList"
                      :key="index"
                      :label="item.language"
                      :value="item.language"
                    >
                    </el-option>
                  </el-select>
                </el-dropdown-item>
                <el-dropdown-item disabled>
                  <span class="fs-16 color-333">{{
                    $t("message.name48")
                  }}</span>
                </el-dropdown-item>
                <el-dropdown-item style="background: #fff">
                  <el-select
                    v-model="symbol"
                    disabled
                    :placeholder="$t('message.请选择')"
                  >
                  </el-select>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div
              class="flex align-center pointer margin-right-40"
              v-if="userInfo.id"
              @click="$router.push({ path: '/personal_center' })"
            >
              <img
                :src="
                  userInfo.avatar ? userInfo.avatar : 'img/index/index16.png'
                "
                alt="头像"
                width="36px"
                height="36px"
                style="border-radius:50%"
              />
              <span class="margin-left-10">{{ userInfo.nickname }}</span>
            </div>
            <div
              class="loginbtn bgBtn flex align-center justify-center color-fff"
              v-else
              @click="$router.push({ path: '/login' })"
            >
              {{ $t("message.name33") }}
            </div>
                      <div
            class="flex align-center pointer"
            v-if="userInfo.id"
            @click="outlogin"
          >
            <img
              src="@img/auth/outlogin.png"
              alt=""
              width="18px"
              height="18px"
            />
            <span class="margin-left-10 fw-bold">{{ $t("message.退出") }}</span>
          </div>
          </div>
        </div>
        <div
          class="flex align-center justify-between padding-top-16"
          v-show="showTop"
        >
          <div class="flex align-center pointer" slot="reference">
            <img
              src="@img/auth/auth6.png"
              alt="菜单"
              width="24px"
              height="24px"
            />
            <span class="margin-left-20">{{ $t("message.name2") }}</span>
          </div>
          <div class="flex align-center margin-left-20">
            <span
              v-for="(item, index) in $t('message.tabList')"
              :key="index"
              @click="$router.push({ path: item.path })"
              class="pointer margin-left-16"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <!-- 分类 -->
        <div
          class="flex align-start padding-top-24 padding-bottom-60 fs-16 bg-fff border-box"
          style="height: 550px;"
        >
          <!-- 一级分类 -->
          <div class="sort u-line" style="flex: 4;max-height: 450px !important;">
            <p
              v-for="(item, index) in treeList"
              @click="toGoods(0,item)"
              @mouseenter="setTree(1, item)"
              :class="item.id == querys.id ? 'fs-16 fw-bold bg-eee' : ''"
              class="padding-y-10 padding-x-24 pointer changeColor"
              :key="index"
            >
              {{ item.name }}
            </p>
          </div>
          <!-- 二级分类 -->
          <div class="sort u-line" style="flex: 3;max-height: 450px !important;">
            <p
              v-for="(item, index) in treeList1"
              @click="toGoods(1,item)"
              @mouseenter="setTree(2, item)"
              :class="item.id == querys.id1 ? 'fs-16 fw-bold bg-eee' : ''"
              class="padding-y-10 padding-x-24 pointer changeColor"
              :key="index"
            >
              {{ item.name }}
            </p>
          </div>
          <!-- 三级分类 -->
          <div class="sort padding-x-24  noscrollbar" style="flex: 6;max-height: 420px !important;background-color: #fff">
            <p class="flex align-center padding-y-10">
              <span class="fw-bold">{{ querys.parentName1 }}</span>
              <!-- <i class="el-icon-arrow-right"></i> -->
            </p>
            <div class="flex flex-wrap">
              <div
                v-for="(item, index) in treeList2"
                :key="index"
                @click="toGoods(2,item)"
                v-show="index < 9 || showTwo"
                class="boxss margin-top-24 padding-x-20 pointer changeColor"
              >
                <p class="flex align-center justify-center">
                  <img :src="item.url" width="64px" height="64px" />
                </p>
                <p style="width: 64px" class="text-center margin-top-10">
                  {{ item.name }}
                </p>
              </div>
              <div
                class="margin-top-24 padding-x-20 flex align-center"
                v-show="treeList2.length"
                @click="showTwo = !showTwo"
              >
                <p class="more">{{ showTwo?$t("message.收起"):$t("message.name3") }}</p>
                <i :class="showTwo?'el-icon-arrow-left':'el-icon-arrow-right'"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showTop2: {
      type: Boolean,
      default: false,
    },
    showTop: {
      type: Boolean,
      default: true,
    },
    shopId: {
      type: String,
      default: "",
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    countryList: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: "",
    },
    symbol: {
      type: String,
      default: "",
    },
    showMenu:{
      type: Boolean,
      default:false
    }
  },
  created() {
    this.init();
  },
  data() {
    return {
      showTwo: false,
      treeList: [],
      treeList1: [],
      treeList2: [],
      querys: {
        parentName: "",
        parentName1: "",
        parentName2: "",
        id: "",
        id1: "",
        id2: "",
      },
      bLogo: "/img/auth/auth1.png",
    };
  },
  methods: {
        // 退出登录
    outlogin() {
      this.$alert(this.$t('message.确定退出登录?'), "", {
        confirmButtonText: this.$t("message.确定"),
        showClose: false,
        showCancelButton: true,
        cancelButtonText: this.$t("message.取消"),
        confirmButtonClass: this.$t("message.确认"),
        cancelButtonClass: this.$t("message.取消"),
        callback: (action) => {
          if (action == "confirm") {
            localStorage.removeItem('userId');
            localStorage.removeItem('accessToken');
            this.$router.replace('/login');
          }
        },
      });
    },
    changeLanguage(value) {
      this.$emit("changeLanguage", value);
    },
    // 还是在页面查询
    toGoods(num,item) {
      // console.log(this.showTop);
      console.log(item)
      if(num==0){
        this.querys.parentName = item.name;
        this.querys.id = item.id;
        this.querys.parentName1 = '';
        this.querys.id1 = '';
        this.querys.parentName2 = '';
        this.querys.id2 = '';
      } else if(num==1){
        this.querys.parentName1 = item.name;
        this.querys.id1 = item.id;
        this.querys.parentName2 = '';
        this.querys.id2 = '';
      } else if(num==2){
        this.querys.parentName2 = item.name;
        this.querys.id2 = item.id;
      }
      // 跳转分类页面
      if (this.showTop) {
        this.$router.push({
          path: "/goodsType",
          query: this.querys,
        });
      } else {
        this.$emit("getid", item.id);
        this.$emit("changeShow", false);
      }
    },
    // 设置树形结构
    setTree(type, item) {
      if (type == 1) {
        this.treeList1 = item.children;
        this.querys.parentName = item.name;
        this.querys.id = item.id;
      } else if(type==2){
        this.treeList2 = item.children;
        this.querys.parentName1 = item.name;
        this.querys.id1 = item.id;
      }
    },
    // 跳转页面
    toPath(path) {
      this.$router.push({
        path: path,
      });
    },
    init() {
      // 配置文件
      this.$http({
        url: "/admin-api/biz/logo/get-type",
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          let datas = res.data.data;
          datas.forEach((el) => {
            if (el.type == 2) {
              this.bLogo = el.logoUrl;
            }
          });
        }
      });
      let urls = "",
        params = {};
      if (this.shopId) {
        //  店铺所有产品类型树形结构
        urls = "/app-api/biz/open/shop-type-tree";
        params.shopId = this.shopId;
      } else {
        // 所有产品类型树形结构
        // 1产品类型 2企业类型 3行业资讯类型 4行业标准类型 5行业技术类型6文章类型7投诉类型8海关类型9行业会展
        urls = "/app-api/biz/open/type-tree";
        params.type = 1;
      }
      this.$http({
        url: urls,
        method: "get",
        params: params,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.treeList = res.data.data;
          this.treeList1=this.treeList[0].children;
          this.querys.parentName = this.treeList[0].name;
          this.querys.id = this.treeList[0].id;
          this.treeList2=this.treeList1[0].children;
          this.querys.parentName1 = this.treeList1[0].name;
          this.querys.id1 = this.treeList1[0].id;
          this.$emit("getTree", this.treeList);
        }
      });
    },
  },
};
</script>

<style scoped>
.noscrollbar::-webkit-scrollbar {
    display: none;
  }
.loginbtn {
  width: 110px;
  height: 36px;
  margin-left: 18px;
  border-radius: 57px 57px 57px 57px;
  cursor: pointer;
}

.sort {
  max-height: 320px;
  overflow-y: auto;
}

.boxss {
  margin-right: 10px;
}

.boxss:nth-of-type(5n) {
  margin-right: 0px;
}

.changeColor:hover {
  color: #e82511;
}
</style>