//lang_en.js

module.exports = {
	message: {
		"已刷新": "Already refreshed",
		tabList: [
			// 自营专区
			{
				name: "Self-support",
				path: "/MerchantDetails",
			},
			{
				// 产品中心
				name: "Product Center",
				path: "/selfRun",
			},
			{
				// 询单中心
				name: "Inquiry Center",
				path: "/InquiryCenter",
			},
			{
				// 招标大厅
				name: "Tendering Hall",
				path: "/biddingHall",
			},
			{
				// 品牌商家
				name: "Brand merchants",
				path: "/brandMerchant",
			},
			{
				// 新闻资讯
				name: "News",
				path: "/news",
			},
			{
				// 海关资讯
				name: "Customs information",
				path: "/customs",
			},
			{
				// 行业文档
				name: "Industry information",
				path: "/industry",
			},
			{
				// 行业会展
				name: "Trade show",
				path: "/exhibitions",
			},
		],
		searchList: [{
			label: "Product",
			value: "1"
		}, {
			label: "Merchant",
			value: "2"
		}, {
			label: "Industry",
			value: "3"
		}],
		tabList2: [{
				name: "Source factory",
				sleimg: require("@img/index/tabsel1.png"),
				unimg: require("@img/index/tab1.png"),
			},
			{
				name: "Comprehensive variety",
				sleimg: require("@img/index/tabsel2.png"),
				unimg: require("@img/index/tab2.png"),
			},
			{
				name: "Brand merchants",
				sleimg: require("@img/index/tabsel3.png"),
				unimg: require("@img/index/tab3.png"),
			},
		],
		tabList3: [{
				name: "latest",
				value: 1,
			},
			{
				name: "Page view",
				value: 2,
			},
			{
				name: "Collection quantity",
				value: 3,
			},
		],
		tabList4: [{
			name: "Buy today",
		}, {
			name: "Latest offer",
		}],
		tabList5: [{
			name: "All",
			value: "",
		}, {
			name: "Tender to be opened",
			value: 1,
		}, {
			name: "Bid opened",
			value: 2,
		}, {
			name: "Already ended",
			value: 3,
		}],
		nav_list: [{
				name: 'Individual center',
				icon: '/img/center/individualSelect.png',
				selectIcon: '/img/center/individualSelect.png',
				path: "/personal_center",
				expand: true,
				children: [{
						name: "Personal information",
						path: "/personal_center/personal"
					},
					{
						name: "Account information",
						path: "/personal_center/account"
					},
					{
						name: "My collection",
						path: "/personal_center/collection"
					}
				]
			},
			{
				name: 'Customer service center',
				icon: '/img/center/customer.png',
				selectIcon: '/img/center/customerSelect.png',
				path: "/personal_center/contact",
				expand: true,
				children: [{
						name: "Contact customer service",
						path: "/personal_center/contact"
					},
					{
						name: "feedback",
						path: "/personal_center/feedback"
					},
					{
						name: "My complaint",
						path: "/personal_center/complaint"
					},
					{
						name: "Help center",
						path: "/personal_center/help"
					}
				]
			},
			{
				name: 'Message center',
				icon: '/img/center/message.png',
				selectIcon: '/img/center/messageSelect.png',
				path: "/personal_center/notification",
				expand: true,
				children: [{
					name: "Message notification",
					path: "/personal_center/notification"
				}]
			},
			{
				name: 'Employee center',
				icon: '/img/center/employee.png',
				selectIcon: '/img/center/employeeSelect.png',
				path: "/personal_center/employee",
				expand: true,
				children: [{
					name: "Employee list",
					path: "/personal_center/employee"
				}]
			},
		],
		type_list: ["purchaser", "supplier"],
		type_list1: ["product", "Merchant", "News information", "Customs information", "Related document",
			"Exhibition information"
		],
		type_list2: ["Buyer's guide", "Seller guidelines"],
		type_list3: ["Online consultation", "Public notice", "System message"],
		type_list4: ["Full tender", "To be reviewed", "Already passed", "rejected"],
		type_list44: ["All bids", "To be reviewed", "Already passed", "rejected"],
		type_list5: ["All my purchases", "My Purchase Order"],
		type_list6: ["My full offer", "Closed offer"],
		type_list7: ['whole', 'Not yet started', 'Under way', 'Already ended'],


		infoList: [
			[{
					name: "Publish purchase requirements",
					desc: "Release purchasing requirements, suppliers take the initiative to quote",
					btn: "Release Purchase",
					msg: 0,
					path: "/personal_center/ReleasePurchase"
				},
				{
					name: "Purchasing management",
					desc: "Conduct purchasing management and quickly find the right quotation",
					btn: "Purchasing Management",
					msg: 0,
					path: "/personal_center/purchasing"
				},
				{
					name: "Bidding management",
					desc: "Conduct bidding management and quickly find partners",
					btn: "Bidding Management",
					msg: 0,
					path: "/personal_center/bidding?type=0" // 需要添加状态 用于分辨是我的还是整体
				},
				{
					name: "My bid",
					desc: "Bid management, view cooperation intention",
					btn: "My bid",
					msg: 0,
					path: "/personal_center/bidding?type=1" // 需要添加状态 用于分辨是我的还是整体
				},
				{
					name: "Order registration",
					desc: "View merchandise order history",
					btn: "Register My Order",
					msg: 0,
					path: "/personal_center/order"
				},
				{
					name: "Sample request",
					desc: "View product sample application",
					btn: "My Sample Application",
					msg: 0,
					path: "/personal_center/sample"
				},
			],
			[{
					name: "Quotation Management",
					desc: "Release quotation requirements, suppliers take the initiative to quote",
					btn: "Release Purchase",
					msg: 0,
					path: "/personal_center/quotation_mana"
				},
				{
					name: "Handle management",
					desc: "Conduct handle management and quickly find the right quotation",
					btn: "Handle Management",
					msg: 0,
					path: "/personal_center/handle"
				},
				{
					name: "Sample management",
					desc: "Conduct sample management and quickly find partners",
					btn: "Sample Management",
					msg: 0,
					path: "/personal_center/sampleList"
				},
				{
					name: "My bid",
					desc: "Bid management, view cooperation intention",
					btn: "My Bid",
					msg: 0,
					path: "/personal_center/bidding?type=1" // 需要添加状态 用于分辨是我的还是整体
				},
				{
					name: "Order registration",
					desc: "View merchandise order history",
					btn: "Register My Order",
					msg: 0,
					path: "/personal_center/order"
				},
				{
					name: "Sample request",
					desc: "View product sample application",
					btn: "My Sample Application",
					msg: 0,
					path: "/personal_center/sample"
				},
			],
			[{
				name: "Publish purchase requirements",
				desc: "Release purchasing requirements, suppliers take the initiative to quote",
				btn: "Release Purchase",
				msg: 0,
				path: "/personal_center/ReleasePurchase"
			},
			{
				name: "Purchasing management",
				desc: "Conduct purchasing management and quickly find the right quotation",
				btn: "Purchasing Management",
				msg: 0,
				path: "/personal_center/purchasing"
			},
			{
				name: "Bidding management",
				desc: "Conduct bidding management and quickly find partners",
				btn: "Bidding Management",
				msg: 0,
				path: "/personal_center/bidding?type=0" // 需要添加状态 用于分辨是我的还是整体
			},
			{
				name: "Order registration",
				desc: "View merchandise order history",
				btn: "Register My Order",
				msg: 0,
				path: "/personal_center/order"
			},
			{
				name: "Sample request",
				desc: "View product sample application",
				btn: "My Sample Application",
				msg: 0,
				path: "/personal_center/sample"
			},
			],
			[{
					name: "Quotation Management",
					desc: "Release quotation requirements, suppliers take the initiative to quote",
					btn: "Release Purchase",
					msg: 0,
					path: "/personal_center/quotation_mana"
				},
				{
					name: "Handle management",
					desc: "Conduct handle management and quickly find the right quotation",
					btn: "Handle Management",
					msg: 0,
					path: "/personal_center/handle"
				},
				{
					name: "Sample management",
					desc: "Conduct sample management and quickly find partners",
					btn: "Sample Management",
					msg: 0,
					path: "/personal_center/sampleList"
				},
				{
					name: "My bid",
					desc: "Bid management, view cooperation intention",
					btn: "My Bid",
					msg: 0,
					path: "/personal_center/bidding?type=1" // 需要添加状态 用于分辨是我的还是整体
				},
			]
		],


		name1: "Search",
		name2: "All categories",
		name3: "More",
		name4: "Procurement certification",
		name5: "Supplier Certification",
		name6: "Release the latest food industry procurement requirements and obtain first-hand price information from the industry",
		name7: "Provide quotations for procurement needs of various food industries and quickly find cooperative customers",
		name8: "Quotation dynamics",
		name9: "Buy Today",
		name10: "Product",
		name11: "Quotation",
		name12: "Time",
		name13: "Number",
		name14: "Easy procurement",
		name15: "Publish procurement requirements and obtain the latest quotations; Comprehensive comparison of suppliers to achieve optimal cooperation",
		name16: "Inquiry Center",
		name17: "Get the latest quotation",
		name18: "Publish procurement requirements",
		name19: "Get a quote now",
		name20: "Frontier trends in the nutrition and health food industry",
		name21: "Official updates",
		name22: "Global food industry exhibition",
		name23: "China exhibitions",
		name24: "Authoritative industry documents",
		name25: "Law",
		name26: "Legal norms in the food industry",
		name27: "Industry standards",
		name28: "Provide international food safety standards",
		name29: "Industry technology",
		name30: "Professional experimental technical operation literature",
		name31: "Latest international customs information",
		name32: "bid",
		name33: "Log on",
		name34: "Set the language and currency",
		name35: "Project bidding",
		name36: "News",
		name37: "International exhibitions",
		name38: "Personalized shopping experience",
		name39: "Frequently searched",
		name40: "Publish purchasing information",
		name41: "Make an offer now",
		name42: "Operate",
		name43: "To quote",
		name44: "ago",
		name45: "View",
		name46: "Please select your preferred language and currency And the Settings can be updated at any time",
		name47: "Language",
		name48: "Currency",
		name49: "No data available",
		name50: "Recommended for you",
		name51: "Recent searches",
		name52: "Refresh",
		name53: "show more",


		footName1: "My Management",
		footName2: "News And Information",
		footName3: "Industry Exhibitions",
		footName4: "Industry Documents",
		footName5: "Customs Information",
		footName6: "Procure",
		footName7: "Release Purchase",
		footName8: "Purchasing Management",
		footName9: "Bidding Management",
		footName10: "My Bid",
		footName11: "Supply",
		footName12: "Quotation Management",
		footName13: "Process Orders",
		footName14: "Sample Application",
		footName15: "About Us",
		footName16: "Buyer Guidelines",
		footName17: "Seller Guidelines",
		footName18: "Feedback",
		footName19: "Nutrient-top.com Service Tel: +86-0371-63316437",
		footName20: "Copyright© nutrient-top.com Corporation All Rights Reserved",
		footName21: "Email Us",
		footName22: "Contact Us",
		footName23: "Mobile Terminal",
		footName24: "豫ICP备05001944号-4增值电信业务经营许可证:豫B2-20230509",
		indexName1: "Please select your identity",
		indexName2: "Enterprise administrator",
		indexName3: "Enterprise employee",
		indexName4: "Go to verify",
		indexName44: "Under review",
		indexName5: "Enterprise information",
		indexName6: "Enterprise name",
		indexName7: "Type of enterprise",
		indexName8: "Home country",
		indexName9: "Home city",
		indexName10: "Detailed address",
		indexName11: "Main commodity",
		"可多选": "Multiple options available",
		indexName12: "Certificate of incorporation",
		indexName13: "Business license can also be uploaded",
		indexName14: "Power of attorney",
		indexName15: "Please fill in the 'Power of Attorney' and affix the official seal before taking photos or scanning and uploading",
		indexName16: " Download the Power of Attorney template",
		indexName17: "Proof of existence",
		indexName18: "To prove the true state of your business",
		indexName19: "Contact information",
		indexName20: "Name",
		indexName21: "Family name",
		indexName22: "Telephone",
		indexName23: "Mailbox",
		indexName24: "Immediate authentication",
		indexName25: "Enterprise Code",

		purchase1: "Publish purchasing information",
		purchase2: "title",
		purchase3: "type",
		purchase4: "goods",
		purchase5: "quantity",
		purchase6: "unit",
		purchase7: "budget",
		purchase8: "Expected delivery date",
		purchase9: "Delivery address",
		purchase10: "instructions",
		purchase11: "product picture",
		purchase12: "Purchase" + " ",
		purchase13: "Only suppliers who provide quotation can view, please feel free to fill in",
		purchase14: "One-click publishing",
		purchase15: "Initiate an offer",
		purchase16: "reset",
		purchase17: "Find procurement",
		purchase18: "Cancel",
		purchase19: "Firm offer",
		purchase20: "Specific price",
		purchase21: "Quotation specification",
		purchase22: "Find the latest quote",

		bidding1: "Publish bidding project",
		bidding2: "Go to Publish",
		bidding3: "Search bidding information",
		bidding4: "Start time",
		bidding5: "Project information",
		bidding6: "Project name",
		bidding7: "Bidding time",
		bidding77: "Bid time",
		bidding8: "Requirement description",
		bidding9: "Bidding document",
		bidding10: "Upload cover image",
		bidding11: "Upload details map",
		bidding12: "submit",
		bidding13: "Tender particulars",
		bidding14: "Company details",
		bidding15: "Person in charge",
		bidding16: "Enterprise address",
		bidding17: "Main product",
		bidding18: "bid",
		bidding19: "Closing of tender",
		bidding20: "Tender not opened",
		bidding21: "Purchasing instructions",
		bidding22: "Contact number",
		bidding23: "Contact email",
		bidding24: "Tender document",
		bidding25: "Tender",
		bidding26: "Check Status",
		bidding27: "Currently not reviewed",
		bidding28: "Check time",
		"恭喜中标": "Congratulations on winning the bid",
		"暂未中标": "Not yet awarded the bid",
		"投标结果": "tender result",
		"咨询消息不能为空":"Consultation message cannot be empty",
		"查看更多":"view more",
		"暂无名称":"No name currently available",

		shop1: "Chat now",
		shop2: "Enter the shop",
		shop3: "business",
		shop4: "yrs",
		shop5: "Follow us",
		shop6: "Domestic exhibitions",
		shop7: "Company introduction",
		shop8: "Cooperative object",
		shop9: "Business license",
		shop10: "Store qualification",
		shop11: "Cooperation time",
		shop12: "Total purchase volume",
		shop13: "Transaction amount",
		shop14: "Trade goods",
		shop15: "Details of cooperation",
		shop16: "pieces",
		shop17: "Contact supplier",
		shop18: "Complaint Merchant",
		shop19: "Merchant information",
		shop20: "Merchant name",
		shop21: "Application information",
		shop22: "Type of complaint",
		shop23: "Cause of complaint",
		shop24: "Picture certificate",
		shop25: "Please fill in this order registration form, we will arrange professional service personnel to contact you",
		shop26: "inventory",
		shop27: "Place of dispatch",
		shop28: "specification",
		shop29: "Sample request",
		shop30: "Order registration",
		shop31: "Commodity attribute",
		shop32: "Product details",
		shop33: "Recommended goods",
		shop34: "Telephone consultation",
		shop35: "Contact person",
		shop36: "I know.",
		shop37: "Trade name",
		shop38: "Commodity price",
		shop39: "Address",
		shop40: "Order details",
		shop41: "Remark",
		shop42: "Please fill in this form, we will arrange professional service personnel to contact you",
		shop43: "Application description",
		shop44: " " + "form",

		news1: "collect",
		news2: "download",
		news3: "Relevant recommendation",
		news4: "Consulting activity",
		news44: "Activity end",
		news5: "Please call the hotline for more information and participate in the registration",

		login1: "Verification",
		login2: "Mobile phone",
		login3: "Number verification",
		login4: "Set A",
		login5: "Password",
		login6: "First Name",
		login7: "Last Name",
		login8: "Verification Code",
		login9: "Get verification code",
		login10: "The Verification Code Has Been Sent To Your Email, Valid For Input Within 10 Minutes, Please Do Not Disclose",
		login11: "Mobile Phone Number Verification",
		"设置密码": "Set A Password",
		"验证码已发送到您的手机，10分钟内输入有效": "The verification code has been sent to your mobile phone, valid for input within 10 minutes, please do not disclose",
		login13: "Last Step",
		login14: "New Password",
		login15: "Confirm Password",
		login16: "Login means that you have agreed to",
		login17: "User Agreement and Privacy Policy",
		login18: "Have read and agree to",
		login19: "the User Agreement",
		login20: "Privacy Policy",
		login21: "Nutrient Manufacturing Network will strictly protect the security of your personal information",
		login22: "Not agree and quit",
		login23: "agree.",
		login24: "and",
		login25: "Back",
		login26: "Email",
		login27: "cipher",
		login28: "Sign in to nutrient-top.com",
		login29: "Forgot password",
		login30: "Don't have an account yet",
		login31: "Register now",
		login32: "Mobile verification code login",
		login33: "login",
		login34: "Identity authentication",
		login35: "Reset password",

		mine1: "Qualification Certification",
		mine2: "Wechat Account",
		mine3: "Current identity",
		mine4: "Avatar",
		mine5: "save",
		mine6: "Modify mobile phone number",
		mine7: "Change password",
		mine8: "Go to modify",
		mine9: "Account information",
		mine10: "Online customer service",
		mine11: "Consult now",
		mine12: "Contact address",
		mine13: "Details picture",
		mine14: "Bidding information",
		mine144: "Employee List",
		mine15: "Delete",
		mine16: "Quotation details",
		mine17: "Order registration information",
		mine177: "Sample application information",
		mine18: "audited",
		mine19: "View details",
		mine199: "Already ended",
		mine20: "Accept Offer",
		mine200: "This quotation has been accepted",
		mine21: "Purchasing information",
		mine22: "Registration time",
		mine23: "Audit status",
		mine24: "Download all files",
		mine25: "Audit details",
		mine26: "Audit Time",
		mine27: "Delete bidding",
		mine277: "Delete bid",
		mine28: "reupload",
		mine288: "Re bidding",
		mine29: "Cause Of Rejection",

		"了解更多信息":"Learn more",
		"请上传投标文件":"Please upload the bidding documents",
		"备注详细信息":"remarks details",
		"请选择": "Please select",
		"商品类型": "Product type",
		"请输入": "Please enter keywords",
		"请选择采购类型": "Please select the procurement type",
		"请输入商品名称": "Please enter the product name",
		"请填写采购商品的品种、规格、型号、包装、物流以及采购商所在地区、资质等要求、确保您收到准确的报价": "Please fill in the product variety/specifications/model/packaging/logistics, and provide information to the purchaser ...",
		"请输入采购标题": "Please enter the purchase title",
		"请输入采购数量": "Please enter the quantity purchased",
		"请输入采购单位": "Please enter the purchasing unit",
		"请输入采购预算": "Please enter the quantity purchased",
		"请选择日期": "Please select a date",
		"请选择地址": "Please select a address",
		"请选择国家": "Please select a country",
		"请输入详细地址": "Please enter detailed address",
		"请输入您的名字": "Please enter your name",
		"请输入姓氏": "Please enter your last name",
		"请输入联系方式": "Please enter a contact number",
		"请输入邮箱": "Please enter your contact email",
		"只有提供报价的供应商可以查看，请您放心填写": "Only suppliers who provide quotation can view, please feel free to fill in",
		"首页": "Home page",
		"发布成功": "Release success",
		"发布成功，请及时关注消息获取最新报价！": "Please pay attention to the news to get the latest quotation!",
		"继续采购": "Further purchase",
		"确定放弃最新一条投标？":"Are you sure you want to abandon the latest bid?",
		"确定重新发起投标吗？":"Are you sure to initiate a new bid?",
		"操作成功":"Operation successful",
		"您确定要删除此样本申请吗？":"Are you sure you want to delete this sample application?",

		// root
		"资质认证": "Qualification certification",
		"管理员认证": "Enterprise administrator",
		"员工认证": "Enterprise employee",
		"品牌商家": "Brand merchant",
		"搜索结果": "searchResult",
		"商品分类": "goodsType",
		"发布采购": "Release purchase",
		"我的投诉": "My complaint",
		"商家详情": "Merchant details",
		"全部商品": "All goods",
		"商品详情": "goodsDetail",
		"轮播详情": "swiperDetail",
		"产品中心": "Self-run mall",
		"询单中心": "Inquiry center",
		"采购详情": "purchaseDetail",
		"报价": "quotedPrice",
		"招标大厅": "Bidding hall",
		"发布招标": "Issue a tender",
		"详情": "View details",
		"新闻资讯": "News information",
		"新闻详情": "Consultation details",
		"海关资讯": "Customs information",
		"海关详情": "Consultation details",
		"行业文档": "Industry information",
		"行业详情": "Industry details",
		"行业会展": "Industry exhibition",
		"会展详情": "Document details",
		"个人中心": "Individual center",
		"采购管理": "Purchasing management",
		"招标管理": "Bidding management",
		"投标管理": "Bidding management",
		"订购登记": "Order registration",
		"样本申请": "Sample request",
		"个人信息": "Personal information",
		"账号信息": "Account information",
		"我的收藏": "My collection",
		"消息通知": "Message notification",
		"消息通知详情": "Message notification",
		"员工列表": "Employee list",
		"联系客服": "Contact customer service",
		"意见反馈": "feedback",
		"我的投诉": "My complaint",
		"帮助中心": "Help center",
		"处理订购登记": "Processing order registration",
		"处理样本申请": "Processing sample registration",
		"报价管理": "Individual center",
		"报价详情": "quotation details",
		"招标详情": "Tender particulars",
		"投标详情": "Bid details",
		"关于我们": "About us",
		"不能对自己公司的招标进行投标":"Unable to bid on their own company's bidding process",

		"请输入项目名称": "Please enter a project name",
		"开始时间": "start time",
		"结束时间": "end time",
		"暂无时间": "No time at the moment",
		"暂无省份": "no provinces",
		"未命名": "Untitled",
		"请详细描述您的招标需求": "Please describe your bidding requirements in detail",
		"提交成功": "Submit successfully",
		"知道了": "I got it",
		"提交成功！工作人员审核通过您的招标项目后，发布招标项目了": "After the staff review and approve your tender project, you can publish the tender project",
		"提交失败": "Commit failure",
		"请重新提交您的投标文件":"Please resubmit your bid documents",
		"重新提交": "resubmit",
		"发布失败": "Publishing failure",
		"企业认证完成后即可发布采购信息": "Purchase information can be released upon completion of enterprise certification",
		"回购": "repurchase",
		"确认":"confirmButton",
		"取消":"cancelButton",
		"请输入企业名称": "Please enter the business name",
		"请选择类型": "Please select Type",
		"请等待处理结果":"Please wait for the processing result",

		"投诉商家": "Complaint Merchant",
		"投诉时间": "Complaint Time",
		"投诉类型": "Type Of Complaint",
		"待处理": "To be processed",
		"已处理": "processed",
		"已拒绝": "rejected",
		"投诉详情": "Complaint details",
		"处理结果": "Processing result",
		"处理意见": "Handling suggestion",
		"处理时间": "Processing time",
		"重新提交": "resubmit",
		"暂无权限": "Temporarily without authority",
		"修改成功": "Modified successfully",
		"申请时间": "Application time",
		"待审核": "To be reviewed",
		"已审核": "audited",
		"投标列表": "Bid list",
		"操作": "Controls",
		"收起": "Pack up",
		"审核通过":"Approved",
		"审核驳回":"Review rejection",
		"暂无地址":"No address currently available",
		"暂无":"None",
		"暂无国家信息":"No country yet",
		"请详细描述您投诉的原因，工作人员会立即处理":"Please describe the reason for your complaint in detail and the staff will deal with it immediately",
		"请选择":"Please select",
		"翻译":"translate",
		"发送":"send",

		"企业名称":"Enterprise name",
		"该文件已丢失":"The file is lost",
		"下载文件":"Download file",
		"合作":"cooperation",
		"已合作":"Collaborated",
		"处理成功":"Successful processing",
		"转到":"go",
		"页":"page",
		"最小页码为1":"The minimum page number is 1.",
		"页数超过最大页数":"Page number exceeding upper limit.",
		"感谢您的反馈！":"Thank you for your feedback!",
		"请提出你的意见":"Please input your opinion.",
		"请选择您想要提供反馈的图像":"Please select the image you want to provide feedback on.",
		"上传成功":"Upload successful.",
		"条数":"articles in total",
		"收藏管理":"Collection management",
		"取消收藏":"uncollect",
		"全选":"Select All.",
		"确定取消收藏吗？":"Are you sure you want to uncollect?",
		"查看详情":"View detail",
		"您确定要删除该订购记录吗？":"Are you sure to delete this subscription registration?",
		"当前限制选择 3 个文件，本次选择了":"The current limit is 3 files, and this one is selected",
		"个文件，共选择了":"file, all selected",
		"个文件":"file",
		"请选择联系人":"Please select Contact person",
		"请选择投诉类型":"Please select the complaint type",
		"请输入投诉原因":"Please enter Cause of complaint",
		"请上传源文件":"Please upload the source file",
		"请上传复印件":"Please upload a copy",
		"请输入要查询的产品名称":"Please enter the product name you want to query",
		"请输入具体价格":"Please enter specific price",
		"请输入单位":"Please enter the unit",
		"成功与该企业合作！":"Successfully collaborated with the company!",
		"请输入正确的手机号":"Please enter the correct phone number",

		"确定": "Confirm",
		"取消": "Cancel",
		"确定清空历史记录?": "Confirm to clear history?",
		"您的认证申请正在审核中，请耐心等待": "Your application for accreditation is being reviewed,Please wait patiently",
		"企业认证完成后可发起报价": "An offer can be initiated upon completion of enterprise certification",
		"立即认证": "Immediate authentication",
		"企业认证完成后可发布采购信息": "Purchase information can be released upon completion of enterprise certification",
		"买方没有报价权限": "Buyer is not authorized to quote",
		"您还没有完成企业认证": "You have not completed enterprise certification",

		"账号": "Account number",
		"密码": "cipher",
		"请输入您的邮箱账号": "Please enter your email account",
		"请输入您的登录密码": "Please enter your login password",
		"忘记密码?": "Forgot password?",
		"两次密码输入不一致":"The two password inputs are inconsistent",
		"密码不能为空":"Password cannot be empty",
		"还没有账号?": "Don't have an account yet?",
		"立即注册": "Sign in",
		"登录代表您已同意": "Login means that you have agreed to the",
		"用户协议": "User Agreement",
		"隐私政策": "Privacy Policy",
		'登录': 'Log in',
		"验证码登录": "Verification code login",
		"其它方式登录": "Login by other means",
		"验证码": "Verification code",
		"邮箱登录": "Email login",
		"请输入您的手机号": "Please enter your phone number",
		"请输入您的验证码": "Please enter the verification code",
		"获取验证码": "Acquire",
		"重新获取": "Reacquire",
		"X秒重新获取": "x s reacquire",
		"验证码已发送": "The verification code has been sent",
		"倒计时结束后再发送": "Wait until the countdown is over",
		"请阅读并勾选用户协议": "Please read and check the User Agreement",
		"身份验证": "Identity authentication",
		"设置密码": "Set a password",
		"手机号验证": "Mobile phone number verification",
		"邮箱验证": "Mailbox verification",
		"验证码已发送到您的邮箱，10分钟内输入有效，请勿泄漏": "The verification code has been sent to your email, valid for input within 10 minutes, please do not disclose",
		"验证码已发送到您的手机，10分钟内输入有效，请勿泄漏": "The verification code has been sent to your phone, valid for input within 10 minutes, please do not disclose",
		"重置密码": "Reset Password",
		"新密码": "New password",
		"确认密码": "Confirm password",
		"请设置您的新密码": "Please set a new password",
		"请设置您的密码": "Please set a password",
		"请确认您的密码": "Please confirm your password",
		"完成": "complete",
		"密码设置成功!请重新登陆": "Password setting successful! Please login again",
		"两次密码不一致": "Two passwords do not match",
		"验证码不能为空":"The verification code cannot be empty",
		"邮箱": "Mailbox",
		"注册成功": "Registered successfully",
		"登陆成功跳转首页":"Login successful, about to jump to homepage.",
		"三方账号成功绑定该邮箱":"The third-party account was successfully bound to the email address",
		"确定保存信息?":"Are you sure you save the information?",
		"保存成功":"Save successfully",
		"确定删除?":"Are you sure you want to delete it?",
		"删除招标成功":"Successfully deleted bidding",
		"未查找到对应企业":"No corresponding enterprise found",
		"请选择采购单位":"Please select the purchasing unit",
		
		"员工信息":"Employee information",
		"暂无报价":"No offer at present",
		"提交成功，工作人员将立即处理":"Submit successfully，The staff will deal with it immediately",
		"继续报价":"Continue to offer",
		"取消报价":"Cancel offer",
		"全部":"All",
		"确定接受这个报价吗?":"Are you sure you accept the offer?",
		"您可以在我的购买订单中查看":"You can view it in My Purchase Order",
		"新投标":"new bids",
		"有":"There are",
		"个新投标":"new bids",
		
		"咨询产品":"Consulting product",
		"订购登记表":"Order registration form",
		"请输入备注详情":"Please enter remarks details",
		"退出":"Quit",
		"确定退出登录?":"Are you sure you want to log out?",
		
		"请输入联系人姓名":"Please enter the contact name",
		"请输入联系邮箱":"Please enter contactEmail",
		"请输入联系地址":"Please enter a contactAddress",
		"请输入申请描述":"Please enter the application description",
		"请输入备注信息":"Please enter remarks",
		"请输入订购详情":"Please enter order details",
		"采购信息":"Purchasing information",
		"报价成功":"Bid successful",
		"会展已结束":"The exhibition has ended",
		"密码设置成功!":"Password setting successful!",
		"请重新登陆":"Please log in again",
		"确定删除报价信息?":"Are you sure to delete this quote information?",
		"有关标书将于招标开始后收到":"The relevant bids will be received once the tender has commenced",
		"确定删除该招标?":"Are you sure to delete the tender?",
		"确定与该企业合作?":"Are you sure you want to work with this company?",
		"请输入关键词":"Enter query keywords",
		"请输入具体价格":"Please enter specific price",
		"请输入商品单位":"Please enter the unit",
		"请输入报价说明":"Please enter a quote description",
		"请输入您的新密码":"Please enter your new password",
		"请再次输入新密码":"Please enter your new password",
		"请输入您的新手机号":"Please enter your new phone number",
		"确定删除该采购信息?":"Are you sure to delete this purchase information?",
		"删除成功":"Delete successful.",
		"请输入企业代码":"Please enter enterpriseCode",
		"请输入业务代码":"Please enter the business Code",
		"请输入正确手机号":"Please enter the correct phone number",
		"请选择手机号前缀":"Please select a phone number prefix",
		"请输入名称":"Please enter name",
		"请输入姓氏":"Please enter last name",
		"请输入正确的邮箱":"Please enter the correct email address",
		"请输入地址":"Please enter address",
		"请选择主营产品":"Please select the main product category",
		"新报价":" new offers",
		"请输入正确邮箱":"Please enter the correct email address",
		"编号":"ID",
		"员工名称":"Employee name",
		"接受该报价成功":"Successfully accepted the quotation",

		"后重新发送":"Resend later",
		"网站许可证":"Website registration/license: Yue ICP Bei No. 16022018-1 Copyright @2020-2022 Junhe Digital Creative All rights reserved",
		"转载版权联系":"Please contact us for copyright issues related to reprinting or citing articles on this website. Phone: 020-34224268 Fax: 020-34113782",

		"您没有发布采购的权限":"You do not have the authority to publish purchases",
		"您没有投标的权限":"You do not have the authority to bid",
		"您没有发布招标的权限":"You do not have the authority to publish bids",
		"已经到底啦":"It's already the end of it",
		"结束会话":"End session",
		"您的账号暂未绑定邮箱，请完成一次登录以绑定邮箱":"Your account has not been linked to an email yet. Please complete a login to link your email",
		"三方登录提示":"Three party login prompt",
		"您的三方账号暂未绑定对应用户，请选择登录/注册对应用户进行绑定":"Your third-party account has not been linked to the corresponding user yet. Please choose to log in/register the corresponding user for binding",
		"注册":"register",
		"请输入您的意见和建议":"Please input your comments and suggestions",
		"无法对自己的商铺进行该操作":"Unable to perform this operation on one's own store",
		"输入密码不能为空":"Input password cannot be empty",
		"输入的密码长度应在4-16位":"The length of the entered password should be between 4-16 bits",
		"两次输入密码不一致":"The passwords entered twice are inconsistent",
		"该账号已经绑定了此平台的三方账号，确定继续登录以替换绑定吗？":"This account is already bound to a third-party account on this platform. Are you sure to continue logging in to replace the binding?",
		"请选择城市":"Please select city",
		"不是jpeg,png,jpg格式,请上传正确的图片类型":"Not. jpeg,. png,. jpg, please upload the correct image type"
	}
}