import { render, staticRenderFns } from "./message-abstract-text.vue?vue&type=template&id=f5e74696&scoped=true"
import script from "./message-abstract-text.vue?vue&type=script&lang=ts"
export * from "./message-abstract-text.vue?vue&type=script&lang=ts"
import style0 from "./message-abstract-text.vue?vue&type=style&index=0&id=f5e74696&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5e74696",
  null
  
)

export default component.exports