<template>
	<!-- 底部 -->
	<div style="border-top: 1px solid #ccc;flex: 0;" class="padding-top-60 padding-bottom-20 bg-fff">
		<div class="inercont">
			<div class="flex align-center justify-between">
				<div class="flex align-start">
					<div class="margin-right-60">
						<div class="fs-16 fw-bold margin-bottom-20">{{$t('message.footName1')}}</div>
						<div class="pointer fs-14" style="line-height: 30px;">
							<p @click="toPage('/news')">{{$t('message.footName2')}}</p>
							<p @click="toPage('/exhibitions')">{{$t('message.footName3')}}</p>
							<p @click="toPage('/industry')">{{$t('message.footName4')}}</p>
							<p @click="toPage('/customs')">{{$t('message.footName5')}}</p>
						</div>
					</div>
					<div class="margin-right-60">
						<div class="fs-16 fw-bold margin-bottom-20">{{$t('message.footName6')}}</div>
						<div class="pointer fs-14" style="line-height: 30px;">
							<p @click="toPage('/ReleasePurchase',3)">{{$t('message.footName7')}}</p>
							<p @click="toPage('/personal_center/purchasing',3)">{{$t('message.footName8')}}</p>
							<p @click="toPage('/biddingHall',3)">{{$t('message.footName9')}}</p>
							<p @click="toPage('/personal_center/bidding?type=1',3)">{{$t('message.footName10')}}</p>
						</div>
					</div>
					<div class="margin-right-60">
						<div class="fs-16 fw-bold margin-bottom-20">{{$t('message.footName11')}}</div>
						<div class="pointer fs-14" style="line-height: 30px;">
							<p @click="toPage('/quotation_mana',4)">{{$t('message.footName12')}}</p>
							<p @click="toPage('/personal_center/handle',4)">{{$t('message.footName13')}}</p>
							<p @click="toPage('/personal_center/sample',4)">{{$t('message.footName14')}}</p>
						</div>
					</div>
					<div class="margin-right-60">
						<div class="fs-16 fw-bold margin-bottom-20">{{$t('message.footName15')}}</div>
						<div class="pointer fs-14" style="line-height: 30px;">
							<p @click="toPage('/personal_center/help?index=0')">{{$t('message.footName16')}}</p>
							<p @click="toPage('/personal_center/help?index=1')">{{$t('message.footName17')}}</p>
							<!-- <p @click="toPage('/personal_center/feedback')">{{$t('message.footName18')}}</p> -->
							<p @click="toPage('/agreement?type=3')">{{$t('message.login19')}}</p>
							<p @click="toPage('/privacypolicy')">{{$t('message.login20')}}</p>
							<p @click="toPage('/about')">{{$t('message.footName15')}}</p>
						</div>
					</div>
				</div>
				<div class="bg-d9" style="width: 1px;height: 152px;opacity: .5;"></div>
				<div class="flex align-center">
					<div>
						<div class="fs-14 margin-bottom-6">{{$t('message.footName21')}}</div>
						<div class="fs-18 fw-bold margin-bottom-10 pointer" style="border-bottom: 1px solid;">
							{{system.email}}
						</div>
						<div class="fs-14 margin-bottom-6">{{$t('message.footName22')}}</div>
						<div class="fs-18 fw-bold margin-bottom-26 pointer"
							style="border-bottom: 1px solid;width: fit-content;">{{system.phone}}</div>
						<div class="flex align-center justify-between margin-top-24 pointer" style="width: 110px;">
							<a href="https://www.facebook.com/profile.php?id=61561856856402" target="_blank">
								<img src="@img/index/index12.png" width="20px" height="20px" alt="" />
							</a>
							<a href="https://twitter.com/NutrientChina" target="_blank">
								<img src="@img/index/index13.png" width="20px" height="20px" alt="" />
							</a>
							<a href="https://www.instagram.com/" target="_blank">
								<img src="@img/index/index14.png" width="20px" height="20px" alt="" />
							</a>
						</div>
					</div>
					<div class="margin-left-88">
						<div>
							<img :src="system.twoCode" width="90px" height="90px" alt="" />
						</div>
						<div class="fs-14 text-center margin-top-10">{{$t('message.footName23')}}</div>
					</div>
				</div>
			</div>
			<div class="fs-12 text-center color-666 margin-top-60" style="line-height: 18px;">
				<div>{{$t('message.footName19')}}</div>
				<div>{{$t('message.footName20')}}</div>
				<div>{{$t('message.footName24')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				system: {
					phone: "",
					email: "",
					twoCode: "",
					wLogo: "", //白logo
					bLogo: "", //
					powerAttorney: "", //委托书
				},
				userId:localStorage.getItem('userId')
			}
		},
		created() {
			// 配置文件
			this.$http({
				url: "/admin-api/biz/logo/get-type",
				method: "get",
				headers: {
					authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				},
			}).then((res) => {
				if (res.data.code == 0) {
					let datas = res.data.data;
					datas.forEach(el => {
						if (el.type == 1) {
							this.system.wLogo = el.logoUrl;
						} else if (el.type == 2) {
							this.system.bLogo = el.logoUrl;
						} else if (el.type == 3) {
							this.system.twoCode = el.logoUrl;
						} else if (el.type == 4) {
							this.system.powerAttorney = el.logoUrl;
						} else if (el.type == 5) {
							this.system.email = el.logoUrl;
						} else if (el.type == 6) {
							this.system.phone = el.logoUrl;
						}
					})
				}
			});
		},
		methods: {
			toPage(path, type) {
				if (type) {
					this.$http({
						url: "/app-api/biz/open/open-user-info",
						method: "get",
						headers: {
							authorization: `Bearer ${localStorage.getItem('accessToken')}`
						},
					}).then((res) => {
						if (res.data.code == 0 && res.data.data) {
							if (res.data.data.enterpriseType == 5) {
								this.$router.push({
									path: path
								})
							}else if(res.data.data.enterpriseType==type){
								this.$router.push({
									path: path
								})
							}else{
								this.$message(this.$t('message.暂无权限'))
							// 未登录
							if (!this.userInfo.id) {
								this.$router.push({
									path: "/login",
								});
							}
							}
						}else{
							this.$message(this.$t('message.暂无权限'))
							// 未登录
							if (!this.userInfo.id) {
								this.$router.push({
									path: "/login",
								});
							}
						}
					});
					return
				}
				this.$router.push({
					path: path
				})
			},
		},
	}
</script>

<style>
</style>