var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['TUI-contact-search', !_vm.isPC && 'TUI-contact-search-h5']},[(!_vm.isSearching || !_vm.isPC)?_c('div',{class:[
      'TUI-contact-search-header',
      !_vm.isPC && 'TUI-contact-search-h5-header',
      _vm.isSearching && 'TUI-contact-searching-h5-header' ],on:{"click":_vm.handleSearchingHeaderClicked}},[_c('div',{class:[
        'TUI-contact-search-header-icon',
        !_vm.isPC && 'TUI-contact-search-h5-header-icon' ],on:{"click":function($event){$event.stopPropagation();return _vm.handleSearchingIconClicked.apply(null, arguments)}}},[_c('Icon',{attrs:{"file":_vm.isSearching ? _vm.backSVG : _vm.addSVG,"width":_vm.isSearching ? '20px' : '14px',"height":_vm.isSearching ? '20px' : '14px'}})],1),_c('div',{class:[
        'TUI-contact-search-header-title',
        !_vm.isPC && 'TUI-contact-search-h5-header-title' ]},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("TUIContact.添加好友/群聊"))+" ")])]):_vm._e(),(_vm.isSearching)?_c('div',{class:[
      'TUI-contact-search-main',
      !_vm.isPC && 'TUI-contact-search-h5-main' ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"TUI-contact-search-main-input",attrs:{"type":"text","placeholder":_vm.searchingPlaceholder,"enterkeyhint":"search"},domProps:{"value":(_vm.searchValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"blur":_vm.search,"confirm":_vm.search,"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}}),_c('div',{staticClass:"TUI-contact-search-main-cancel",on:{"click":function($event){_vm.isSearching = false}}},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("取消"))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }