var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat"},[_c('div',{class:['TUI-chat', !_vm.isPC && 'TUI-chat-h5']},[(!_vm.currentConversationID)?_c('div',{class:['TUI-chat-default', !_vm.isPC && 'TUI-chat-h5-default']},[_vm._t("default")],2):_vm._e(),(_vm.currentConversationID)?_c('div',{class:['TUI-chat', !_vm.isPC && 'TUI-chat-h5']},[_c('ChatHeader',{class:[
          'TUI-chat-header',
          !_vm.isPC && 'TUI-chat-H5-header',
          _vm.isUniFrameWork && 'TUI-chat-uniapp-header' ],on:{"closeChat":_vm.closeChat}}),_c('Forward'),_c('MessageList',{class:['TUI-chat-message-list', !_vm.isPC && 'TUI-chat-h5-message-list'],attrs:{"isGroup":_vm.isGroup,"groupID":_vm.groupID},on:{"handleEditor":_vm.handleEditor}}),(_vm.isToolbarShow)?_c('MessageInputToolbar',{class:['TUI-chat-message-input-toolbar', !_vm.isPC && 'TUI-chat-h5-message-input-toolbar'],on:{"insertEmoji":_vm.insertEmoji}}):_vm._e(),_c('MessageInput',{ref:"messageInputRef",class:[
          'TUI-chat-message-input',
          !_vm.isPC && 'TUI-chat-h5-message-input',
          _vm.isUniFrameWork && 'TUI-chat-uni-message-input',
          _vm.isWeChat && 'TUI-chat-wx-message-input' ],attrs:{"isMuted":false,"muteText":_vm.TUITranslateService.t('TUIChat.您已被管理员禁言'),"placeholder":_vm.TUITranslateService.t('TUIChat.请输入消息')},on:{"handleToolbarListShow":_vm.handleToolbarListShow}})],1):_vm._e(),(_vm.isUniFrameWork && _vm.isGroup && _vm.groupManageExt)?_c('div',{staticClass:"group-profile",on:{"click":_vm.handleGroup}},[_vm._v(" "+_vm._s(_vm.groupManageExt.text)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }