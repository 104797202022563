<template>
  <div :class="['message-abstract-image']">
    <img :class="['messageImage']" :src="messageContent.url" />
  </div>
</template>
<script lang="ts">
const __sfc_main = {};
__sfc_main.props = {
  messageContent: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
</script>
<style scoped lang="scss">
@import "../../../../../assets/styles/common.scss";

.message-abstract-image {
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  overflow: hidden;
  background-color: #ffffff;

  .messageImage {
    max-width: 100px;
    max-height: 100px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}
</style>
