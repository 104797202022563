<template>
  <div
    class="overlay"
    :style="{
      zIndex: props.zIndex,
      backgroundColor: props.bgColor,
    }"
    @click="click"
    @tap="tap"
  >
    <div @click.stop="innerContentClick">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export interface IOverlayProps {
  zIndex?: number;
  bgColor?: string;
}
import { withDefaults } from '../../../adapter-vue';
const __sfc_main = {};
__sfc_main.props = {
  zIndex: {
    key: "zIndex",
    required: false,
    type: Number,
    default: 9999
  },
  bgColor: {
    key: "bgColor",
    required: false,
    type: String,
    default: 'rgba(0, 0, 0, 0.6)'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  function innerContentClick(e: Event) {
    e.stopPropagation();
  }
  function click() {
    emits('clickHandler');
  }
  function tap() {
    emits('touchHandler');
  }
  return {
    props,
    innerContentClick,
    click,
    tap
  };
};
export default __sfc_main;
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
