import axios from "axios";
import { Message } from 'element-ui';
import router from "./../router";
import config from "@/utils/config.js";
// 实例

const http= axios.create({
    baseURL:config.baseURL,
    timeout: 30000,
    headers: {
        'X-Custom-Header': 'yma16',
        authorization:`Bearer ${localStorage.getItem('accessToken')}`
    },
    params:{language:localStorage.getItem("language")?localStorage.getItem("language"):'english'},
    data:{language:localStorage.getItem("language")?localStorage.getItem("language"):'english'},
});
const https= axios.create({
    baseURL:config.baseURL,
    timeout: 30000,
    headers: {
        'X-Custom-Header': 'yma16',
        authorization:`Bearer ${localStorage.getItem('accessToken')}`
    },
    params:{},
});


// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // return response;
    const {data:{code,msg}} = response
    if(code===0){
        return response
    }else if(code===401){
		router.push({
			path: "/login",
		});
	} else {
		Message(msg);
		return response
        // throw new Error(`response code = ${code},message:${msg}`)
    }
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});

export {
    http,
    https
};

