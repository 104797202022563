<template>
	<div class="flex flex-direction align-center padding-y-100" v-show="length==0">
		<img src="@img/index/emptypng.png" width="200px" height="200px" alt="暂无数据" />
		<p>{{$t('message.name49')}}</p>
	</div>
</template>

<script>
	export default{
		props:{
			length:{
				type:Number,
				default: 0
			}
		}
	}
</script>

<style>
</style>